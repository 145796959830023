import React, { useCallback, useEffect, useRef, useState } from 'react';
import './uploadExcel.css'
import uploadImg from './../../../assets/uploadExcel.png'
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import excelImg from './../../../assets/excel.png'
import axios from 'axios';
import baseConfig from '../../../config/config';
import Alert from '../../components/alert/alert';

function UploadExcel(props) {
    const navigate = useNavigate();
    const [attachment, setAttachment] = React.useState(null);
    const [attachmentName, setAttachmentName] = React.useState(null);
    const [fileUploadSucess, setFileUploadSuccess] = React.useState(false);
    const [fileUploadFailed, setFileUploadFailed] = React.useState(false);
    const [fileUploading, setFileUploading] = React.useState(false)
    const [APIResponseData, setAPIResponseData] = React.useState('');
    const [AllRowsUploaded, setAllRowsUploaded] = React.useState(false);
    const [invalidRows, setInvalidRows] = React.useState([]);

    const inputRef = useRef(null);
    useEffect(() => {
        const hideAlert = () => {
            setFileUploadSuccess(false);
            setFileUploadFailed(false);
        };

        const alertTimeout = setTimeout(hideAlert, 8000); // Change 5000 to the desired time in milliseconds

        return () => clearTimeout(alertTimeout);
    }, [fileUploadSucess, fileUploadFailed])

    const FileDropzone = ({ onDrop, children }) => {
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

        return (
            <div {...getRootProps()} className={`dropzone ${isDragActive && 'active'}`}>
                <input {...getInputProps()} />
                {children}
            </div>
        );
    };

    const uploadTemplate = async () => {
        setFileUploading(true)
        const data = new FormData();
        data.append('file', attachment);
        //Utility/AvailablePTUUpload
        let nextURL = "";
        if(props.isPtuAvailaibleCapacity){
            nextURL = '/Utility/AvailablePTUUpload'
        }else{
            nextURL = parseInt(sessionStorage.getItem('tab')) === 2 ? '/Utility/ManagedUpload' : '/Utility/Upload';
        }
        const config = {
            method: 'post',
            url: baseConfig.apiBaseUrl + nextURL,
            headers: {
                'serviceid': window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
            },
            data: data,
        };
        try {
            const response = await axios(config);
            if(props.isPtuAvailaibleCapacity){
                setFileUploading(false)
                setFileUploadSuccess(true)
                setAllRowsUploaded(true)
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
                
            }else{
            const responseJson = response.data;
            setAPIResponseData(responseJson);
            let number1 = responseJson['InsertedRows'];
            let number2 = responseJson['TotalRows'];

            if (number1 === number2) {
                setAllRowsUploaded(true);
                setFileUploading(false)
                setFileUploadSuccess(true)
                setTimeout(() => {
                    setFileUploadSuccess(false)
                    window.location.reload();
                }, 4000);
            } else {
                setFileUploading(false)
                setInvalidRows(responseJson['InvalidRows'])
                setAllRowsUploaded(number2 - number1);
            }      
        }
        } catch (error) {
            setFileUploading(false)
            setTimeout(() => {
                setFileUploadFailed(error.response.data)
            }, 4000);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const fileName = selectedFile.name;
        const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        if (selectedFile && (fileExtension === "xlsx" || fileExtension === "xls")) {
            setAttachment(selectedFile)
            setAttachmentName(selectedFile.name)
        }
        else {
            setFileUploadFailed("Please select a valid file format")
            setTimeout(() => {
                setFileUploadFailed(false)
            }, 4000);
        }
    }

    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            props.closeUploadPopUpOpen()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);
    return (
        <>
            <div className="modal-container">
                <div style={{ maxHeight: '80vh' }}>
                    <div className="title">
                        <b>Upload Excel</b>
                        <i className="fa fa-times close-icon"
                            onClick={props.closeUploadPopUpOpen}
                        ></i>
                    </div>

                    <hr className="divider" />

                    <div className="text" style={{ fontSize: '15.5px', textAlign: 'center' }}>
                        To Append data to database, click on Upload Excel button. Our system will then process the excel file and add data to database.
                    </div>

                    <div className="container-uplaod">
                        <img
                            src={uploadImg}
                            alt="Filter Icon"
                            style={{ height: '100px', width: '100px' }}
                        />
                    </div>

                    <div className="container-uplaod">

                        <button className="upload-btn" disabled={fileUploading ? true : false}
                            onClick={() => inputRef.current.click()}
                        >
                            Upload Excel
                            <input
                                ref={inputRef}
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </button>

                    </div>

                    {attachment &&
                        <div className="container-uplaod">
                            <div className="image-container">
                                <img
                                    src={excelImg}
                                    alt="Filter Icon"
                                    style={{ height: '25px', width: '25px' }}
                                />
                            </div>
                            <div className="info-container" style={{ textAlign: 'left' }}>
                                <span className="attachment-name">{attachmentName}</span>
                                <span className="attachment-size">
                                    {Math.round(attachment?.size / 1024)}KB
                                </span>
                            </div>
                            <i className="fa fa-times close-icon" style={{ color: '#ff8a8a' }} onClick={() => { setAttachment(null); setAttachmentName(null) }}></i>
                        </div>
                    }

                    <hr className="divider" />

                    <div className="buttons-container">
                        <button
                            className="button button-go-back"
                            onClick={props.closeUploadPopUpOpen}
                        >
                            Cancel
                        </button>
                        <button
                            className="button button-confirm"
                            disabled={fileUploading ? true : false}
                            onClick={uploadTemplate}
                        >
                            Submit
                        </button>
                    </div>

                </div>
            </div>
            {fileUploading && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                File is being uploaded. Please wait
            </div>}
            {(fileUploadSucess && AllRowsUploaded) && (
                <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                    File uploaded successfully.
                </div>
            )}
            {(fileUploadFailed) && (
                <div className="alert" style={{ backgroundColor: '#f44336' }}>
                    {fileUploadFailed}
                </div>
            )}

            {
                (invalidRows?.length > 0 && !props.isPtuAvailaibleCapacity) && (
                    <div className="modal-container" style={{ width: '50vw', }}>
                        <div>
                            <div className="title" style={{ display: "flex", width: '100%', alignItems: 'center' }}>
                                <b>Upload Errors</b>
                            </div>

                            <hr className="divider" />

                            <div className="text" style={{ fontSize: '13.5px' }}>
                                <b>{APIResponseData['InsertedRows']}</b> out of <b>{APIResponseData['TotalRows']}</b> records have been uploaded successfully!<br /> Error were found in <b>{AllRowsUploaded}</b> records. Please update the Records and re-upload ONLY those Records<br />

                                <div className="invalidRows">
                                    <ul>
                                        {invalidRows.map((item, key) => {
                                            return (
                                                <li>
                                                    <b>Row {item['Row']}: </b> {item['Message']}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <hr className="divider" />

                            <div className="buttons-container" style={{ marginBottom: '0px' }}>
                                <div className="button-container" style={{ marginRight: '10px' }} onClick={() => window.location.reload()}>
                                    <button style={{ backgroundColor: "#5B8BFF" }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
}

export default UploadExcel;
