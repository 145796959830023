exports.tableFormat = {
    headerLabels: [
        'Request Date',
        "UAT ID",
        "Customer", 
        'GBB Region',
        "Sub ID",
        "Region", //Region Requested
        "Model",
        "PTUs Requested",
        'Request State',
        "Request Granted Status",
        "Approved Date",
        "Region Granted", 
        "PTUs Granted", 
        "Tags",    
        "Notes",
        'Provision Date',
        'Purchase Date',
        'Deprovision Date',
        'Churn Date',
        'Churn Reason',
    ],
    keys: [
        'GetHelp_RequestDate',
        "GetHelpID",
        "CustomerName",
        'GBB_Region',
        "SubscriptionID",
        "RegionRequested",
        "ModelName",
        "PTU_Requested",
        'RequestState',
        "RequestGrantStatus",
        "DateApproved",
        "RegionGranted",
        "PTU_Granted", 
        "Tag",
        "Notes",
        'ProvisionDate',
        'PurchaseDate',
        'DeprovisionDate',
        'ChurnDate',
        'ChurnReason',
    ],
    
    LabelKeyPair: [
        { label: 'Request Date', key: 'GetHelp_RequestDate' },
        { label: 'UAT ID', key: 'GetHelpID' },
        { label: 'Customer', key: 'CustomerName' },
        { label: 'Sub ID', key: 'SubscriptionID' },
        { label: 'GBB Region', key: 'GBB_Region' },
        { label: 'Region', key: 'RegionRequested' },
        { label: 'Model', key: 'ModelName' },
        { label: 'PTUs Requested', key: 'PTU_Requested' },
        { label: 'Request Granted Status', key: 'RequestGrantStatus' },
        { label: 'Approved Date', key: 'DateApproved' },
        { label: 'Region Granted', key: 'RegionGranted' },
        { label: 'PTUs Granted', key: 'PTU_Granted' },
        { label: 'Tags', key: 'Tag' },
        { label: 'Notes', key: 'Notes' },
        { label: 'Provision Date', key: 'ProvisionDate' },
        { label: 'Purchase Date', key: 'PurchaseDate' },
        { label: 'Deprovision Date', key: 'DeprovisionDate' },
        { label: 'Churn Date', key: 'ChurnDate' },
        { label: 'Churn Reason', key: 'ChurnReason' },
        { label: 'Request State', key: 'RequestState' },
    ],
    bulkUpdate: [
        { label: 'Request Granted Status', key: 'RequestGrantStatus' },
        { label: 'Approved Date', key: 'DateApproved' },
        { label: 'Notes', key: 'Notes' },
        { label: 'Provision Date', key: 'ProvisionDate' },
        { label: 'Churn Date', key: 'ChurnDate' },
        { label: 'Deprovision Date', key: 'DeprovisionDate' },
        { label: 'Purchase Date', key: 'PurchaseDate' },
        { label: 'Request State', key: 'RequestState' },
        { label: 'Churn Reason', key: 'ChurnReason' }, 
        // { label: 'Tags', key: 'Tag' }, 
    ]
};
exports.headerLabels= [
    {label:"Region",keys:"Region"},
    {label:"Sellable ETA",keys:"SellableETAmonth"},
    {label:"GPU Physical",keys:"GPUPhysical"},
    {label:"GPU Holdback",keys:"GPUHoldback"},
    {label:"GPU Sellable",keys:"GPUSellable"},
    {label:"GPU set aside for PTU-C",keys:"GPUsetaside"},
    {label:"GPU set aside for PTU-M",keys:"GPU_SetAside_GPT4"},
    {label:"ABCD Models",keys:"ABCDModels"},
    {label:"GPU GPT-4 Paygo",keys:"GPUGPT4Paygo"},
    {label:"GPU Turbo Paygo",keys:"GPUTurboPaygo"},
    {label:"Fine Tuning",keys:"FineTuning"},
];

exports.ptuHeaderLabels= [
    {label:"Region",keys:"Region"},
    {label:"PTUs budgeted to sell",keys:"PTUBudgetedToSell"},
    {label:"Quota PTUs(Table)",keys:"QuotaPTU"},
    {label:"Commitment PTUs(Table)",keys:"CommitmentPTU"},
    {label:"PTUs Sold(Active)",keys:"PTUSold"},
    {label:"PTUs Approved for Allocation",keys:"PTUApprovedForAllocation"},
    {label:"Sellable PTUs",keys:"SellablePTU"},
    {label:"Available PTUs",keys:"AvailablePTU"},
];
