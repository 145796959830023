import * as React from "react";
import Reactselect from "react-select";
import filterImg from "./../../../assets/filter.png";
import tags from "./../../../assets/tags.png";
import arrowUp from "./../../../assets/arrowUp.png";
import arrowDown from "./../../../assets/arrowDown.png";
import "./allSubmission.css";
import "../mainPage/mainPage.css";
import "../../components/PTUAvailable/ptuAvailable.css";
import TableComponent from "../../components/tableComponent/tableComponent/tablecomponent";
import ApproveRequest from "../requestApproval/requestApproval";
import axios from "axios";
import baseConfig from "../../../config/config";
import * as XLSX from "xlsx";
import { tableFormat } from "../../../config/tableFormat";
import copy from "clipboard-copy";
import MailModal from "../mail/mail";
import BulkUpdate from "../bulk_update/bulkupdate";
import bulkUpdateImg from "./../../../assets/bulkUpdate.png";
import mailImg from "./../../../assets/mail.png";
import addImg from "./../../../assets/add.png";
import exportImg from "./../../../assets/exportImg.png";
import crossImg from "./../../../assets/cross.png";
import linkImg from "./../../../assets/copyLink.png";
import Detail from "../detail/detail";
import UpdateTags from "../updateTags/UpdateTags";

import { components } from 'react-select';

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPtusData,
  getAllClassicTableData,
  getClassicPtuUsers,
  getDistinctDataThroughSingleApi,
  getPTUTriageTags,
} from "../../../redux/actions/actions";
import {
  updateClassicRegionGranted,
  updateClassicTableDataByRequestId,
  updateAllClassicTableData,
} from "../../../redux/reducers/rootReducer";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Draggable from "../../components/tableComponent/tableComponent/Draggable";
import AutoConfigure from "../autoConfigure/autoConfigure";
import PTUTable from "../ptuTable/PTUTable";
const moment = extendMoment(originalMoment);

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="far fa-calendar fa-sm" style={{ color: '#333' }} aria-hidden="true"></i>
    </components.DropdownIndicator>
  );
};

const CustomValueContainerForDate = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {React.Children.map(children, child =>
        child && child.type !== components.Input ? child : null
      )}
    </components.ValueContainer>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const selectedValues = props.getValue();
  const { selectProps, hasValue } = props;
  const handleRemove = (removedValue) => {
    const newValue = selectedValues.filter(val => val.value !== removedValue.value);
    selectProps.onChange(newValue, { action: 'remove-value', removedValue });
  };

  return (
    <components.ValueContainer {...props}>
      <div className="drag-tag" onClick={props.selectProps.onMenuOpen}>
        <Draggable showScroll={false}>
          {!hasValue && !props.selectProps.inputValue && (
            <div className="custom-placeholder" style={{ color: "#888888" }}>{selectProps.placeholder}</div>
          )}
          {selectedValues.map((val, index) => (
            <span className="filter-value" key={val.value} style={{ marginRight: '8px' }}>
              {val.label} {"  "}
              <span className="multiselect-remove">
                <img src={crossImg} onClick={() => handleRemove(val)} width={"13px"} />
              </span>
            </span>
          ))}
        </Draggable>
      </div>
    </components.ValueContainer>
  );
};


const reactSelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "Segoe UI",
    fontSize: "13px",
    fontWeight: "600",
    minHeight: "20px",
    height: "32.5px",
    width: "18.6vw",
    border: "1px solid #888888",
    borderRadius: "0px",
    zIndex: 1,
    boxShadow: state.isFocused ? "0px 0px 0px black" : "none",
    "&:hover": {
      border: "1px solid #888888",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    // whiteSpace: 'nowrap',
    flexWrap: "unset",
    zIndex: 10002,
    fontFamily:
      'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
    fontSize: "5px",
    fontWeight: "600",
    height: "32px",
    padding: "0px 8px 0px 8px",
    width: "18.5vw",
  }),
  multiValue: (base) => ({
    ...base,
    flex: "1 0 auto",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    boxShadow: state.isFocused ? "0px 0px 4px black" : "none",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    top: "6px",
    left: "280px",
  }),
};

function AllSubmissions(props) {
  const { filters } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [preFiterSelected, setPreFilterSelected] = React.useState([]);

  const [regionData, setRegionData] = React.useState([]);
  const classicRegionData = useSelector((store) =>
    store.ptusData.ptusData !== undefined ? store.ptusData.ptusData : []
  );
  const classicRegionDataError = useSelector(
    (store) => store.ptusData.ptusDataError
  );
  const isClassicRegionDataLoaded = useSelector((store) =>
    store.ptusData.isPtusDataLoaded !== undefined
      ? store.ptusData.isPtusDataLoaded
      : []
  );
  const [regionSums, setRegionSums] = React.useState([]);
  const sumByRegion = {};

  const [openApprovalModal, setOpenApproveModal] = React.useState(false);
  const [openAutoConfigureModal, setOpenAutoConfigureModal] = React.useState(false);
  const [selectedAutoConfigurableIndex, setSelectedAutoConfigurableIndex] = React.useState([]);
  const [showBackDrop, setShowBackDrop] = React.useState(false);
  const [capacityAllocation, setCapacityAllocation] = React.useState(false);
  const [tableData, setTableData] = React.useState(null);
  const [isCtrlActive, setIsCtrlActive] = React.useState(false);

  const classicTableDataReducer = useSelector((store) =>
    store.classicTableData.classicTableData !== undefined
      ? store.classicTableData.classicTableData
      : []
  );
  const isClassicTableDataLoading = useSelector(
    (store) => store.classicTableData.isClassicTableDataLoading
  );
  const isClassicTableDataLoaded = useSelector(
    (store) => store.classicTableData.isClassicTableDataLoaded
  );
  const classicTableDataError = useSelector(
    (store) => store.classicTableData.classicTableDataError
  );
  const [newTab, setnewTab] = React.useState(null);
  const autoManualRef = React.useRef();
  const [selectedData, setSelectedData] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [filterSelected, setFilterSelected] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(false);
  const [openBulkUpdate, setOpenBulkUpdate] = React.useState(false);
  const [openTagUpdate, setOpenTagUpdate] = React.useState(false);
  const [OpenMailModel, setOpenMailModel] = React.useState(false); const [openAddEntryModal, setOpenAddEntryModal] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [copyLinkText, setCopyLinkText] = React.useState("Copy Link");

  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  // const [allCustomer, setAllCustomer] = React.useState(null);
  const allCustomer = useSelector((store) =>
    store.allClassicCustomers.allClassicCustomers !== undefined
      ? store.allClassicCustomers.allClassicCustomers
      : []
  );
  const [selectUATID, setSelectedUATD] = React.useState(null);
  const [selectedSUBID, setSelectedSUBID] = React.useState(null);

  const UATIDs = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .UATIDClassic !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .UATIDClassic
      : null)
  const SUBIDs = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .SubIdClassic !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .SubIdClassic
      : null)
  const unModifiedTableDataReducer = useSelector((state) => state.classicUnchangedTableData.classicTableData);
  const isTagsLoading = useSelector((store) => store.PTUTriageTags.isTagsLoading !== undefined ? store.PTUTriageTags.isTagsLoading : null);
  const isAllClassicCustomersLoaded = useSelector(
    (store) => store.allClassicCustomers.isAllClassicCustomersLoaded
  );
  const isAllClassicCustomersError = useSelector(
    (store) => store.allClassicCustomers.isAllClassicCustomersError
  );
  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [selectedRequestGranted, setSelectedRequestGranted] =
    React.useState(null);
  const [selectedApprovedDate, setSelectedApprovedDate] = React.useState(null);
  const [subscriptionType, setSubscriptionType] = React.useState({
    value: "PTU",
    label: "subscriptionType",
  });
  const [requestState, setRequestState] = React.useState(null);
  const [getHelpRequestDate, setGetHelpRequestDate] = React.useState(null);
  const [ProvisionDate, setProvisionDate] = React.useState(null);
  const [expectedPuchaseDate, setExpectedPuchaseDate] = React.useState(null);
  const [purchaseDate, setPurchaseDate] = React.useState(null);
  const [assignedName, setAssignedName] = React.useState(null);
  const [driName, setDriName] = React.useState(null);
  const [notes, setNotes] = React.useState(null);
  const [showPTUAvailable, setShowPTUAvailable] = React.useState(null);

  const requestStateOption = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .RequestState !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .RequestState
      : null
  );
  const requestGrantedStatusOptions = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .RequestGrantStatus !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .RequestGrantStatus
      : null
  );
  const gbbRegionOptions = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .GBBRegion !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .GBBRegion
      : null
  );
  const modelNameOptions = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .ClassicModelName !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .ClassicModelName
      : null
  );
  const regionGrantedOptions = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
      .PTUTriageRegionGranted !== undefined
      ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
        .PTUTriageRegionGranted
      : null
  );
  const PTUTriageTagsReducer = useSelector((store) =>
    store.PTUTriageTags.PTUTriageTagsState !== undefined
      ? store.PTUTriageTags.PTUTriageTagsState
      : null
  );
  const PTUTriageTagsLoaded = useSelector((store) =>
    store.PTUTriageTags.isTagsLoaded !== undefined
      ? store.PTUTriageTags.isTagsLoaded
      : null
  );
  const isDistinctDataThroughSingleApiLoaded = useSelector(
    (store) =>
      store.distinctDataThroughSingleApi.isDistinctDataThroughSingleApiLoaded
  );
  const distinctDataThroughSingleApiError = useSelector(
    (store) =>
      store.distinctDataThroughSingleApi.distinctDataThroughSingleApiError
  );
  const [filteredTableDataState, setFilteredTableDataState] = React.useState(
    []
  );
  const userRole = useSelector((store) => store.userRoleData.role);
  const isUserRoleLoadedError = useSelector(
    (store) => store.userRoleData.isUserRoleLoadedError
  );
  const [selectedModelOptions, setSelectedModelOptions] = React.useState([]);

  const [isReadyForUpdate, setIsRaedyForUpdate] = React.useState(false);

  const [selectedRegionGranted, setSelectedRegionGranted] =
    React.useState(null);

  const [loader, setLoader] = React.useState(false);
  const [tableLoader, setTableLoader] = React.useState(true);
  const requestDateCalendarRef = React.useRef(null);
  const approvedDateCalendarRef = React.useRef(null);
  const provisionDateCalendarRef = React.useRef(null);
  const purchaseDateCalendarRef = React.useRef(null);
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [selectedTagOptions, setSelectedTagOptions] = React.useState([]);
  const setSelectAllChange = (value) => {
    setSelectAll(value);
  };

  const midpoint = Math.ceil(regionData.length / 2);

  const getRegionList = async () => {
    if (!isClassicRegionDataLoaded) {
      await dispatch(fetchPtusData()); // same call as fetchRegionData
    }
    setRegionData(classicRegionData);
  };

  const handleRequestStateChange = async (selectedOption) => {
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    // Find the index of the JSON object with property 'RequestGrantStatusID' if it exists
    const RequestStateIDIndex = preselectedFilter.findIndex(
      (item) => item.property === "RequestStateID"
    );

    if (RequestStateIDIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(RequestStateIDIndex, 1);
      } else {
        selectedOption.property = "RequestStateID";
        preselectedFilter[RequestStateIDIndex] = selectedOption;
      }
    } else {
      if (selectedOption) {
        selectedOption.property = "RequestStateID";
        preselectedFilter.push(selectedOption);
      }
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    if (!selectedOption) {
      selectedOption = { value: "", label: "" };
      setRequestState(null);
    } else {
      setRequestState(selectedOption);
    }
    selectedOption.property = "RequestStateID";
    fetchData(selectedOption);
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  function formatDate(dateString) {
    if (dateString.includes("/")) {
      return dateString;
    }
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${month}/${day}/${year}`;
    }
    return "";
  }

  const [showRequestDateCalender, setShowRequestDateCalender] =
    React.useState(false);
  const [requestDateContentValue, setRequestDateContentValue] =
    React.useState("");
  const [requestDatePickerValue, setRequestDatePickerValue] =
    React.useState(null);
  const [requestDateRenderKey, setRequestDateRenderKey] = React.useState(0);
  const onRequestDateSelect = (requestDatePickerValue, states) => {
    setRequestDatePickerValue(requestDatePickerValue);
    const formattedValue = `${requestDatePickerValue.start.format(
      "MM-DD-YYYY"
    )} - ${requestDatePickerValue.end.format("MM-DD-YYYY")}`;
    setRequestDateContentValue(formattedValue);
    handleGetHelpRequestDateChange(
      formatDate(requestDatePickerValue.start.format("YYYY-MM-DD")),
      formatDate(requestDatePickerValue.end.format("YYYY-MM-DD"))
    );
  };
  const clearRequestDateSelect = () => {
    setRequestDatePickerValue(null);
    setRequestDateContentValue("");
    setRequestDateRenderKey(requestDateRenderKey + 1);
    handleGetHelpRequestDateClear();
    setShowRequestDateCalender(false)
  };
  const handleUATIDChange = async (selectedOption) => {
    const fetchDataUAT = {
      property: "GetHelpID",
      ...selectedOption,
    };
    setSelectedUATD(fetchDataUAT);

    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const UATIdIndex = preselectedFilter.findIndex(
      (item) => item.property === "GetHelpID"
    );

    if (UATIdIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(UATIdIndex, 1);
      } else {
        selectedOption.property = "GetHelpID";
        preselectedFilter[UATIdIndex] = selectedOption
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "GetHelpID",
        value: selectedOption.value,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem(
      "ptuFilter",
      JSON.stringify(preselectedFilter)
    );
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchDataUAT);
    // setSelectedIndex(null);
    setSelectAll(false);
  };
  const handleSUBIDChange = async (selectedOption) => {
    const fetchDataSUB = {
      property: "SubscriptionID",
      ...selectedOption,
    };
    setSelectedSUBID(fetchDataSUB);

    const preselectedFilter = preFiterSelected;

    const SUBIdIndex = preselectedFilter.findIndex(
      (item) => item.property === "SubscriptionID"
    );

    if (SUBIdIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(SUBIdIndex, 1);
      } else {
        selectedOption.property = "SubscriptionID";
        preselectedFilter[SUBIdIndex] = selectedOption
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "SubscriptionID",
        value: selectedOption.value,
      });
    }
    // Set the updated preselectedFilter in the state
    sessionStorage.setItem(
      "ptuFilter",
      JSON.stringify(preselectedFilter)
    );
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchDataSUB);
    // setSelectedIndex(null);
    setSelectAll(false);
  };
  const handleGetHelpRequestDateClear = async (startDate, endDate) => {
    setGetHelpRequestDate(null);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const GetHelp_RequestDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "GetHelp_RequestDate"
    );

    if (GetHelp_RequestDateIndex !== -1) {
      preFiterSelected.splice(GetHelp_RequestDateIndex, 1);
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchDataClear("GetHelp_RequestDate");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleGetHelpRequestDateChange = async (startDate, endDate) => {
    const fetchDataGetHelpRequestDate = {
      property: "GetHelp_RequestDate",
      startDateValue: startDate,
      endDateValue: endDate,
    };
    setGetHelpRequestDate(fetchDataGetHelpRequestDate);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const GetHelp_RequestDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "GetHelp_RequestDate"
    );

    if (GetHelp_RequestDateIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(GetHelp_RequestDateIndex, 1);
      } else {
        preselectedFilter[GetHelp_RequestDateIndex].startDateValue = startDate;
        preselectedFilter[GetHelp_RequestDateIndex].endDateValue = endDate;
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "GetHelp_RequestDate",
        startDateValue: startDate,
        endDateValue: endDate,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);



    // Perform other actions if needed
    fetchData(fetchDataGetHelpRequestDate);
    // setSelectedIndex(null);
    setSelectAll(false);
    setShowRequestDateCalender(false)
  };

  const [showProvisionDateCalender, setShowProvisionDateCalender] =
    React.useState(false);
  const [provisionDateContentValue, setProvisionDateContentValue] =
    React.useState("");
  const [provisionDatePickerValue, setProvisionDatePickerValue] =
    React.useState(null);
  const [provisionDateRenderKey, setProvisionDateRenderKey] = React.useState(0);
  const onProvisionDateSelect = (provisionDatePickerValue, states) => {
    setProvisionDatePickerValue(provisionDatePickerValue);
    const formattedValue = `${provisionDatePickerValue.start.format(
      "MM-DD-YYYY"
    )} - ${provisionDatePickerValue.end.format("MM-DD-YYYY")}`;
    setProvisionDateContentValue(formattedValue);
    handleProvisionDateChange(
      formatDate(provisionDatePickerValue.start.format("YYYY-MM-DD")),
      formatDate(provisionDatePickerValue.end.format("YYYY-MM-DD"))
    );
  };

  const clearProvisionDateSelect = () => {
    setProvisionDatePickerValue(null);
    setProvisionDateContentValue("");
    setProvisionDateRenderKey(provisionDateRenderKey + 1);
    handleProvisionDateClear();
    setShowProvisionDateCalender(false);
  };

  const handleProvisionDateClear = async () => {
    setProvisionDate(null);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const ProvisionDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "ProvisionDate"
    );

    if (ProvisionDateIndex !== -1) {
      preFiterSelected.splice(ProvisionDateIndex, 1);
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    // Perform other actions if needed
    fetchDataClear("ProvisionDate");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleProvisionDateChange = async (startDate, endDate) => {
    const fetchDataTechnicalEnablement = {
      property: "ProvisionDate",
      startDateValue: startDate,
      endDateValue: endDate,
    };
    setProvisionDate(fetchDataTechnicalEnablement);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const ProvisionDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "ProvisionDate"
    );

    if (ProvisionDateIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(ProvisionDateIndex, 1);
      } else {
        preselectedFilter[ProvisionDateIndex].startDateValue = startDate;
        preselectedFilter[ProvisionDateIndex].endDateValue = endDate;
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "ProvisionDate",
        startDateValue: startDate,
        endDateValue: endDate,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchDataTechnicalEnablement);
    // setSelectedIndex(null);
    setSelectAll(false);
    setShowProvisionDateCalender(false);
  };

  const handleCustomerNameChange = async (selectedOption) => {
    const fetchDataCustomer = {
      property: "CustomerName",
      ...selectedOption
    };
    setSelectedCustomer(fetchDataCustomer);

    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const CustomerNameIndex = preselectedFilter.findIndex(
      (item) => item.property === "CustomerName"
    );

    if (CustomerNameIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(CustomerNameIndex, 1);
      } else {
        preselectedFilter[CustomerNameIndex].value = selectedOption.value;
      }
    } else {
      // If it doesn't exist, add it to the array
      if (selectedOption) {
        selectedOption.property = "CustomerName";
        preselectedFilter.push(selectedOption);
      }
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);
    fetchData(fetchDataCustomer);

    // fetchData(fetchDataCustomer);
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleNotesChange = async (selectedOption) => {
    const fetchDataNotes = {
      property: "Notes",
      value: selectedOption,
    };
    setNotes(fetchDataNotes);

    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const NoteIndex = preselectedFilter.findIndex(
      (item) => item.property === "Notes"
    );

    if (NoteIndex !== -1) {
      if (selectedOption === "") {
        preFiterSelected.splice(NoteIndex, 1);
      } else {
        preselectedFilter[NoteIndex].value = selectedOption;
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "Notes",
        value: selectedOption,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchDataNotes);
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const [showPurchaseDateCalender, setShowPurchaseDateCalender] =
    React.useState(false);
  const [purchaseDateContentValue, setPurchaseDateContentValue] =
    React.useState("");
  const [purchaseDatePickerValue, setPurchaseDatePickerValue] =
    React.useState(null);
  const [purchaseDateRenderKey, setPurchaseDateRenderKey] = React.useState(0);
  const onPurchaseDateSelect = (purchaseDatePickerValue, states) => {
    setPurchaseDatePickerValue(purchaseDatePickerValue);
    const formattedValue = `${purchaseDatePickerValue.start.format(
      "MM-DD-YYYY"
    )} - ${purchaseDatePickerValue.end.format("MM-DD-YYYY")}`;
    setPurchaseDateContentValue(formattedValue);
    handleSubIDChange(
      formatDate(purchaseDatePickerValue.start.format("YYYY-MM-DD")),
      formatDate(purchaseDatePickerValue.end.format("YYYY-MM-DD"))
    );
  };

  const clearPurchaseDateSelect = () => {
    setPurchaseDatePickerValue(null);
    setPurchaseDateContentValue("");
    setPurchaseDateRenderKey(purchaseDateRenderKey + 1);
    handleSubIDClear();
    setShowPurchaseDateCalender(false);
  };

  const handleSubIDClear = async () => {
    setPurchaseDate(null);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const PurchaseDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "PurchaseDate"
    );

    if (PurchaseDateIndex !== -1) {
      preFiterSelected.splice(PurchaseDateIndex, 1);
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchDataClear("PurchaseDate");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleSubIDChange = async (startDate, endDate) => {
    const fetchPurchaseDate = {
      property: "PurchaseDate",
      startDateValue: startDate,
      endDateValue: endDate,
    };
    setPurchaseDate(fetchPurchaseDate);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const PurchaseDateIndex = preselectedFilter.findIndex(
      (item) => item.property === "PurchaseDate"
    );

    if (PurchaseDateIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(PurchaseDateIndex, 1);
      } else {
        preselectedFilter[PurchaseDateIndex].startDateValue = startDate;
        preselectedFilter[PurchaseDateIndex].endDateValue = endDate;
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "PurchaseDate",
        startDateValue: startDate,
        endDateValue: endDate,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchPurchaseDate);
    // setSelectedIndex(null);
    setSelectAll(false);
    setShowPurchaseDateCalender(false);
  };

  const handleRegionChange = async (selectedOption) => {
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const regionIndex = preselectedFilter.findIndex(
      (item) => item.property === "GBB_Region"
    );

    if (regionIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(regionIndex, 1);
      } else {
        selectedOption.property = "GBB_Region";
        preselectedFilter[regionIndex] = selectedOption;
      }
    } else {
      if (selectedOption) {
        selectedOption.property = "GBB_Region";
        preselectedFilter.push(selectedOption);
      }
    }
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    if (!selectedOption) {
      selectedOption = { value: "", label: "" };
      setSelectedRegion(null);
    } else {
      setSelectedRegion(selectedOption);
    }

    selectedOption.property = "GBB_Region";

    fetchData(selectedOption);
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleRegionGrantedChange = async (selectedOption) => {
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    const regionGrantedIndex = preselectedFilter.findIndex(
      (item) => item.property === "RegionGranted"
    );

    if (regionGrantedIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(regionGrantedIndex, 1);
      } else {
        selectedOption.property = "RegionGranted";
        preselectedFilter[regionGrantedIndex] = selectedOption;
      }
    } else {
      if (selectedOption) {
        selectedOption.property = "RegionGranted";
        preselectedFilter.push(selectedOption);
      }
    }
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    if (!selectedOption) {
      selectedOption = { value: -1, label: "" };
      setSelectedRegionGranted(null);
    } else {
      setSelectedRegionGranted(selectedOption);
    }

    selectedOption.property = "RegionGranted";

    fetchData(selectedOption);
    // setSelectedIndex(null);
    setSelectAll(false);
  };
  const [openModelSelect, setOpenModelSelect] = React.useState(false);
  const openModelSelectRef = React.useRef();
  const [openTagSelect, setOpenTagSelect] = React.useState(false);
  const openTagSelectRef = React.useRef();
  const handleModelChange = async (selectedModelOptions) => {
    setSelectedModelOptions(selectedModelOptions);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;
    const modelIndex = preselectedFilter.findIndex(
      (item) => item.property === "ModelName"
    );

    if (modelIndex !== -1) {
      if (!selectedModelOptions) {
        preFiterSelected.splice(modelIndex, 1);
      } else {
        // selectedOption.property = "ModelName";
        preselectedFilter[modelIndex] = {
          property: "ModelName",
          value: selectedModelOptions,
        };
      }
    } else {
      if (selectedModelOptions) {
        // selectedOption.property = "ModelName";
        preselectedFilter.push({
          property: "ModelName",
          value: selectedModelOptions,
        });
      }
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    if (!selectedModelOptions) {
      selectedModelOptions = [{ value: "", label: "" }];
      setSelectedModel({ property: "ModelName", value: "" });
    } else {
      setSelectedModel({
        property: "ModelName",
        value: selectedModelOptions?.map((item) => item.label),
      });
    }

    fetchData(selectedModelOptions, "ModelName");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleTagChange = async (selectedTagOptions) => {
    setSelectedTagOptions(selectedTagOptions);
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;
    const tagIndex = preselectedFilter.findIndex(
      (item) => item.property === "Tag"
    );

    if (tagIndex !== -1) {
      if (!selectedTagOptions) {
        preFiterSelected.splice(tagIndex, 1);
      } else {
        preselectedFilter[tagIndex] = {
          property: "Tag",
          value: selectedTagOptions,
        };
      }
    } else {
      if (selectedTagOptions) {
        preselectedFilter.push({
          property: "Tag",
          value: selectedTagOptions,
        });
      }
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem(
      "ptuFilter",
      JSON.stringify(preselectedFilter)
    );
    setPreFilterSelected(preselectedFilter);

    if (!selectedTagOptions) {
      selectedTagOptions = [{ value: "", label: "" }];
      setSelectedTag({ property: "Tag", value: "" });
    } else {
      setSelectedTag({
        property: "Tag",
        value: selectedTagOptions?.map((item) => item.label),
      });
    }

    fetchData(selectedTagOptions, "Tag");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleRequestGrantedChange = async (selectedOption) => {
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    // Find the index of the JSON object with property 'RequestGrantStatusID' if it exists
    const requestGrantedIndex = preselectedFilter.findIndex(
      (item) => item.property === "RequestGrantStatusID"
    );

    if (requestGrantedIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(requestGrantedIndex, 1);
      } else {
        selectedOption.property = "RequestGrantStatusID";
        preselectedFilter[requestGrantedIndex] = selectedOption;
      }
    } else {
      if (selectedOption) {
        selectedOption.property = "RequestGrantStatusID";
        preselectedFilter.push(selectedOption);
      }
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    if (!selectedOption) {
      selectedOption = { value: "", label: "" };
      setSelectedRequestGranted(null);
    } else {
      setSelectedRequestGranted(selectedOption);
    }
    selectedOption.property = "RequestGrantStatusID";
    fetchData(selectedOption);
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const [showDateApprovedCalender, setShowDateApprovedCalender] =
    React.useState(false);
  const [dateApprovedContentValue, setDateApprovedContentValue] =
    React.useState("");
  const [dateApprovedPickerValue, setDateApprovedPickerValue] =
    React.useState(null);
  const [dateApprovedRenderKey, setDateApprovedRenderKey] = React.useState(0);
  const onDateApprovedSelect = (dateApprovedPickerValue, states) => {
    setDateApprovedPickerValue(dateApprovedPickerValue);
    const formattedValue = `${dateApprovedPickerValue.start.format(
      "MM-DD-YYYY"
    )} - ${dateApprovedPickerValue.end.format("MM-DD-YYYY")}`;
    setDateApprovedContentValue(formattedValue);
    handleApprovedDateChange(
      formatDate(dateApprovedPickerValue.start.format("YYYY-MM-DD")),
      formatDate(dateApprovedPickerValue.end.format("YYYY-MM-DD"))
    );
  };

  const clearDateApprovedSelect = () => {
    setDateApprovedPickerValue(null);
    setDateApprovedContentValue("");
    setDateApprovedRenderKey(dateApprovedRenderKey + 1);
    handleApprovedDateClear();
    setShowDateApprovedCalender(false);
  };

  const handleApprovedDateClear = async () => {
    setSelectedApprovedDate(null);

    // Parse the current URL and get the preselectedFilter array
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    // Find the index of the JSON object with property 'DateApproved' if it exists
    const dateApprovedIndex = preselectedFilter.findIndex(
      (item) => item.property === "DateApproved"
    );

    if (dateApprovedIndex !== -1) {
      preFiterSelected.splice(dateApprovedIndex, 1);
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    // Perform other actions if needed
    fetchDataClear("DateApproved");
    // setSelectedIndex(null);
    setSelectAll(false);
  };

  const handleApprovedDateChange = async (startDate, endDate) => {
    const fetchApprovedDate = {
      property: "DateApproved",
      startDateValue: startDate,
      endDateValue: endDate,
    };
    setSelectedApprovedDate(fetchApprovedDate);

    // Parse the current URL and get the preselectedFilter array
    const url = new URL(window.location.href);
    const preselectedFilter = preFiterSelected;

    // Find the index of the JSON object with property 'DateApproved' if it exists
    const dateApprovedIndex = preselectedFilter.findIndex(
      (item) => item.property === "DateApproved"
    );

    if (dateApprovedIndex !== -1) {
      if (!selectedOption) {
        preFiterSelected.splice(dateApprovedIndex, 1);
      } else {
        preselectedFilter[dateApprovedIndex].startDateValue = startDate;
        preselectedFilter[dateApprovedIndex].endDateValue = endDate;
      }
    } else {
      // If it doesn't exist, add it to the array
      preselectedFilter.push({
        property: "DateApproved",
        startDateValue: startDate,
        endDateValue: endDate,
      });
    }

    // Set the updated preselectedFilter in the state
    sessionStorage.setItem("ptuFilter", JSON.stringify(preselectedFilter));
    setPreFilterSelected(preselectedFilter);

    fetchData(fetchApprovedDate);
    // setSelectedIndex(null);
    setSelectAll(false);
    setShowDateApprovedCalender(false);
  };

  const closeConfirmationModal = () => {
    setOpenApproveModal(false);
    setShowBackDrop(false);
  };

  const closeAutoConfigureModal = () => {
    setOpenAutoConfigureModal(false);
    autoManualRef.current.value = 'Manual';
    setShowBackDrop(false);
  };

  const closeBulkUpdate = () => {
    setOpenBulkUpdate(false);
    setShowBackDrop(false);
  };
  const closeTagUpdate = () => {
    setOpenTagUpdate(false);
    setShowBackDrop(false);
  };
  const openMailModal = () => {
    setOpenMailModel(true);
    setShowBackDrop(true);
  };

  const closeMailModal = () => {
    setOpenMailModel(false);
    setShowBackDrop(false);
  };
  const openAddModal = () => {
    setOpenAddEntryModal(true);
    setShowBackDrop(true);
  };
  const openTagsUpdateModal = () => {
    setOpenTagUpdate(true);
    setShowBackDrop(true);
  };

  const closeAddModal = () => {
    setOpenAddEntryModal(false);
    setShowBackDrop(false);
  };
  const openApproval = () => {
    if (selectedIndex && selectedIndex.length > 0) {
      setOpenApproveModal(true);
      setShowBackDrop(true);
      mergeData();
    }
  };

  const openBulkUpdateModal = () => {
    setOpenBulkUpdate(true);
    setShowBackDrop(true);
  };

  const handleAutoConfigure = (e) => {
    if (e.target.value === 'auto') {
      setShowBackDrop(true);
      fetchAutoConfigurableData();
    }
  }

  const handleCapacityChange = () => {
    if (autoManualRef.current.value !== 'Manual') {
      showToast("Please choose manual mode", "danger");
    } else if (selectedIndex?.length === 0 || selectedIndex === null) {
      showToast("Please select records", "danger");
    }
    setCapacityAllocation(true);
  };
  const mergeData = () => {
    if (newTab) {
      const newData = [...newTab];
      if (selectedIndex && selectedIndex.length > 0) {
        selectedIndex.forEach((id, i) => {
          const indexNew = newData.findIndex((item) => item.RequestID === id);
          const indexEdi = classicTableDataReducer.findIndex(
            (item) => item.RequestID === id
          );
          newData[indexNew] = classicTableDataReducer[indexEdi];
        });
      }
      setSelectedData(newData);
    } else {
      setSelectedData(newTab);
    }
  };

  const handletableChange = (event, id, label, row) => {
    // const newData = [...tableData];
    const newData = JSON.parse(JSON.stringify([...classicTableDataReducer]));
    let newIndex = newData.findIndex((item) => item.RequestID === id);
    let filter = newData?.filter((item) => item.RequestID === id);
    const index = newData[newIndex];
    // const index = JSON.parse(JSON.stringify(newData[newIndex]));
    const existingItem = regionSums.find((mergedItem) => mergedItem.id === id);
    if (index) {
      if (event?.target) {
        const findValue = regionGrantedOptions?.filter(
          (item) => item.Key === event.target.value
        );
        if (label === "RegionGrantedID") {
          index["RegionGrantedID"] = event.target.value;
          index[row] = findValue[0]?.Value;
        } else {
          index["RegionGrantedID"] = filter[0].RegionGrantedID;
          index["RegionGranted"] = filter[0].RegionGranted;
          index[label] = event.target.value;
        }
        // index[label] = event.target.value;
        if (label === "RequestGrantStatusID") {
          let eventTemp = requestGrantedStatusOptions.find(
            (item) => item.Key === event.target.value
          );
          index["RequestGrantStatus"] = eventTemp.Value;
        } else if (label === "RegionGrantedID") {
          let eventTemp = regionGrantedOptions.find(
            (item) => item.Key === event.target.value
          );
          index["RegionGranted"] = eventTemp.Value;
        }
        if (label === "DateApproved") {
          if(event.target.value){
            const val = event.target.value.split("-");
            index[label] = val[1] + "/" + val[2] + "/" + val[0];
          }else{
            index[label] = ""
          }
        }
      } else if (label === 'Tag') {
        if (event) {
          index["Tag"] = event.map(option => option.label).join(',');
        } else {
          index["Tag"] = event;
        }
      } else {
        index[label] = event;
      }
    }
    if (label === "PTU_Granted") {
      if (existingItem) {
        existingItem.value = event.target.value;
        existingItem.region = filter[0].RegionGranted;
      } else {
        regionSums.push({
          region: filter[0].RegionGranted,
          value: event.target.value,
          id: id,
        });
      }
    }
    if (label === "RegionGrantedID") {
      if (existingItem) {
        if (existingItem.id === id) {
          existingItem.value = filter[0].PTU_Granted;
          existingItem.region = filter[0].RegionGranted;
        }
      } else {
        regionSums.push({
          region: filter[0].RegionGranted,
          value: filter[0].PTU_Granted,
          id: id,
        });
      }
    }
    if (
      label === "PTU_Granted" ||
      (label === "RegionGrantedID" && event.target.value >= 0)
    ) {
      joinDataWithRegionSums();
    }
    dispatch(
      updateClassicTableDataByRequestId({ requestId: id, updatedObject: index })
    );
    // setEditedData(newData);
    // setTableData(newData);
    // setFilteredTableDataState(newData);
  };

  const joinDataWithRegionSums = () => {
    regionSums.forEach((item) => {
      const region = item.region;
      const value = parseInt(item.value);

      if (!isNaN(value)) {
        sumByRegion[region] = (sumByRegion[region] || 0) + value;
      }
    });
    const updatedData = regionData.map((item) => {
      const region = item.Region;
      const regionSumss = sumByRegion[region] || 0;

      // if (regionSumss <= JSON.parse(item.AvailablePTU)) {
      if (true) {
        return {
          ...item,
          AllotedPTU: regionSumss,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    dispatch(updateClassicRegionGranted({ updatedData: updatedData }));
    setRegionData(updatedData);
    Object.keys(sumByRegion).forEach((key) => delete sumByRegion[key]);
  };

  React.useEffect(() => {
    if (
      isAllClassicCustomersError ||
      distinctDataThroughSingleApiError ||
      classicRegionDataError ||
      classicTableDataError ||
      isUserRoleLoadedError
    ) {
      navigate("/errorPage");
    }
  }, [
    isAllClassicCustomersError,
    distinctDataThroughSingleApiError,
    classicRegionDataError,
    classicTableDataError,
    isUserRoleLoadedError,
  ]);

  React.useEffect(() => {
    updateTableData();
  }, []);
  React.useEffect(() => {

    return () => {
      if (unModifiedTableDataReducer.length > 0) {
        dispatch(
          updateAllClassicTableData(unModifiedTableDataReducer)
        )
      }
    };
  }, [unModifiedTableDataReducer]);

  React.useEffect(() => {
    setRegionData(classicRegionData);
  }, []);

  React.useEffect(() => {
    setRegionData(classicRegionData);
  });

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const getAllData = async () => {
    setCapacityAllocation(false);
    if (!isClassicTableDataLoaded) {
      dispatch(getAllClassicTableData());
    }
  };

  // React.useEffect(()=>{
  //     // setTableData(classicTableDataReducer);
  //     setnewTab(classicTableDataReducer);
  //     // setTableLoader(false);
  //     fetchDataForFilters();
  // },[isClassicTableDataLoading]);

  React.useEffect(() => {
    if (!isDistinctDataThroughSingleApiLoaded) {
      dispatch(getDistinctDataThroughSingleApi());
    }
    else if (isClassicTableDataLoaded) {
      decodeVanityURl();
    }
  }, [isDistinctDataThroughSingleApiLoaded, isClassicTableDataLoaded]);

  const fetchFiltersOnLink = async (postData) => {
    setCapacityAllocation(false);
    setFilterSelected(false);
    setSelectedOption(postData);
    // setFilterSelected(true);
    const defaultPostData = {
      SubscriptionType: "PTU",
      RequestStateID: -1,
      GetHelpID: "",
      RequestGrantStatusID: -99,
      GetHelp_RequestDate: "",
      DateApproved: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: -1,
      ModelName: "",
      Notes: "",
      Tag: "",
      DRI_Name: "",
      SubscriptionID: ""
    };

    if (postData.RequestStateID === "") {
      postData.RequestStateID = -1;
    }

    function getRegionGrantedFromKey(regionKey) {
      const curentRegion = regionGrantedOptions.filter(
        (item) => item.Key === regionKey
      );
      if (curentRegion) {
        return curentRegion[0].Value;
      }
      return "";
    }
    if (postData.RegionGranted !== -1) {
      postData.RegionGranted = getRegionGrantedFromKey(postData.RegionGranted);
    }
    if (
      postData.RequestGrantStatusID !== -99 &&
      postData.RequestGrantStatusID !== ""
    ) {
      postData.RequestGrantStatusID = parseInt(postData.RequestGrantStatusID);
    } else if (postData.RequestGrantStatusID === "") {
      postData.RequestGrantStatusID = -99;
    }

    if (postData.GBB_Region !== "") {
      postData.GBB_Region = postData.GBB_Region.toUpperCase();
    }

    function matchesProvidedObject(record) {
      for (let key in postData) {
        if (postData.hasOwnProperty(key) && record.hasOwnProperty(key)) {
          const providedValue = postData[key];
          const recordValue = record[key];

          if (key === "GBB_Region" || key === "CustomerName" || key === "GetHelpID" || key === "SubscriptionID") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const trimmedProvidedValue = providedValue.trim();
              const trimmedRecordValue = recordValue.trim();

              if (
                trimmedProvidedValue.toLowerCase() !==
                defaultPostData[key].toLowerCase() &&
                trimmedProvidedValue.toLowerCase() !==
                trimmedRecordValue.toLowerCase()
              ) {
                return false;
              }
            }
          } else if (key === "ModelName") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              // return true;
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              if (!providedLabels.some((label) => label === recordValue)) {
                return false;
              }
            }
          } else if (key === "Tag") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              const recordLabels = recordValue.split(',').map(label => label.trim());
              if (!providedLabels.some(label => recordLabels.includes(label))) {
                return false;
              }
            }
          } else if (key === "Notes") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const providedLower = providedValue.toLowerCase();
              const recordLower = recordValue.toLowerCase();
              if (!recordLower.includes(providedLower)) {
                return false;
              }
            }
          } else if (/date/i.test(key)) {
            if (
              providedValue.startDateValue &&
              typeof recordValue === "string"
            ) {
              const isInRange = isDateWithinRange(recordValue, providedValue);
              if (!isInRange) return false;
            }
          } else {
            if (
              providedValue !== defaultPostData[key] &&
              providedValue !== recordValue
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }

    const filteredTableData = classicTableDataReducer.filter(
      matchesProvidedObject
    );
    setTableData(filteredTableData);
    setFilteredTableDataState(filteredTableData);
    setnewTab(filteredTableData);
    setFilterSelected(true);
    setFilterSelected(true);
  };

  const updateTableData = () => {
    const postData = {
      SubscriptionType: "PTU",
      RequestStateID: requestState?.value || -1,
      GetHelpID: selectUATID?.value || "",
      RequestGrantStatusID: selectedRequestGranted?.value || -99,
      GetHelp_RequestDate: getHelpRequestDate || "",
      DateApproved: selectedApprovedDate || "",
      ProvisionDate: ProvisionDate || "",
      ExpectedPuchaseDate: expectedPuchaseDate?.value || "",
      PurchaseDate: purchaseDate || "",
      CustomerName: selectedCustomer?.value || "",
      AssignedToName: assignedName?.value || "",
      GBB_Region: selectedRegion?.value || "",
      RegionGranted: selectedRegionGranted?.value || -1,
      ModelName: selectedModel?.value || "",
      Notes: notes?.value || "",
      DRI_Name: driName?.value || "",
      Tag: selectedTag?.value || "",
      SubscriptionID: selectedSUBID?.value || ""
    };
    const defaultPostData = {
      SubscriptionType: "PTU",
      RequestStateID: -1,
      GetHelpID: "",
      RequestGrantStatusID: -99,
      GetHelp_RequestDate: "",
      DateApproved: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: -1,
      ModelName: "",
      Notes: "",
      DRI_Name: "",
      Tag: "",
      SubscriptionID: ""
    };

    if (postData.RequestStateID === "") {
      postData.RequestStateID = -1;
    }

    function getRegionGrantedFromKey(regionKey) {
      const curentRegion = regionGrantedOptions.filter(
        (item) => item.Key === regionKey
      );
      if (curentRegion) {
        return curentRegion[0].Value;
      }
      return "";
    }
    if (postData.RegionGranted !== -1) {
      postData.RegionGranted = getRegionGrantedFromKey(postData.RegionGranted);
    }
    if (
      postData.RequestGrantStatusID !== -99 &&
      postData.RequestGrantStatusID !== ""
    ) {
      postData.RequestGrantStatusID = parseInt(postData.RequestGrantStatusID);
    } else if (postData.RequestGrantStatusID === "") {
      postData.RequestGrantStatusID = -99;
    }

    if (postData.GBB_Region !== "") {
      postData.GBB_Region = postData.GBB_Region.toUpperCase();
    }
    function matchesProvidedObject(record) {
      for (let key in postData) {
        if (postData.hasOwnProperty(key) && record.hasOwnProperty(key)) {
          const providedValue = postData[key];
          const recordValue = record[key];

          if (key === "GBB_Region" || key === "CustomerName" || key === "GetHelpID" || key === "SubscriptionID") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const trimmedProvidedValue = providedValue.trim();
              const trimmedRecordValue = recordValue.trim();

              if (
                trimmedProvidedValue.toLowerCase() !==
                defaultPostData[key].toLowerCase() &&
                trimmedProvidedValue.toLowerCase() !==
                trimmedRecordValue.toLowerCase()
              ) {
                return false;
              }
            }
          } else if (key === "ModelName") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              if (!providedLabels.some((label) => label === recordValue)) {
                return false;
              }
            }
          } else if (key === "Tag") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              const recordLabels = recordValue.split(',').map(label => label.trim());
              if (!providedLabels.some(label => recordLabels.includes(label))) {
                return false;
              }
            }
          }
          else if (key === "Notes") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const providedLower = providedValue.toLowerCase();
              const recordLower = recordValue.toLowerCase();
              if (!recordLower.includes(providedLower)) {
                return false;
              }
            }
          } else if (/date/i.test(key)) {
            if (
              providedValue.startDateValue &&
              typeof recordValue === "string"
            ) {
              const isInRange = isDateWithinRange(recordValue, providedValue);
              if (!isInRange) return false;
            }
          } else {
            if (
              providedValue !== defaultPostData[key] &&
              providedValue !== recordValue
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }
    const filteredTableData = classicTableDataReducer.filter(
      matchesProvidedObject
    );
    setTableData(filteredTableData);
    setnewTab(filteredTableData);
  };

  const isDateWithinRange = (recordedDate, providedValues) => {
    // Parse dates
    const startDate = moment(providedValues.startDateValue, "MM/DD/YYYY");
    const endDate = moment(providedValues.endDateValue, "MM/DD/YYYY");
    const recorded = moment(recordedDate, "MM/DD/YYYY");

    // Check if recorded date is within the range
    return recorded.isBetween(startDate, endDate, null, "[]");
  };

  const fetchDataClear = async (property) => {
    // debugger
    setFilterSelected(false);
    setTableLoader(true);
    const postData = {
      SubscriptionType: "PTU",
      RequestStateID: requestState?.value || -1,
      GetHelpID: selectUATID?.value || "",
      RequestGrantStatusID: selectedRequestGranted?.value || -99,
      GetHelp_RequestDate: getHelpRequestDate ? { ...getHelpRequestDate } : "",
      DateApproved: selectedApprovedDate ? { ...selectedApprovedDate } : "",
      ProvisionDate: ProvisionDate ? { ...ProvisionDate } : "",
      ExpectedPuchaseDate: expectedPuchaseDate?.value || "",
      PurchaseDate: purchaseDate ? { ...purchaseDate } : "",
      CustomerName: selectedCustomer?.value || "",
      AssignedToName: assignedName?.value || "",
      GBB_Region: selectedRegion?.value || "",
      RegionGranted: selectedRegionGranted?.value || -1,
      ModelName: selectedModel?.value || "",
      Notes: notes?.value || "",
      DRI_Name: driName?.value || "",
      Tag: selectedTag?.value || "",
      SubscriptionID: selectedSUBID?.value || ""
    };
    if (property) {
      postData[property] = "";
    }
    setSelectedOption(postData);
    const defaultPostData = {
      SubscriptionType: "PTU",
      RequestStateID: -1,
      RequestGrantStatusID: -99,
      GetHelpID: "",
      GetHelp_RequestDate: "",
      DateApproved: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: -1,
      ModelName: "",
      Notes: "",
      DRI_Name: "",
      Tag: "",
      SubscriptionID: ""
    };
    if (postData.RequestStateID === "") {
      postData.RequestStateID = -1;
    }
    function getRegionGrantedFromKey(regionKey) {
      const curentRegion = regionGrantedOptions.filter(
        (item) => item.Key === regionKey
      );
      if (curentRegion) {
        return curentRegion[0].Value;
      }
      return "";
    }
    if (postData.RegionGranted !== -1) {
      postData.RegionGranted = getRegionGrantedFromKey(postData.RegionGranted);
    }
    if (
      postData.RequestGrantStatusID !== -99 &&
      postData.RequestGrantStatusID !== ""
    ) {
      postData.RequestGrantStatusID = parseInt(postData.RequestGrantStatusID);
    } else if (postData.RequestGrantStatusID === "") {
      postData.RequestGrantStatusID = -99;
    }

    if (postData.GBB_Region !== "") {
      postData.GBB_Region = postData.GBB_Region.toUpperCase();
    }

    function matchesProvidedObject(record) {
      for (let key in postData) {
        if (postData.hasOwnProperty(key) && record.hasOwnProperty(key)) {
          const providedValue = postData[key];
          const recordValue = record[key];

          if (key === "GBB_Region" || key === "CustomerName" || key === 'GetHelpID' || key === "SubscriptionID") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const trimmedProvidedValue = providedValue.trim();
              const trimmedRecordValue = recordValue.trim();

              if (
                trimmedProvidedValue.toLowerCase() !==
                defaultPostData[key].toLowerCase() &&
                trimmedProvidedValue.toLowerCase() !==
                trimmedRecordValue.toLowerCase()
              ) {
                return false;
              }
            }
          } else if (key === "ModelName") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              // return true;
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              if (!providedLabels.some((label) => label === recordValue)) {
                return false;
              }
            }
          } else if (key === "Tag") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              const recordLabels = recordValue.split(',').map(label => label.trim());
              if (!providedLabels.some(label => recordLabels.includes(label))) {
                return false;
              }
            }
          } else if (key === "Notes") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const providedLower = providedValue.toLowerCase();
              const recordLower = recordValue.toLowerCase();
              if (!recordLower.includes(providedLower)) {
                return false;
              }
            }
          } else if (/date/i.test(key)) {
            if (
              providedValue.startDateValue &&
              typeof recordValue === "string"
            ) {
              const isInRange = isDateWithinRange(recordValue, providedValue);
              if (!isInRange) return false;
            }
          } else {
            if (
              providedValue !== defaultPostData[key] &&
              providedValue !== recordValue
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }
    const filteredTableData = classicTableDataReducer.filter(
      matchesProvidedObject
    );
    setTableData(filteredTableData);
    setFilteredTableDataState(filteredTableData);
    setnewTab(filteredTableData);
    setFilterSelected(true);
    setTableLoader(false);
  };


  const fetchAutoConfigurableData = async () => {
    const postData = {
      SubscriptionType: "PTU",
      RequestStateID: 21,
      GetHelpID: "",
      RequestGrantStatusID: -99,
      GetHelp_RequestDate: "",
      DateApproved: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: "",
      ModelName: ["gpt-4"],
      Tag: "",
      Notes: "",
      DRI_Name: "",
      PTU_Requested: "",
      PTU_Granted: "",
      RegionRequested: "",
      SubscriptionID: ""
    };
    const defaultPostData = {
      SubscriptionType: "PTU",
      RequestStateID: -1,
      RequestGrantStatusID: -99,
      GetHelp_RequestDate: "",
      DateApproved: "",
      GetHelpID: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: "",
      ModelName: "",
      Tag: "",
      Notes: "",
      DRI_Name: "",
      PTU_Requested: "",
      PTU_Granted: "",
      RegionRequested: "",
      SubscriptionID: ""
    };

    function matchesProvidedObject(record) {
      for (let key in postData) {
        if (postData.hasOwnProperty(key) && record.hasOwnProperty(key)) {
          const providedValue = postData[key];
          const recordValue = record[key];
          if (key === "ModelName") {
            if (!recordValue || recordValue == 'TBD') return false

          } else if (key === "RequestStateID") {
            if (!(recordValue == '1' || recordValue == '11')) return false;
          } else if (key === "PTU_Requested") {
            if (recordValue && recordValue == '0') return false;
          } else if (key === "PTU_Granted") {
            if (recordValue && recordValue != '0') return false;
          } else if (key === "RegionRequested") {
            if (recordValue == 'TBD' || recordValue.includes(';') || recordValue.includes(',')) return false;
          } else if (key === "RegionGranted") {
            if (recordValue) return false;
          } else if (key === "RegionGrantedID") {
            if (recordValue != '0' || recordValue) return false;
          } else if (key === 'RequestGrantStatusID') {
            if (recordValue == '3') return false;
          } else if (key === 'SubscriptionID') {
            if (recordValue && recordValue === 'TBD') return false;
          }
          else {
            if (
              providedValue !== defaultPostData[key] &&
              providedValue !== recordValue
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }
    const filteredTableData = classicTableDataReducer.filter(matchesProvidedObject);
    const arrayOfIDs = filteredTableData.map(obj => obj.RequestID);
    setSelectedAutoConfigurableIndex(arrayOfIDs);
    setOpenAutoConfigureModal(true);
  };

  const fetchData = async (selected, property) => {
    setCapacityAllocation(false);
    setFilterSelected(false);
    setTableLoader(true);
    const postData = {
      SubscriptionType: "PTU",
      RequestStateID: requestState?.value || -1,
      GetHelpID: selectUATID?.value || "",
      RequestGrantStatusID: selectedRequestGranted?.value || -99,
      GetHelp_RequestDate: getHelpRequestDate ? { ...getHelpRequestDate } : "",
      DateApproved: selectedApprovedDate ? { ...selectedApprovedDate } : "",
      ProvisionDate: ProvisionDate ? { ...ProvisionDate } : "",
      ExpectedPuchaseDate: expectedPuchaseDate?.value || "",
      PurchaseDate: purchaseDate ? { ...purchaseDate } : "",
      CustomerName: selectedCustomer?.value || "",
      AssignedToName: assignedName?.value || "",
      GBB_Region: selectedRegion?.value || "",
      RegionGranted: selectedRegionGranted?.value || -1,
      ModelName: selectedModel?.value || "",
      Tag: selectedTag?.value || "",
      Notes: notes?.value || "",
      DRI_Name: driName?.value || "",
      SubscriptionID: selectedSUBID?.value || ""
    };
    if (selected === "clear") {
      postData.CustomerName = "";
      setSelectedCustomer(null);
      postData.PurchaseDate = "";
      postData.GetHelpID = "";
      setSelectedUATD(null)
      postData.SubscriptionID = "";
      setSelectedSUBID(null)
      setPurchaseDate(null);
      postData.AssignedToName = "";
      setAssignedName(null);
      postData.GBB_Region = "";
      setSelectedRegion(null);
      postData.RegionGranted = -1;
      setSelectedRegionGranted(null);
      postData.ModelName = "";
      setSelectedModel(null);
      postData.RequestGrantStatusID = -99;
      setSelectedRequestGranted(null);
      postData.DateApproved = "";
      setSelectedApprovedDate(null);
      postData.RequestStateID = -1;
      setRequestState(null);
      postData.GetHelp_RequestDate = "";
      setGetHelpRequestDate(null);
      postData.ProvisionDate = "";
      setProvisionDate(null);
      postData.ExpectedPuchaseDate = "";
      setExpectedPuchaseDate(null);
      postData.SubscriptionType = "";
      setSubscriptionType(null);
      postData.DRI_Name = "";
      setDriName(null);
      postData.Notes = "";
      setNotes(null);
      if (preFiterSelected) {
        window.location.replace("/Home");
      }
    } else if (property) {
      postData[property] = selected.map((option) => option.label);
      setErrorLabel("");
      setRowErrors("");
    } else if (selected && selected.property.toLowerCase().includes("date")) {
      postData[selected.property] = selected["value"]
        ? selected.value
        : selected;
      setErrorLabel("");
      setRowErrors("");
    } else if (selected) {
      postData[selected.property] = selected["value"];
      setErrorLabel("");
      setRowErrors("");
    }
    setSelectedOption(postData);
    // setFilterSelected(true);
    const defaultPostData = {
      SubscriptionType: "PTU",
      RequestStateID: -1,
      RequestGrantStatusID: -99,
      GetHelpID: "",
      GetHelp_RequestDate: "",
      DateApproved: "",
      ProvisionDate: "",
      ExpectedPuchaseDate: "",
      PurchaseDate: "",
      CustomerName: "",
      AssignedToName: "",
      GBB_Region: "",
      RegionGranted: -1,
      ModelName: "",
      Notes: "",
      DRI_Name: "",
      Tag: "",
      SubscriptionID: ""
    };

    if (postData.RequestStateID === "") {
      postData.RequestStateID = -1;
    }
    function getRegionGrantedFromKey(regionKey) {
      const curentRegion = regionGrantedOptions.filter(
        (item) => item.Key === regionKey
      );
      if (curentRegion) {
        return curentRegion[0].Value;
      }
      return "";
    }
    if (postData.RegionGranted !== -1) {
      postData.RegionGranted = getRegionGrantedFromKey(postData.RegionGranted);
    }
    if (
      postData.RequestGrantStatusID !== -99 &&
      postData.RequestGrantStatusID !== ""
    ) {
      postData.RequestGrantStatusID = parseInt(postData.RequestGrantStatusID);
    } else if (postData.RequestGrantStatusID === "") {
      postData.RequestGrantStatusID = -99;
    }

    if (postData.GBB_Region !== "") {
      postData.GBB_Region = postData.GBB_Region.toUpperCase();
    }
    function matchesProvidedObject(record) {
      for (let key in postData) {
        if (postData.hasOwnProperty(key) && record.hasOwnProperty(key)) {
          const providedValue = postData[key];
          const recordValue = record[key];

          if (key === "GBB_Region" || key === "CustomerName" || key === "GetHelpID" || key === "SubscriptionID") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const trimmedProvidedValue = providedValue.trim();
              const trimmedRecordValue = recordValue.trim();

              if (
                trimmedProvidedValue.toLowerCase() !==
                defaultPostData[key].toLowerCase() &&
                trimmedProvidedValue.toLowerCase() !==
                trimmedRecordValue.toLowerCase()
              ) {
                return false;
              }
            }
          } else if (key === "ModelName") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              // return true;
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              if (!providedLabels.some((label) => label === recordValue)) {
                return false;
              }
            }
          } else if (key === "Tag") {
            if (Array.isArray(providedValue) && providedValue.length === 0) {
              continue;
            }
            if (Array.isArray(providedValue)) {
              const providedLabels = providedValue;
              const recordLabels = recordValue.split(',').map(label => label.trim());
              if (!providedLabels.some(label => recordLabels.includes(label))) {
                return false;
              }
            }
          } else if (key === "Notes") {
            if (
              typeof providedValue === "string" &&
              typeof recordValue === "string"
            ) {
              const providedLower = providedValue.toLowerCase();
              const recordLower = recordValue.toLowerCase();
              if (!recordLower.includes(providedLower)) {
                return false;
              }
            }
          } else if (/date/i.test(key)) {
            if (
              providedValue.startDateValue &&
              typeof recordValue === "string"
            ) {
              const providedCopy = { ...providedValue };
              const isInRange = isDateWithinRange(recordValue, providedCopy);
              if (!isInRange) return false;
            }
          } else {
            if (
              providedValue !== defaultPostData[key] &&
              providedValue !== recordValue
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }

    const filteredTableData = classicTableDataReducer.filter(
      matchesProvidedObject
    );
    setTableData(filteredTableData);
    setFilteredTableDataState(filteredTableData);
    setnewTab(filteredTableData);
    setFilterSelected(true);
    setTableLoader(false);
  };

  const getUserData = async () => {
    if (!isAllClassicCustomersLoaded) {
      await dispatch(getClassicPtuUsers());
    }
  };

  const getVanityURl = async (url) => {
    const postData = {
      URL: JSON.stringify(url)
    };
    const config = {
      headers: {
        "content-type": "application/json",
        serviceid: window.btoa(
          sessionStorage.getItem("Emailid") +
          ";" +
          sessionStorage.getItem("userName")
        ),
      },
    };

    try {
      const response = await axios.post(
        baseConfig.apiBaseUrl + "/VanityURL/GetID",
        postData,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        navigate("/errorPage");
      }
    }
  };

  const fetchDataForFilters = async (filter) => {
    let currentURL = new URL(window.location.href);
    try {
      if (!PTUTriageTagsLoaded) {
        dispatch(getPTUTriageTags());
      } if (!isDistinctDataThroughSingleApiLoaded) {
        dispatch(getDistinctDataThroughSingleApi());
      }
      setLoader(false);
      if (filter) {
        // const parts = filter.split('/');
        // const jsonPart = parts[parts.length - 1].split(']')[0] + ']';
        // filter = JSON.parse(decodeURIComponent(filter));
        const postData = {
          SubscriptionType: "PTU",
          RequestStateID: -1,
          RequestGrantStatusID: -99,
          GetHelpID: "",
          GetHelp_RequestDate: "",
          DateApproved: "",
          ProvisionDate: "",
          ExpectedPuchaseDate: "",
          PurchaseDate: "",
          CustomerName: "",
          AssignedToName: "",
          GBB_Region: "",
          RegionGranted: -1,
          ModelName: "",
          Notes: "",
          Tag: "",
          DRI_Name: "",
          SubscriptionID: "",
        };
        let filterJson = filter;
        setPreFilterSelected(filterJson);

        // const subscriptionItem = filterJson.filter(item => item.label === 'SubscriptionType')
        // if (subscriptionItem.length === 1) {
        //     if (subscriptionItem[0]?.value === '') {
        //         setSubscriptionType(null);
        //     }
        //     else {
        //         setSubscriptionType(subscriptionItem[0])
        //         postData[subscriptionItem[0]?.label] = subscriptionItem[0]?.value || '';
        //     }
        // }

        const requestStateItem = filterJson.filter(
          (item) => item.property === "RequestStateID"
        );
        if (requestStateItem.length === 1) {
          setRequestState(requestStateItem[0]);
          postData[requestStateItem[0]?.property] =
            requestStateItem[0]?.value || "";
        }

        const requestGrantedStatusItem = filterJson.filter(
          (item) => item.property === "RequestGrantStatusID"
        );
        if (requestGrantedStatusItem.length === 1) {
          setSelectedRequestGranted(requestGrantedStatusItem[0]);
          postData[requestGrantedStatusItem[0]?.property] =
            requestGrantedStatusItem[0]?.value || "";
        }

        const getHelpRequestDateItem = filterJson.filter(
          (item) => item.property === "GetHelp_RequestDate"
        );
        if (getHelpRequestDateItem.length === 1) {
          setGetHelpRequestDate(getHelpRequestDateItem[0]);
          postData[getHelpRequestDateItem[0]?.property] =
            getHelpRequestDateItem[0] || "";
          const formattedValue = `${getHelpRequestDateItem[0].startDateValue} - ${getHelpRequestDateItem[0].endDateValue}`;
          setRequestDateContentValue(formattedValue);
          const startDate = moment(
            getHelpRequestDateItem[0].startDateValue,
            "MM/DD/YYYY"
          );
          const endDate = moment(
            getHelpRequestDateItem[0].endDateValue,
            "MM/DD/YYYY"
          );
          const range = moment.range(startDate, endDate);
          setRequestDatePickerValue(range);
        }

        const dateApprovedItem = filterJson.filter(
          (item) => item.property === "DateApproved"
        );
        if (dateApprovedItem.length === 1) {
          setSelectedApprovedDate(dateApprovedItem[0]);
          postData[dateApprovedItem[0]?.property] = dateApprovedItem[0] || "";
          const formattedValue = `${dateApprovedItem[0].startDateValue} - ${dateApprovedItem[0].endDateValue}`;
          setDateApprovedContentValue(formattedValue);
          const startDate = moment(
            dateApprovedItem[0].startDateValue,
            "MM/DD/YYYY"
          );
          const endDate = moment(
            dateApprovedItem[0].endDateValue,
            "MM/DD/YYYY"
          );
          const range = moment.range(startDate, endDate);
          setDateApprovedPickerValue(range);
        }

        const technicalEnablementItem = filterJson.filter(
          (item) => item.property === "ProvisionDate"
        );
        if (technicalEnablementItem.length === 1) {
          setProvisionDate(technicalEnablementItem[0]);
          postData[technicalEnablementItem[0]?.property] =
            technicalEnablementItem[0] || "";
          const formattedValue = `${technicalEnablementItem[0].startDateValue} - ${technicalEnablementItem[0].endDateValue}`;
          setProvisionDateContentValue(formattedValue);
          const startDate = moment(
            technicalEnablementItem[0].startDateValue,
            "MM/DD/YYYY"
          );
          const endDate = moment(
            technicalEnablementItem[0].endDateValue,
            "MM/DD/YYYY"
          );
          const range = moment.range(startDate, endDate);
          setProvisionDatePickerValue(range);
        }

        const expectedPuchaseDateItem = filterJson.filter(
          (item) => item.property === "ExpectedPuchaseDate"
        );
        if (expectedPuchaseDateItem.length === 1) {
          setExpectedPuchaseDate(expectedPuchaseDateItem[0]);
          postData[expectedPuchaseDateItem[0]?.property] =
            expectedPuchaseDateItem[0]?.value || "";
        }

        const purchaseDateItem = filterJson.filter(
          (item) => item.property === "PurchaseDate"
        );
        if (purchaseDateItem.length === 1) {
          setPurchaseDate(purchaseDateItem[0]);
          postData[purchaseDateItem[0]?.property] = purchaseDateItem[0] || "";
          const formattedValue = `${purchaseDateItem[0].startDateValue} - ${purchaseDateItem[0].endDateValue}`;
          setPurchaseDateContentValue(formattedValue);
          const startDate = moment(
            purchaseDateItem[0].startDateValue,
            "MM/DD/YYYY"
          );
          const endDate = moment(
            purchaseDateItem[0].endDateValue,
            "MM/DD/YYYY"
          );
          const range = moment.range(startDate, endDate);
          setPurchaseDatePickerValue(range);
        }

        const customerItems = filterJson.filter(
          (item) => item.property === "CustomerName"
        );
        if (customerItems.length === 1) {
          setSelectedCustomer(customerItems[0]);
          postData[customerItems[0]?.property] = customerItems[0]?.value || "";
        }
        const UATIDitem = filterJson.filter(
          (item) => item.property === "GetHelpID"
        );
        if (UATIDitem.length === 1) {
          setSelectedUATD(UATIDitem[0]);
          postData[UATIDitem[0]?.property] = UATIDitem[0]?.value || "";
        }
        const SubscriptionIDitem = filterJson.filter(
          (item) => item.property === "SubscriptionID"
        );
        if (SubscriptionIDitem.length === 1) {
          setSelectedSUBID(SubscriptionIDitem[0]);
          postData[SubscriptionIDitem[0]?.property] = SubscriptionIDitem[0]?.value || "";
        }
        const assignedNameItem = filterJson.filter(
          (item) => item.property === "AssignedToName"
        );
        if (assignedNameItem.length === 1) {
          setAssignedName(assignedNameItem[0]);
          postData[assignedNameItem[0]?.property] =
            assignedNameItem[0]?.value || "";
        }

        const driNameItem = filterJson.filter(
          (item) => item.property === "DRI_Name"
        );
        if (driNameItem.length === 1) {
          setDriName(driNameItem[0]);
          postData[driNameItem[0]?.property] = driNameItem[0]?.value || "";
        }

        const notesItem = filterJson.filter(
          (item) => item.property === "Notes"
        );
        if (notesItem.length === 1) {
          setNotes(notesItem[0]);
          postData[notesItem[0]?.property] = notesItem[0]?.value || "";
        }

        const gbbRegionItem = filterJson.filter(
          (item) => item.property === "GBB_Region"
        );
        if (gbbRegionItem.length === 1) {
          setSelectedRegion(gbbRegionItem[0]);
          postData[gbbRegionItem[0]?.property] = gbbRegionItem[0]?.value || "";
        }

        const RegionGrantedItem = filterJson.filter(
          (item) => item.property === "RegionGranted"
        );
        if (RegionGrantedItem.length === 1) {
          setSelectedRegionGranted(RegionGrantedItem[0]);
          postData[RegionGrantedItem[0]?.property] =
            RegionGrantedItem[0]?.value || "";
        }

        const modelItem = filterJson?.filter(
          (item) => item.property === "ModelName"
        );
        if (modelItem.length === 1) {
          setSelectedModel({
            property: "ModelName",
            value: modelItem[0]?.value?.map((item) => item.label),
          });
          setSelectedModelOptions(modelItem[0].value);
          postData[modelItem[0]?.property] =
            modelItem[0]?.value?.map((item) => item.label) || "";
        }

        const tagItem = filterJson.filter(
          (item) => item.property === "Tag"
        );
        if (tagItem.length === 1) {
          setSelectedTag({
            property: "Tag",
            value: tagItem[0]?.value?.map((item) => item.label),
          });
          setSelectedTagOptions(tagItem[0].value);
          postData[tagItem[0]?.property] =
            tagItem[0]?.value?.map((item) => item.label) || "";
        }

        setSelectedOption(postData);
        setFilterSelected(true);
        fetchFiltersOnLink(postData);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        setTableData(classicTableDataReducer);
        setFilteredTableDataState(classicTableDataReducer);
        setnewTab(classicTableDataReducer);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const triggerExport = () => {
    const selectedEntries = [];

    for (const item of tableData) {
      if (selectedIndex.includes(item.RequestID)) {
        selectedEntries.push(item);
      }
    }

    const excelData = selectedEntries.map((item) => {
      const newItem = {};
      for (const column of tableFormat.keys) {
        if (item[column] == -1) {
          newItem[column] = "NA";
        } else {
          if (column === "PTU_Requested" || column === "PTU_Granted") {
            newItem[column] = parseInt(item[column])
          } else {
            newItem[column] = item[column];
          }
        }
      }
      return newItem;
    });
    const data = [
      tableFormat.headerLabels,
      ...excelData.map((item) => Object.values(item)),
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelFile = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "base64",
    });

    const link = document.createElement("a");
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
    link.download = "GBB Report.xlsx";
    link.click();
  };

  const handleLinkClick = async () => {
    setCopyLinkText("Copying...");
    let json = [];

    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const baseURL = `${parts[0]}//${parts[2]}`;

    let url = baseURL + `/Home/`;

    // if (subscriptionType) {
    //     let type = subscriptionType
    //     type.label = 'SubscriptionType'
    //     json.push(type)
    // }
    if (requestState) {
      json.push(requestState);
    }
    if (selectedRequestGranted) {
      json.push(selectedRequestGranted);
    }
    if (getHelpRequestDate) {
      json.push(getHelpRequestDate);
    }
    if (selectedApprovedDate) {
      json.push(selectedApprovedDate);
    }
    if (ProvisionDate) {
      json.push(ProvisionDate);
    } if (selectUATID) {
      json.push(selectUATID);
    }
    if (expectedPuchaseDate) {
      json.push(expectedPuchaseDate);
    }
    if (purchaseDate) {
      json.push(purchaseDate);
    }
    if (selectedCustomer) {
      json.push(selectedCustomer);
    } if (selectedSUBID) {
      json.push(selectedSUBID);
    }
    if (assignedName) {
      json.push(assignedName);
    }
    if (driName) {
      json.push(driName);
    }
    if (notes) {
      json.push(notes);
    }
    if (selectedRegion) {
      json.push(selectedRegion);
    }
    if (selectedRegionGranted) {
      json.push(selectedRegionGranted);
    }
    if (selectedModel) {
      json.push({ property: "ModelName", value: selectedModelOptions });
    }
    if (selectedTag) {
      json.push({ property: "Tag", value: selectedTagOptions });
    }
    if (sessionStorage.getItem("tab") == "1") {
      json.push({
        property: "ptuTriage",
        value: "ptuTriage",
      });
    }
    if (sessionStorage.getItem("tab") === "1") {
      json.push({
        property: "tab",
        value: "1",
      });
    }

    if (json.length > 0) {
      // let jsonString = encodeURIComponent(JSON.stringify(json));
      // let unProcessedUrl = jsonString;
      let vanityId = await getVanityURl(json);
      url = url + vanityId;
    }

    try {
      await copy(url);
      setCopyLinkText("Copied");

      setTimeout(() => {
        setCopyLinkText("Copy Link");
      }, 5000);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  const handleTableDataChange = (value) => {
    setnewTab(value);
  };

  const decodeVanityURl = async () => {
    if (sessionStorage.getItem("copyLinkuuid")) {
      const config = {
        headers: {
          "content-type": "application/json",
          serviceid: window.btoa(
            sessionStorage.getItem("Emailid") +
            ";" +
            sessionStorage.getItem("userName")
          ),
        },
      };

      try {
        const response = await axios.get(
          baseConfig.apiBaseUrl + "/VanityURL/GetURL/" + sessionStorage.getItem("copyLinkuuid"),
          config
        );
        if (response.status === 200) {
          // let decodedURL = decodeURIComponent(response.data);
          // window.location.replace(`/Home/${decodedURL}`);
          const tabvalue = JSON.parse(response.data).filter((item) => item.property === "tab");
          fetchDataForFilters(sessionStorage.getItem("ptuFilter") ? JSON.parse(sessionStorage.getItem("ptuFilter")) : tabvalue[0].value === '1' ? JSON.parse(response.data) : []);
        }
      } catch (error) {
        setPreFilterSelected(null);
        console.error(error);
        if (error.response.status === 401) {
          navigate("/errorPage");
        }
      }
    } else {
      fetchDataForFilters(sessionStorage.getItem("ptuFilter") ? JSON.parse(sessionStorage.getItem("ptuFilter")) : []);
    }
    setTableLoader(false);
  };

  function showToast(message, type) {
    const toaster = document.createElement("div");
    toaster.className = `toaster ${type}-toaster`;
    toaster.style.position = "fixed";
    toaster.style.top = "5%";
    toaster.style.left = "50%";
    toaster.style.transform = "translateX(-50%)";

    toaster.style.height = "25px"; // Adjust the height as needed
    toaster.style.maxWidth = "300px";
    toaster.style.zIndex = 999;

    toaster.innerText = message;

    document.body.appendChild(toaster);
    setTimeout(() => {
      // toaster.style.display = 'none'; // or toaster.remove(); to completely remove it from the DOM
      toaster.remove();
    }, 2000);
  }

  React.useEffect(() => {
    setRegionData(classicRegionData);
  }, [isClassicRegionDataLoaded]);

  React.useEffect(() => {
    if (!filters) {
      setLoader(false);
    }
    // if (filters) {
    //   decodeVanityURl();
    // }
    // fetchDataForFilters();
    getUserData();
    getAllData();
    getRegionList();
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey) {
        setIsCtrlActive(true);
      }
    });
    document.addEventListener("keyup", (e) => {
      if (!e.ctrlKey) {
        setIsCtrlActive(false);
      }
    });
    // joinDataWithRegionSums();
  }, []);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        requestDateCalendarRef?.current &&
        !requestDateCalendarRef.current.contains(event.target)
      ) {
        setShowRequestDateCalender(false);
      }
      if (
        approvedDateCalendarRef?.current &&
        !approvedDateCalendarRef.current.contains(event.target)
      ) {
        setShowDateApprovedCalender(false);
      }
      if (
        provisionDateCalendarRef?.current &&
        !provisionDateCalendarRef.current.contains(event.target)
      ) {
        setShowProvisionDateCalender(false);
      }
      if (
        purchaseDateCalendarRef?.current &&
        !purchaseDateCalendarRef.current.contains(event.target)
      ) {
        setShowPurchaseDateCalender(false);
      } if (
        openModelSelectRef?.current &&
        !openModelSelectRef.current.contains(event.target)
      ) {
        setOpenModelSelect(false);
      }
      if (
        openTagSelectRef?.current &&
        !openTagSelectRef.current.contains(event.target)
      ) {
        setOpenTagSelect(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [rowErrors, setRowErrors] = React.useState(
    Array(tableData?.length).fill("")
  );
  const [errorLabel, setErrorLabel] = React.useState(
    Array(tableData?.length).fill("")
  );

  const handleBlur = (event, rowIndex, label, row) => {
    let index = tableData?.findIndex((item) => item.RequestID === rowIndex);
    let selectedData = classicTableDataReducer?.find(
      (item) => item.RequestID === rowIndex
    );
    const selectedIndex = classicTableDataReducer?.filter((item) => {
      if (item.RequestID === rowIndex) {
        return true;
      }
      return false;
    });
    if (selectedIndex[0].RegionGrantedID == -1) {
      const updatedErrors = [...rowErrors];
      let error = `Please select region`;
      updatedErrors[index] = error;
      setErrorLabel("PTU_Granted");
      setRowErrors(updatedErrors);
      return;
    }
    const findVal = regionGrantedOptions.find(
      (i) => parseInt(i["Key"]) === parseInt(selectedData.RegionGrantedID)
    );
    let limit = regionData?.filter((item) => item?.Region === findVal["Value"]);
    let availPTU = limit[0]?.AvailablePTU
      ? JSON.parse(limit[0]?.AvailablePTU)
      : 0;
    if (event.target.value === "") {
      limit[0].AllotedPTU = 0;
    }
    let allotedPTU = availPTU - sumByRegion[selectedData.RegionGranted] || 0;
    allotedPTU =
      allotedPTU < 0
        ? parseInt(event.target.value) + parseInt(allotedPTU)
        : allotedPTU;
    const value = event.target.value;
    let error = "";
    // if (sumByRegion[selectedData.RegionGranted] > availPTU) {
    //         error = `Maximum limit: ${allotedPTU}`;
    //         const updatedErrors = [...rowErrors];
    //         updatedErrors[index] = error;
    //         setErrorLabel(label)
    //         setRowErrors(updatedErrors);
    //         event.target.value = value.slice(0, 5);
    // } else
    if (
      value.length > 5 ||
      (value !== "" && parseInt(value) <= availPTU - allotedPTU)
    ) {
      if (
        label === "InstancesRequested" ||
        label === "PTU_Requested" ||
        label === "PTU_Granted" ||
        label === "TPMGranted"
      ) {
        const numericValue = parseInt(value, 10);
        if (isNaN(numericValue) || numericValue > availPTU - allotedPTU) {
          error = "";
        }
      }
      const updatedErrors = [...rowErrors];
      updatedErrors[index] = error;
      setErrorLabel(label);
      setRowErrors(updatedErrors);
      event.target.value = value.slice(0, 5);
    }
    if (event.target.value < 0) {
      const updatedErrors = [...rowErrors];
      error = `PTU Granted should be positive number`;
      updatedErrors[index] = error;
      setErrorLabel("PTU_Granted");
      setRowErrors(updatedErrors);
    }

    if (event.target.value === "") {
      const updatedErrors = [...rowErrors];
      updatedErrors[index] = ``;
      setErrorLabel("PTU_Granted");
      setRowErrors(updatedErrors);
    }
    if (error !== "") {
      setIsRaedyForUpdate(true);
    } else {
      setIsRaedyForUpdate(false);
    }
  };

  const checkLimit = (event, rowIndex, label, row) => {
    let index = tableData?.findIndex((item) => item.RequestID === rowIndex);
    let selectedIndex = tableData?.filter(
      (item) => item.RequestID === rowIndex
    );
    const findVal = regionGrantedOptions.find(
      (i) => i["Key"] === event.target.value
    );
    let limit = regionData?.filter((item) => item?.Region === findVal["Value"]);
    const existingItem = regionSums.find(
      (mergedItem) => mergedItem.id === rowIndex
    );
    let availPTU = limit[0]?.AvailablePTU
      ? JSON.parse(limit[0]?.AvailablePTU)
      : 0;
    let allotedPTU = limit[0]?.AllotedPTU || 0;
    let error = "";
    // if (event.target.value === '-1') {
    //     const updatedErrors = [...rowErrors];
    //     updatedErrors[index] = error;
    //     setErrorLabel('PTU_Granted')
    //     setRowErrors(updatedErrors);
    // } else
    {
      // if ((availPTU-allotedPTU) < selectedIndex[0].PTU_Granted) {
      //     const updatedErrors = [...rowErrors];
      //     updatedErrors[index] = `Maximum limit: ${availPTU - allotedPTU}`;;
      //     setErrorLabel('PTU_Granted')
      //     setRowErrors(updatedErrors);
      //     setIsRaedyForUpdate(true)
      // }
      // else
      if (availPTU - allotedPTU > selectedIndex[0].PTU_Granted) {
        // let filter = newData.filter(item => item.RegionGrantedID === event.target.value)
        if (existingItem) {
          if (existingItem.id === rowIndex) {
            // existingItem.value = selectedIndex[0].PTU_Granted;
            // existingItem.region = selectedIndex[0].RegionGranted
          }
        } else {
          regionSums.push({
            region: selectedIndex[0].RegionGranted,
            value: selectedIndex[0].PTU_Granted,
            id: rowIndex,
          });
        }
        const updatedErrors = [...rowErrors];
        updatedErrors[index] = error;
        setErrorLabel("PTU_Granted");
        setRowErrors(updatedErrors);
        setIsRaedyForUpdate(false);
      }
    }
  };
  return (
    <>
      {!loader && (
        <>
          <div className="heading-container">
            <div className="image-container">
              <img src={filterImg} alt="Filter Icon" />
            </div>
            <div className="sub-heading">Filters</div>
            <div
              className="clear-all"
              onClick={() => {
                fetchData("clear");
                sessionStorage.removeItem("ptuFilter");
              }}
            >
              Clear All
            </div>
          </div>

          <div style={{ display: "flex", padding: "10px", paddingTop: "10px" }}>
            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 999,
              }}
            >
              <div className="dropdown-label"> Request State </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Request State"
                  placeholder="Choose Request State"
                  styles={reactSelectCustomStyles}
                  value={
                    requestState
                      ? {
                        value: requestState.value,
                        label: requestState.label,
                      }
                      : ""
                  }
                  options={requestStateOption?.map((item) => ({
                    value: item["Key"],
                    label: item["Value"],
                  }))}
                  onChange={(selectedOption) => {
                    handleRequestStateChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
              }}
              ref={requestDateCalendarRef}
            >
              <div className="dropdown-label"> Request Date </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: '10px'
                }}
                onClick={() =>
                  setShowRequestDateCalender(!showRequestDateCalender)
                }
              >
                <Reactselect
                  id="demo-select-small"
                  name="Choose Request Date"
                  readOnly
                  isClearable
                  placeholder="Choose Request Date"
                  styles={reactSelectCustomStyles}
                  value={
                    requestDateContentValue
                      ? {
                        value: requestDateContentValue,
                        label: requestDateContentValue,
                      }
                      : ""
                  }
                  components={{
                    ValueContainer: CustomValueContainerForDate,
                    DropdownIndicator
                  }}
                  menuIsOpen={false}
                  onChange={() => { clearRequestDateSelect() }}
                  classNamePrefix="react-select"
                />
              </div>
              {showRequestDateCalender && (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: -3,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                  >
                    <DateRangePicker
                      key={requestDateRenderKey}
                      value={requestDatePickerValue}
                      onSelect={onRequestDateSelect}
                      singleDateRange={true}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
              }}
              ref={approvedDateCalendarRef}
            >
              <div className="dropdown-label"> Approved Date </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: '10px'
                }}
                onClick={() =>
                  setShowDateApprovedCalender(!showDateApprovedCalender)
                }
              >
                <Reactselect
                  id="demo-select-small"
                  name="Choose Approved Date"
                  readOnly
                  isClearable
                  placeholder="Choose Approved Date"
                  styles={reactSelectCustomStyles}
                  value={
                    dateApprovedContentValue
                      ? {
                        value: dateApprovedContentValue,
                        label: dateApprovedContentValue,
                      }
                      : ""
                  }
                  components={{
                    ValueContainer: CustomValueContainerForDate,
                    DropdownIndicator
                  }}
                  menuIsOpen={false}
                  onChange={() => { clearDateApprovedSelect() }}
                  classNamePrefix="react-select"
                />
              </div>
              {showDateApprovedCalender && (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: -3,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                  >
                    <DateRangePicker
                      value={dateApprovedPickerValue}
                      key={dateApprovedRenderKey}
                      onSelect={onDateApprovedSelect}
                      singleDateRange={true}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
              }}
              ref={provisionDateCalendarRef}
            >
              <div className="dropdown-label"> Provision Date </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: '10px'
                }}
                onClick={() =>
                  setShowProvisionDateCalender(!showProvisionDateCalender)
                }
              >
                <Reactselect
                  id="demo-select-small"
                  name="Choose Provision Date"
                  readOnly
                  isClearable
                  placeholder="Choose Provision Date"
                  styles={reactSelectCustomStyles}
                  value={
                    provisionDateContentValue
                      ? {
                        value: provisionDateContentValue,
                        label: provisionDateContentValue,
                      }
                      : ""
                  }
                  components={{
                    ValueContainer: CustomValueContainerForDate,
                    DropdownIndicator
                  }}
                  menuIsOpen={false}
                  onChange={() => { clearProvisionDateSelect() }}
                  classNamePrefix="react-select"
                />
              </div>
              {showProvisionDateCalender && (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: -3,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                  >
                    <DateRangePicker
                      key={provisionDateRenderKey}
                      value={provisionDatePickerValue}
                      onSelect={onProvisionDateSelect}
                      singleDateRange={true}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 998,
              }}
            >
              <div className="dropdown-label"> Request Granted Status </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Status"
                  placeholder="Choose Status"
                  styles={reactSelectCustomStyles}
                  value={
                    selectedRequestGranted
                      ? {
                        value: selectedRequestGranted.value,
                        label: selectedRequestGranted.label,
                      }
                      : ""
                  }
                  options={requestGrantedStatusOptions?.map((item) => ({
                    value: item["Key"],
                    label: item["Value"],
                  }))}
                  onChange={(selectedOption) => {
                    handleRequestGrantedChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", padding: "10px", paddingTop: "0px" }}>
            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
              }}
              ref={purchaseDateCalendarRef}
            >
              <div className="dropdown-label"> Purchase Date </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: '10px'
                }}
                onClick={() =>
                  setShowPurchaseDateCalender(!showPurchaseDateCalender)
                }
              >
                <Reactselect
                  id="demo-select-small"
                  name="Choose Purchase Date"
                  readOnly
                  isClearable
                  placeholder="Choose Purchase Date"
                  styles={reactSelectCustomStyles}
                  value={
                    purchaseDateContentValue
                      ? {
                        value: purchaseDateContentValue,
                        label: purchaseDateContentValue,
                      }
                      : ""
                  }
                  components={{
                    ValueContainer: CustomValueContainerForDate,
                    DropdownIndicator
                  }}
                  menuIsOpen={false}
                  onChange={() => { clearPurchaseDateSelect() }}
                  classNamePrefix="react-select"
                />
              </div>
              {showPurchaseDateCalender && (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: -3,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                  >
                    <DateRangePicker
                      key={purchaseDateRenderKey}
                      value={purchaseDatePickerValue}
                      onSelect={onPurchaseDateSelect}
                      singleDateRange={true}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 998,
              }}
            >
              <div className="dropdown-label"> Customer Name </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Customer Name"
                  placeholder="Choose Customer Name"
                  styles={reactSelectCustomStyles}
                  value={
                    selectedCustomer?.value
                      ? {
                        value: selectedCustomer.value,
                        label: selectedCustomer.value,
                      }
                      : ""
                  }
                  options={allCustomer?.map((item, index) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(selectedOption) => {
                    handleCustomerNameChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 997,
              }}
            >
              <div className="dropdown-label"> GBB Region </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Region"
                  placeholder="Choose GBB Region"
                  styles={reactSelectCustomStyles}
                  value={
                    selectedRegion
                      ? {
                        value: selectedRegion.value,
                        label: selectedRegion.label,
                      }
                      : ""
                  }
                  options={gbbRegionOptions?.map((item) => ({
                    value: item["Key"],
                    label: item["Value"],
                  }))}
                  onChange={(selectedOption) => {
                    handleRegionChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 997,
              }}
              onClick={() => { setOpenModelSelect(!openModelSelect) }}
              ref={openModelSelectRef}
            >
              <div className="dropdown-label"> Model</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  openMenuOnFocus={true}
                  menuIsOpen={openModelSelect}
                  isMulti
                  isClearable
                  id="demo-select-small"
                  name="Choose Model"
                  placeholder="Choose Model"
                  styles={reactSelectCustomStyles}
                  options={modelNameOptions?.map((item) => ({
                    value: item["Key"],
                    label: item["Value"],
                  }))}
                  components={{ ValueContainer: CustomValueContainer }}
                  onChange={handleModelChange}
                  value={selectedModelOptions}
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 997,
              }}
            >
              <div className="dropdown-label"> Region Granted </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Region"
                  placeholder="Choose Region"
                  styles={reactSelectCustomStyles}
                  value={
                    selectedRegionGranted
                      ? {
                        value: selectedRegionGranted.value,
                        label: selectedRegionGranted.label,
                      }
                      : ""
                  }
                  options={regionGrantedOptions
                    ?.filter((item) => item["Key"] !== "-1")
                    .map((item) => ({
                      value: item["Key"],
                      label: item["Value"],
                    }))}
                  onChange={(selectedOption) => {
                    handleRegionGrantedChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", padding: "10px", paddingTop: "0px" }}>
            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 997,
              }}
            >
              <div className="dropdown-label"> UAT ID </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose UAT ID"
                  placeholder="Choose UAT ID"
                  styles={reactSelectCustomStyles}
                  value={
                    selectUATID?.value
                      ? {
                        value: selectUATID.value,
                        label: selectUATID.value,
                      }
                      : ""
                  }
                  options={UATIDs?.map((item) => ({
                    value: item["Value"],
                    label: item["Key"],
                  }))}
                  onChange={(selectedOption) => {
                    handleUATIDChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 997,
              }}
            >
              <div className="dropdown-label"> Sub ID </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <Reactselect
                  isClearable
                  id="demo-select-small"
                  name="Choose Sub ID"
                  placeholder="Choose Sub ID"
                  styles={reactSelectCustomStyles}
                  value={
                    selectedSUBID?.value
                      ? {
                        value: selectedSUBID.value,
                        label: selectedSUBID.value,
                      }
                      : ""
                  }
                  options={SUBIDs?.map((item) => ({
                    value: item["Value"],
                    label: item["Key"],
                  }))}
                  onChange={(selectedOption) => {
                    handleSUBIDChange(selectedOption);
                  }}
                  classNamePrefix="react-select"
                />

              </div>
            </div>
            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: "996"
              }}
            >
              <div className="dropdown-label">Tags</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
                onClick={() => { setOpenTagSelect(!openTagSelect) }}
                ref={openTagSelectRef}
              >
                <Reactselect
                  isMulti
                  menuIsOpen={openTagSelect}
                  openMenuOnFocus={true}
                  isClearable
                  id="demo-select-small"
                  name="Choose Tags"
                  placeholder="Choose Tags"
                  styles={reactSelectCustomStyles}
                  value={selectedTagOptions}
                  options={PTUTriageTagsReducer
                    ?.filter((item) => item["ID"] !== "-1")
                    .map((item) => ({
                      value: item["ID"],
                      label: item["Name"],
                    }))}
                  onChange={handleTagChange}
                  classNamePrefix="react-select"
                  components={{ ValueContainer: CustomValueContainer }}

                />
              </div>
            </div>


            <div
              className="grid-item"
              style={{
                flexBasis: "2.4%",
                fontSize: "14px",
                marginBottom: "10px",
                zIndex: 0,
              }}
            >
              <div className="dropdown-label"> Notes </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "10px",
                }}
              >
                <input
                  placeholder="Search..."
                  type="text"
                  style={{ width: "17.8vw" }}
                  className="custom-input-filters"
                  value={notes?.value}
                  onChange={(event) => {
                    handleNotesChange(event.target.value);
                    setTimeout(() => {
                      fetchData({
                        property: "Notes",
                        value: event.target.value,
                      });
                    }, 500);
                  }}
                />
              </div>
            </div>
          </div>

          <hr className="horizontal-divider" />

          <div className="heading-container">
            <div
              className="sub-heading"
              style={{ padding: "0px", margin: "0px" }}
            >
              PTUs Available
            </div>
            <div style={{ marginLeft: "auto" }}>
              {showPTUAvailable ? (
                <div
                  className="image-container"
                  style={{ paddingRight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    setShowPTUAvailable(false);
                  }}
                >
                  <img src={arrowUp} alt="Filter Icon" />
                </div>
              ) : (
                <div
                  className="image-container"
                  style={{ paddingRight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    setShowPTUAvailable(true);
                  }}
                >
                  <img src={arrowDown} alt="Filter Icon" />
                </div>
              )}
            </div>
          </div>

          <div
            className="ptu-available-container"
            style={{ padding: "10px", display: showPTUAvailable ? "flex" : "none", flexDirection: "column", justifyContent: "space-evenly" }}
          >
            <PTUTable
              regionData={regionData}
            />
          </div>

          <hr className="horizontal-divider" />

          <div className="feature-container">
            {
              userRole !== 'Basic' && (
                <>
                  <div className="feature-buttons">

                    <button
                      className="tooltip-button"
                      onClick={openAddModal}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={addImg}
                          alt="Filter Icon"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "7px",
                          }}
                        />
                        Add Record
                      </span>
                      <span className="tooltip-text">Add Record</span>
                    </button>
                  </div>
                  <div className="button-separator"></div>
                </>
              )
            }


            <div className="feature-buttons">
              <button
                className="tooltip-button"
                onClick={openMailModal}
                disabled={!selectedIndex || selectedIndex.length === 0}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={mailImg}
                    alt="Filter Icon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "7px",
                    }}
                  />
                  Send Mail
                </span>
                <span className="tooltip-text">Send Mail</span>
              </button>
            </div>

            <div className="button-separator"></div>

            <div className="feature-buttons">
              <button
                disabled={copyLinkText !== "Copy Link" ? true : false}
                className="tooltip-button"
                onClick={handleLinkClick}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={linkImg}
                    alt="Filter Icon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "7px",
                    }}
                  />
                  {copyLinkText}
                </span>
                <span className="tooltip-text">{copyLinkText}</span>
              </button>
            </div>

            <div className="button-separator"></div>

            <div onClick={triggerExport} className="feature-buttons">
              <button
                className="tooltip-button"
                disabled={!selectedIndex || selectedIndex.length === 0}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={exportImg}
                    alt="Filter Icon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "7px",
                    }}
                  />
                  Export Excel
                </span>
                <span className="tooltip-text">Export Excel</span>
              </button>
            </div>

            {userRole === "Contributor" && (
              <>
                <div className="button-separator"></div>

                <div onClick={openBulkUpdateModal} className="feature-buttons">
                  <button
                    className="tooltip-button"
                    disabled={!selectedIndex || selectedIndex.length === 0}
                  >
                    {" "}
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={bulkUpdateImg}
                        alt="Filter Icon"
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "7px",
                        }}
                      />
                      Bulk Update
                    </span>
                    <span className="tooltip-text">Bulk Update</span>
                  </button>
                </div>
                <div className="button-separator"></div>

                <div onClick={openTagsUpdateModal} className="feature-buttons">
                  <button
                    className="tooltip-button"
                    disabled={!selectedIndex || selectedIndex.length === 0}
                  >
                    {" "}
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={tags}
                        alt="Filter Icon"
                        style={{
                          height: "17px",
                          width: "19px",
                          marginRight: "7px",
                        }}
                      />
                      Update Tags
                    </span>
                    <span className="tooltip-text">Update Tags</span>
                  </button>
                </div>
                <div className="select-container">
                  <select ref={autoManualRef} style={{ width: "150px" }} onChange={(e) => handleAutoConfigure(e)}>
                    <option value="Manual" selected>
                      Manual
                    </option>
                    <option value="auto">
                      Auto Allocation
                    </option>
                  </select>
                </div>
                <div className="button-container">
                  <button onClick={handleCapacityChange}>
                    Start Capacity Allocation
                  </button>
                </div>

                <div className="button-container">
                  <button
                    onClick={openApproval}
                    disabled={
                      !selectedIndex ||
                      selectedIndex?.length === 0 ||
                      !capacityAllocation ||
                      isReadyForUpdate
                    }
                  >
                    Ready to Approve
                  </button>
                </div>
              </>
            )}
          </div>

          <div
            style={{
              overflowY: "hidden",
              overflowX: "auto",
              width: "98%",
              paddingLeft: "8px",
              paddingRight: "8px",
              userSelect: isCtrlActive ? 'text' : 'none'
            }}
          >
            <TableComponent
              rowErrors={rowErrors}
              errorLabel={errorLabel}
              checkLimit={checkLimit}
              handleBlur={handleBlur}
              capacityAllocation={capacityAllocation}
              handletableChange={handletableChange}
              handleSelectedIndex={handleSelectedIndex}
              selectedIndex={selectedIndex}
              handleTableDataChange={handleTableDataChange}
              tableData={tableData}
              regionData={regionData}
              setIsRaedyForUpdate={setIsRaedyForUpdate}
              tableLoader={tableLoader || isTagsLoading}
              showToast={showToast}
              selectAll={selectAll}
              setSelectAllChange={setSelectAllChange}
              regionSums={regionSums}
              joinDataWithRegionSums={joinDataWithRegionSums}
              PTUTagsReducer={PTUTriageTagsReducer}
            ></TableComponent>
          </div>

          {showBackDrop && <div className="modal-backdrop"></div>}
          {openApprovalModal ? (
            <ApproveRequest
              closeConfirmationModal={closeConfirmationModal}
              data={selectedData}
              selectedIndex={selectedIndex}
            ></ApproveRequest>
          ) : (
            ""
          )}
          {openAutoConfigureModal &&
            <AutoConfigure
              closeConfirmationModal={closeAutoConfigureModal}
              data={tableData}
              selectedIndex={selectedAutoConfigurableIndex}
            />
          }
          {openAddEntryModal ? (
            <Detail
              closeConfirmationModal={closeAddModal}
              data={[]}
              regionData={regionData}
              regionGrantedOptions={regionGrantedOptions}
              regionGrantedStatusOptions={requestGrantedStatusOptions}
              modelOptions={modelNameOptions}
              subscriptionOptions={[]}
              gbbRegionOptions={gbbRegionOptions}
              requestStateOption={requestStateOption}
              handletableInputChange={handletableChange}
              PTUManagedTriageTagsReducer={PTUTriageTagsReducer}
            >
            </Detail>
          ) : (
            ""
          )}
          {openBulkUpdate ? (
            <BulkUpdate
              closeBulkUpdate={closeBulkUpdate}
              data={newTab}
              selectedIndex={selectedIndex}
            ></BulkUpdate>
          ) : (
            ""
          )}
          {openTagUpdate ? (
            <UpdateTags
              closeTagUpdate={closeTagUpdate}
              data={newTab}
              selectedIndex={selectedIndex}
              PTUTagsReducer={PTUTriageTagsReducer}
            ></UpdateTags>
          ) : (
            ""
          )}
          {OpenMailModel ? (
            <MailModal
              closeMailModal={closeMailModal}
              data={tableData}
              selectedIndex={selectedIndex}
            ></MailModal>
          ) : (
            ""
          )}
        </>
      )}

      {loader && (
        <div className="loader-container" style={{ height: "80vh" }}>
          <div className="loader"></div>
        </div>
      )}

      {/* {
                alert &&
                <Alert message={'All Unsaved Changes would be discarded. Do you Want Proceed?'} ></Alert>
            } */}
    </>
  );
}

export default AllSubmissions;
