import React, { useEffect, useRef, useState } from "react";
import "./NavBar.css";
import MicrosoftIcon from "./../../../assets/Microsoft.png";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import baseConfig from "../../../config/config";

export default function NavBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userName = sessionStorage.getItem("userName");
  const emailId = sessionStorage.getItem("Emailid");
  const { instance } = useMsal();
  const initials = userName
    ?.split(" ")
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join("");

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); 


  const handleLogout = async () => {
    try {
      sessionStorage.clear();
      localStorage.clear();
      if(props.checkUrl()){
        props.checkUrl()
      }
      await instance.logout({
        mainWindowRedirectUri: "/login",
      });

    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  return (
    // TODO: Remove bg color for production
    <div className="navbar" style={{ background: baseConfig.apiBaseUrl.includes("staging") ? "#d3e0fd" : "#F0F0F0" }}>
      <img src={MicrosoftIcon} alt="Company Logo" className="logo" />
      <div className="spacer" />
      {location.pathname !== "/errorPage" && (<div className="user-name">{userName}</div>)}
      {location.pathname !== "/errorPage" && (
        <div className="user-info">
          <div className="user-role">
            {/* Content you want to render when pathname is not equal to "/errorPage" */}
          </div>
        </div>
      )}
      {location.pathname !== "/errorPage" && (
        <div className="avatar dropdown-icon" onClick={handleDropdownToggle}>{initials}</div>
      )}
      {isDropdownOpen && location.pathname !== "/errorPage" && (
        <div ref={dropdownRef} className="dropdown-menu">
          <div className="user-info dropdrown-info">
            <div className="avatar dropdown-avatar">{initials}</div>
            <div className="user-name dropdown-username">
              <div>{userName}</div>
              <div className="dropdown-email"> <span>{emailId}</span></div>
            </div>
          </div>
          <div className="menu-item" onClick={handleLogout}>
            Sign out
          </div>
        </div>
      )}
    </div>
  );
}
