import * as React from "react";
import "../allSubmission/allSubmission.css";
import "../capacityRoadMap/capacityRoadMap.css";
import TableComponent from "../../components/tableComponent/roadMapTableComponent/capacityRoadMapTable";
import axios from "axios";
import baseConfig from "../../../config/config";
import AddRegion from "../addRegion/addRegion";
import { headerLabels, ptuHeaderLabels } from "../../../config/tableFormat";
import { useNavigate } from "react-router-dom";
import EditPtu from '../editPtu/editPtu'
import { useDispatch, useSelector } from "react-redux";
import uploadImg from './../../../assets/upload.png';
import { fetchGpt4Data, fetchLiveData, fetchModalTypeData, fetchModelNames, getRegionListforClassic } from '../../../redux/actions/actions';
import UploadExcel from "../uploadExcel/uploadExcel";

function CapacityRoadMap(props) {

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editHeader, setEditHeader] = React.useState('');
  const liveData = useSelector(store => store.liveData.liveData !== undefined ? store.liveData.liveData : []);
  const isLiveDataLoading = useSelector(store => store.liveData.isLiveDataLoading);
  const liveDataError = useSelector(store => store.liveData.liveDataError);
  const isLiveDataLoaded = useSelector(store => store.liveData.isLiveDataLoaded);
  const ptusData = useSelector(store => store.regionDataClassic.regionDataClassic !== undefined ? store.regionDataClassic.regionDataClassic : []);
  const isPtusDataLoaded = useSelector(store => store.regionDataClassic.isPtuTableClassicDataLoaded);
  const ptusDataError = useSelector(store => store.ptusData.ptusDataError);
  const gpt4Data = useSelector(store => store.gpt4Data.gpt4Data !== undefined ? store.gpt4Data.gpt4Data : []);
  const isGpt4DataDataLoading = useSelector(store => store.gpt4Data.isGpt4DataDataLoading);
  const gpt4DataError = useSelector(store => store.gpt4Data.gpt4DataError);
  const isGpt4DataDataLoaded = useSelector(store => store.gpt4Data.isGpt4DataDataLoaded);
  const isModalNamesDataLoading = useSelector(store => store.modalNamesData.isModalNamesDataLoading);
  const isModalNamesDataLoaded = useSelector(store => store.modalNamesData.isModalNamesDataLoaded);
  const modelNamesDataError = useSelector(store => store.modalNamesData.modelNamesDataError);
  const modalTypeData = useSelector(store => store.modalTypeData.modalTypeData !== undefined ? store.modalTypeData.modalTypeData : []);
  const [modifiedModelTypeData, setModifiedModelTypeData] = React.useState([])
  const isModalTypeDataLoading = useSelector(store => store.modalTypeData.isModalTypeDataLoading);
  const modelTypeDataError = useSelector(store => store.modalTypeData.modelTypeDataError);
  const [type, setType] = React.useState('')
  const isModalTypeDataLoaded = useSelector(store => store.modalTypeData.isModalTypeDataLoaded);
  const [openUploadPopup, setOpenUploadPopup] = React.useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleModal = () => {
    props.openPopup()
    getAllLiveData()
    getModalType()
    getAllPTUsData()
    setIsModalOpen(false)
    getAllGptData()
    if (props.isOpen === true) {
      setSelectedData('')
    }
  };
  const handlePTUModal = (type, header) => {
    setEditHeader(header)
    props.openPtu()
    setType(type)
    setIsModalOpen(false)
    if (props.openPTU === true) {
      setSelectedData('')
    }
  };
  const getAllLiveData = async () => {
    setLoader(true);
    if (!isLiveDataLoaded) {
      await dispatch(fetchLiveData());
    }
  };

  React.useEffect(() => {
    if (liveDataError || ptusDataError || gpt4DataError || modelNamesDataError || modelTypeDataError) {
      navigate("/errorPage");
    }
  }, [liveDataError, ptusDataError, gpt4DataError, modelNamesDataError, modelTypeDataError])

  React.useEffect(() => {
    props.setLiveTableData(liveData);
    if (isLiveDataLoading) {
      setLoader(true);
    }
  }, [isLiveDataLoading])


  const getModalType = React.useCallback(async () => {
    setLoader(true);
  
    try {
      if (!isModalNamesDataLoaded) {
        dispatch(fetchModelNames());

      } else {
        const newState = modalTypeData.map(obj => {
          const { Pool, ...rest } = obj; 
          return rest; 
        });

        setModifiedModelTypeData(newState);
      }
      
    } catch (error) {
      
    } finally {
      setLoader(false);
    }
  }, []);
  ;
  const modifyModelTypeData = () => {
    const newData = [];
    const poolStates = {};

    modalTypeData.forEach(obj => {
        const objData = obj.data.map(item => {
            // Remove "Pool" field from the object
            const { Pool, ...newItem } = item;

            // Collect unique pool values and their corresponding regions
            if (Pool) {
                if (!poolStates[Pool]) {
                    poolStates[Pool] = [];
                }
                poolStates[Pool].push(item.Region);
            }

            return newItem;
        });

        newData.push({ modalName: obj.modalName, data: objData });
      });
      setModifiedModelTypeData(newData)
  }

  
  React.useEffect(() => {
    
    modifyModelTypeData()
  }
  , [isModalTypeDataLoaded])
React.useEffect(() => {
  if (isModalNamesDataLoaded) {
    dispatch(fetchModalTypeData());
  }
}, [isModalNamesDataLoading])

React.useEffect(() => {
  props.setModalWiseTableData(modalTypeData);
  setLoader(false);
}, [isModalTypeDataLoading])


const getAllPTUsData = async () => {
  setLoader(true);
  if (!isPtusDataLoaded) {
    await dispatch(getRegionListforClassic());
  }
};




const getAllGptData = async () => {
  setLoader(true);
  if (!isGpt4DataDataLoaded) {
    await dispatch(fetchGpt4Data());
  }
};

React.useEffect(() => {
  props.setGpt35turbo([])
  props.setGpt35turbo16k([])
  props.setGpt4(gpt4Data)
  props.setGpt432k([])
  setLoader(false);
}, [isGpt4DataDataLoading])


React.useEffect(() => {
  setLoader(false);
  getAllLiveData();
  getAllPTUsData();
  getModalType();
  modifyModelTypeData();
}, []);

const getSelectedRow = (data) => {
  setSelectedData(data)
}
const getSelectedRowDelete = (data) => {
  setIsModalOpen(true)
}
const handleConfirmDelete = async () => {
  const postData = { Id: selectedData.Id };
  try {
    const response = await axios.post(
      baseConfig.apiBaseUrl + '/CapacityRoadMap/Delete',
      postData,
      {
        headers: {
          'Content-Type': 'application/json',
          "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
        },
      }
    );
    if (response.status === 200) {
      window.location.reload();
    }


  } catch (error) {
    console.error('API Error:', error);
    if (error.response.status === 401) {
      navigate("/errorPage");
    }
  }

  setIsModalOpen(false);
};
const handleCancelDelete = () => {
  setIsModalOpen(false);
};
const title = (data) => {
  if (data.length === 0) {
    return {
      fontWeight: "500",
      display: 'none'
    }
  } else {
    return { fontWeight: "500" }
  }
};
function rearrangeDataWithBlankFields(liveData) {
  // Separate 'Pool A' and 'Pool B' data from liveData
  const otherData = liveData.filter(obj => !obj.Region.includes('Pool'));
  const poolAData = liveData.filter(obj => obj.Region.includes('Pool A'));
  const poolBData = liveData.filter(obj => obj.Region.includes('Pool B'));

  // Create objects with blank fields 'Pool A:' and 'Pool B:'
  const poolAField = { Region: 'Pool A' };
  const poolBField = { Region: 'Pool B' };

  // Concatenate blank fields with respective data
  const modifiedLiveData = [
    ...otherData,
    poolAField,
    ...poolAData.map(obj => ({ ...obj, Region: obj.Region.replace('Pool A : ', '') })),
    poolBField,
    ...poolBData.map(obj => ({ ...obj, Region: obj.Region.replace('Pool B : ', '') }))
  ];

  return modifiedLiveData;
}
const closeUploadPopUpOpen = () => {
  setOpenUploadPopup(false)
}
return (
  <>
    {(!loader || isLiveDataLoaded || isModalNamesDataLoaded) && (
      <>
        <div style={title(liveData)} >Live Capacity Roadmap <i onClick={() => props.exportToExcel(0)} className="fas fa-file-excel" style={{color: "#216f44", cursor:"pointer"}}></i></div>
        <div className="boxShadow" style={{ display: liveData.length > 0 ? 'block' : 'none' }}>
          <TableComponent
            tableData={rearrangeDataWithBlankFields(liveData)}
            getSelectedRow={getSelectedRow}
            handleModal={handleModal}
            deleteModal={getSelectedRowDelete}
            headerLabels={headerLabels}
            header={'Live'}
          ></TableComponent>
        </div>
        {

          isLiveDataLoaded && (
            <>          
            <div style={title(ptusData)}>PTU Classic  <i onClick={() => props.exportToExcel(1)} className="fas fa-file-excel" style={{color: "#216f44", cursor:"pointer"}}></i>            </div>

              <div className="boxShadow" style={{ display: ptusData.length > 0 ? 'block' : 'none' }}>
                <TableComponent
                  tableData={ptusData}
                  getSelectedRow={getSelectedRow}
                  handleModal={handlePTUModal}
                  headerLabels={ptuHeaderLabels}
                  type="ptu"
                ></TableComponent>
              </div></>)
        }

        {modifiedModelTypeData.map((item, index) => {
          return (
            <div key={index} >
                <div style={{ 
                    fontWeight: "500", 
                    display: item?.data.length > 0 ? 'flex' : 'none', 
                    justifyContent: 'space-between', 
                    alignItems: 'center' 
                }}>
                  <span>
                    {item?.modalName}
                    <i
                      onClick={() => props.exportToExcel(index + 2)}
                      className="fas fa-file-excel"
                      style={{ color: "#216f44", cursor: "pointer", marginLeft: '4px' }}
                    ></i>
                  </span>
                  {props.userRole === "Contributor" && (
                    <div
                      className='tabButton'
                      onClick={() => setOpenUploadPopup(true)}
                      style={{ cursor: 'pointer', marginRight: '20px' }}
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={uploadImg}
                          alt="Filter Icon"
                          style={{ height: '15px', width: '15px', marginRight: '10px' }}
                        />
                        Upload Excel
                      </span>
                    </div>
                  )}
                </div>
              <div className="boxShadow" style={{ display: item?.data.length > 0 ? 'block' : 'none' }}>
                <TableComponent
                  tableData={item?.data || []}
                  getSelectedRow={getSelectedRow}
                  handleModal={handlePTUModal}
                  headerLabels={ptuHeaderLabels}
                  type="ptuGPT"
                  header={item?.modalName}
                ></TableComponent>
              </div>
            </div>
          )
        })}
         <div className="pool-info">
                <div>
                <b>Note:</b>  The above PTU-M availability is being refreshed once a day. For more accurate real time results, please refer to the link below: 
                </div>
                <div>
                  <a href="https://aka.ms/AOAISolutionSizing"  target="_blank" style={{color: "rgb(0, 90, 158)"}}>https://aka.ms/AOAISolutionSizing</a>
                </div>
              </div>

        {props.isOpen && (
          <div
            className="modal-backdrop"
            onClick={handleModal}
          ></div>
        )}
        {props.openPTU && (
          <div
            className="modal-backdrop"
            onClick={handlePTUModal}
          ></div>
        )}
        {props.isOpen ? (
          <AddRegion
            handleModal={handleModal}
            data={selectedData}
            table={modifiedModelTypeData.slice(-1)[0]?.data}
            selectedIndex={selectedIndex}
          ></AddRegion>
        ) : (
          ""
        )}

        {props.openPTU ? (
          <EditPtu
            handleModal={handlePTUModal}
            data={selectedData}
            selectedIndex={selectedIndex}
            type={type}
            header={editHeader}
          ></EditPtu>
        ) : (
          ""
        )}
        {isModalOpen ? (
          <div className="modal-container" style={{ width: '45vh' }}>
            <div style={{ maxHeight: "30vh" }}>
              <p>Are you sure you want to delete this record?</p>
              <div className="buttons-container marginBottom">
                <button
                  className="button button-go-back"
                  onClick={handleCancelDelete} >
                  Cancel
                </button>
                <button
                  className="button button-confirm"
                  onClick={handleConfirmDelete} >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    )}

    {(loader || isLiveDataLoading || isModalTypeDataLoading) && (
      <div className="loader-container" style={{ height: "80vh" }}>
        <div className="loader"></div>
      </div>
    )}

{
                        openUploadPopup &&
                        <>
                            <UploadExcel closeUploadPopUpOpen={closeUploadPopUpOpen} isPtuAvailaibleCapacity={true}></UploadExcel>
                            <div className="modal-backdrop"></div>
                        </>
                    }
  </>
);
}



export default CapacityRoadMap;