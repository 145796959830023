const baseConfig = {
    ...process.env, ...window['env']
}

export default baseConfig;

// const baseConfig = {
//     apiBaseUrl: 'https://stagingapi.azurecapacityroadmap.com/api',
//     clientId: '236bf59f-88b0-4a71-b9ed-0c70af2931de',
//     authority: 'https://login.microsoftonline.com/common'
// }
// export default baseConfig;