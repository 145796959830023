import React from 'react';
import { headerLabels, tableFormat } from '../../../config/tableFormat';
import moment from 'moment';

const ApprovalTable = (props) => {
    const [loader, setLoader] = React.useState(false)
    const excludeHeaders = ['Deprovision Date', 'Request Grant Status', 'Notes', 'Purchase Date', 'Churn Reason', 'Churn Date'];

// Filtered headers and keys
const filteredHeaderLabels = tableFormat.headerLabels.filter(label => !excludeHeaders.includes(label));

    const tableFormatLocal = props.isMail ? {headerLabels: filteredHeaderLabels, keys: filteredHeaderLabels} : tableFormat


    return (
        <>
            <table style={{ borderCollapse: 'collapse' }} className='auto-width'>
                <thead >
                    <tr>
                        {tableFormatLocal.headerLabels.map((label, index) => (
                            <th key={index} style={headerStyle}>
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.data?.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            style={{
                                backgroundColor: '#f6f6f6',
                            }}
                        >
                            {tableFormatLocal.keys.map((label) => (
                                <td key={rowIndex} style={bodyStyle}
                                title={
                                    `Tag :${row['Tag']}`}>
                                    {((row[label] && row[label] !== -1 && row[label] !== '-1') ? (label === 'DateApproved' ? moment(row[label]).format('MM/DD/YYYY') : row[label]) : 'NA')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                loader &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }
        </>
    );
};

const headerStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    backgroundColor: '#e9e9e9',
    color: 'black',
    height: '30px',
    padding: '3px'
};

const bodyStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    // padding: '10px'
};


export default ApprovalTable;
