import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import baseConfig from "../../config/config";
// const config = {
//   headers: {
//     "content-type": "application/json",
//     serviceid: window.btoa(
//       sessionStorage.getItem("Emailid") +
//         ";" +
//         sessionStorage.getItem("userName")
//     ),
//   },
// };
// Thunk action creator for fetching regions
export const fetchTableData = createAsyncThunk("PTU/tableData", async () => {
  try {
    const res = await axios.get(
      `${baseConfig.apiBaseUrl}/HelpRequest/List?Source=PTUManagedTriage`,
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data };
  } catch (error) {
    throw error;
  }
});

// Thunk action creator for fetching regions
export const fetchRegions = createAsyncThunk("PTU/regionsData", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl + `/CapacityRoadMap/ModelWiseAvailablePTU`,
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data: data };
  } catch (error) {
    throw error;
  }
});

export const getManagedUsers = createAsyncThunk("getManagedUsers", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl + `/HelpRequest/Users?Type=ptuManagedTriage`,
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data: data };
  } catch (error) {
    throw error;
  }
});

// Thunk action creator for fetching distinct data
export const distinctData = createAsyncThunk(
  "getDistinctData",
  async (columnName) => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/Utility/Filter?columnName=${columnName}`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { columnName: columnName, data: data };
    } catch (error) {
      throw error;
    }
  }
);

export const getDistinctDataThroughSingleApi = createAsyncThunk(
  "getDistinctDataThroughSingleApi",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/Utility/References`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);
export const getPTUManagedTriageTags = createAsyncThunk(
  "getPTUManagedTriageTags",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/Tag/List?Source=PTUManagedTriage`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);

export const getPTUTriageTags = createAsyncThunk(
  "getPTUTriageTags",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/Tag/List?Source=PTUTriage`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);

// Thunk action creator for fetching distinct data
export const getRegionListforClassic = createAsyncThunk(
  "getRegionDataForClassic",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/CapacityRoadMap/AvailablePTU`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );

      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);

// Thunk action creator for fetching distinct data
export const getAllClassicTableData = createAsyncThunk(
  "getAllClassicTableData",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/HelpRequest/List?Source=PTUTriage`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);

export const getClassicPtuUsers = createAsyncThunk(
  "classicPtuUsers",
  async () => {
    try {
      const res = await axios(
        baseConfig.apiBaseUrl + `/HelpRequest/Users?Type=ptuTriage`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      return { data: data };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLiveData = createAsyncThunk("fetchLiveData", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl + "/CapacityRoadMap/Live",
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data };
  } catch (error) {
    throw error;
  }
});

//it is same call as above getRegionDataForClassic
export const fetchPtusData = createAsyncThunk("fetchPtusData", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl + "/CapacityRoadMap/AvailablePTUForClassic",
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data };
  } catch (error) {
    throw error;
  }
});

export const fetchGpt4Data = createAsyncThunk("fetchGpt4Data", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl +
        "/CapacityRoadMap/AvailablePTUModelWise?ModelID=3",
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
    );
    const data = res.data;
    return { data };
  } catch (error) {
    throw error;
  }
});

export const fetchModelNames = createAsyncThunk("fetchModelNames", async () => {
  try {
    const res = await axios(
      baseConfig.apiBaseUrl + "/Utility/Filter?columnName=ModelType",
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;
    return { data };
  } catch (error) {
    throw error;
  }
});

// export const fetchModalTypeData = createAsyncThunk(
//   "fetchModalTypeData",
//   async (item) => {
//     try {
//       let urlData = "";
//       item.Key === "All"
//         ? (urlData =
//             "/CapacityRoadMap/AvailablePTUModelTypeAgreegateWise?ModelType=")
//         : (urlData = "/CapacityRoadMap/AvailablePTUModelTypeWise?ModelType=");

//       const res = await axios.get(
//         baseConfig.apiBaseUrl + `${urlData + item.Key}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
//           },
//         }
//       );
//       const data = res.data;
//       return { modalName: item.Value, data: data };
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const fetchModalTypeData = createAsyncThunk(
  "fetchModalTypeData",
  async (item) => {
    try {
      const res = await axios.get(
        baseConfig.apiBaseUrl + `/CapacityRoadMap/AllAvailablePTUModelTypeWise`,
        {
          headers: {
            "Content-Type": "application/json",
            "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
          },
        }
      );
      const data = res.data;
      const transformedData = Object.keys(data).map(key => {
        const modalName = key === "Aggregate" ? "Aggregate table for PTU-Managed" : key;
        return {
          modalName,
          data: data[key]
        };
      });
      return { data: transformedData };
    } catch (error) {
      throw error;
    }
  }
);

// Thunk action creator for user role 
export const getUserRole = createAsyncThunk("User/role", async () => {
  try {
    const res = await axios.post(
      baseConfig.apiBaseUrl + `/Utility/Role`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          "serviceid":window.btoa(sessionStorage.getItem("Emailid") + ';' + sessionStorage.getItem("userName"))
        },
      }
    );
    const data = res.data;  
    //Contributor
    // Basic
    return {role:data}
  } catch (error) {
    throw error;
  }
});