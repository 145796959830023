import React, { useEffect, useState } from 'react';
import '../tableComponent/tablecomponant.css'
import { useSelector } from 'react-redux';

const AddComponent = (props) => {
  const [loader, setLoader] = useState(false)
  const [PTUBudgetedToSell, setPTUBudgetedToSell] = useState(0);
  const [QuotaPTU, setQuotaPTU] = useState(0);
  const [CommitmentPTU, setCommitmentPTU] = useState(0);
  const [PTUSold, setPTUSold] = useState(0);
  const [PTUApprovedForAllocation, setPTUApprovedForAllocation] = useState(0);
  const [SellablePTU, setSellablePTU] = useState(0);
  const [AvailablePTU, setAvailablePTU] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [keys, setKeys] = useState([]);
  const [liveTotals, setLiveTotals] = useState([])
  const userRole = useSelector(store => store.userRoleData.role);
  const modalTypeData = useSelector(store => store.modalTypeData.modalTypeData !== undefined ? store.modalTypeData.modalTypeData : []);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  let sortedData = [...props.tableData];

  if (sortColumn) {
    sortedData = sortedData.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

      const isDate = (value) => !isNaN(Date.parse(value));

      if (isNumeric(valueA) && isNumeric(valueB)) {
        const numericA = parseFloat(valueA);
        const numericB = parseFloat(valueB);

        return sortOrder === 'asc' ? numericA - numericB : numericB - numericA;
      } else if (isDate(valueA) && isDate(valueB)) {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);

        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      } else {
        return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }
    })
  }

  const handleRowSelect = (data) => {
    props.getSelectedRow({ data, edit: true })
    let dataType = ''
    if (props.type === "ptu") {
      dataType = 'ptu'
    } else {
      dataType = "ptuGPT"
    }
    props.handleModal(dataType, props.header)
  };

  function convertDateToMonthString(dateString) {
    const newDate = dateString.split(' ')
    const [month, day, year] = newDate[0].split('/');
    const date = `${month < 10 ? 0 + month : month}/${day < 10 ? 0 + day : day}/${year}`;
    return date;
  }

  function convertUSFormate(num) {
    const numericRegex = /^[0-9]+$/;
    if (numericRegex.test(num)) {
      const number = parseInt(num).toLocaleString("en-US")
      return number;
    } else {
      return num
    }
  }
  const totals = {}
  const calculateTotals = () => {
    props.tableData.forEach(item => {
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'number') {
          totals[key] = (totals[key] || 0) + item[key];
        }
      });
    });
  };
  const formatLabels = (oldLabel) => {
    const exceptions = ['PTU', 'GBB', 'PTUs', 'GPT', 'ID'];
    let words = oldLabel.split(/(PTUs|PTU|GBB|GPT|ID)|(?=[A-Z])|\s+/).filter(Boolean);
    words = words.map(word => {
      if (!exceptions.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word;
      }
    });
    return words.join(' ');
  }
  useEffect(() => {
    if (props.tableData.length !== 0) {
      setKeys(Object.keys(props.tableData[0]))
    }

    if (props.header === 'Live') {
      const data = props.tableData
      const columnNames = Object.keys(data[0]);

      const keysToExclude = ["Region", "ID", "RegionID", "GBB Region"];

      const updateState = () => {
        const newState = { total: [] };

        newState.total = Array(columnNames.length).fill(0);

        data.forEach(obj => {
          Object.keys(obj).forEach((key, index) => {
            if (keysToExclude.includes(key)) {
              if (keysToExclude.includes(key)) {
                newState.total[index] = " ";
              }
            } else {
              const numericValue = parseInt(obj[key]);
              if (!isNaN(numericValue)) {
                newState.total[index] += numericValue;
              }
            }
          });
        });

        newState.total.splice(1, 1)
        if(userRole === "Basic"){
          newState.total.splice(1, 1)

        }
        newState.total[0] = "Total"
        return newState.total;
      };
      setLiveTotals(updateState());
    }

    calculateTotals();

    // for  PTU
    const total9 = props.tableData.reduce((acc, item) => acc + parseInt(item.PTUBudgetedToSell !== "" ? item.PTUBudgetedToSell : 0), 0);

    setPTUBudgetedToSell(total9);
    const total10 = props.tableData.reduce((acc, item) => acc + parseInt(item.QuotaPTU !== "" ? item.QuotaPTU : 0), 0);

    setQuotaPTU(total10);
    const total11 = props.tableData.reduce((acc, item) => acc + parseInt(item.CommitmentPTU !== "" ? item.CommitmentPTU : 0), 0);

    setCommitmentPTU(total11);
    const total12 = props.tableData.reduce((acc, item) => (!item.Pool && !item.Region.includes("Pool") ? acc + (parseInt(item.PTUSold !== "" ? item.PTUSold : 0)) : acc), 0);
    setPTUSold(total12);
    const total13 = props.tableData.reduce((acc, item) => (!item.Pool && !item.Region.includes("Pool") ? acc + (parseInt(item.PTUApprovedForAllocation !== "" ? item.PTUApprovedForAllocation : 0)) : acc), 0);

    setPTUApprovedForAllocation(total13);
    const total14 = props.tableData.reduce((acc, item) => acc + parseInt(item.SellablePTU !== "" ? item.SellablePTU : 0), 0);

    setSellablePTU(total14);
    const total15 = props.tableData.reduce((acc, item) => acc + parseInt(item.AvailablePTU !== "" ? item.AvailablePTU : 0), 0);

    setAvailablePTU(total15);


  }, [props.tableData]);
  return (
    <>
      <div className="table-container">
        <table style={{ borderSpacing: '0px', width: '100%', overflow: 'scroll' }}>
          <thead style={{ backgroundColor: 'rgb(233, 233, 233)', position: 'sticky', top: 0 }}>
            <tr>

              <th style={{ headerStyle, display: (props.header === "Live" || props.type==="ptu")  && userRole === "Contributor" ? '' : 'none' }} />
              {keys.map((key) => {
                if (key !== 'ID' && key !== 'RegionID' && key !== 'ColorID' && ((props.header === "PTU Capacity Available to ALLOCATE to Customers" && key !== "GBBRegion") || props.header !== "PTU Capacity Available to ALLOCATE to Customers")) {
                  return (
                    <th
                      key={key}
                      style={{ ...headerStyle, textAlign: key === 'Region' ? 'left' : 'center', cursor: props.header !== "Live" ? 'pointer' : 'auto' }}
                      onClick={props.header !== "Live" ? () => handleSort(key) : undefined}
                      className={key === sortColumn ? (sortOrder === 'asc' ? 'sort-asc' : 'sort-desc') : ''}
                    >
                      {key !== 'ID' && key !== 'RegionID' ? key === "GBBRegion ID" ? formatLabels(key).slice(0, -3) : formatLabels(key) : ''}
                      {key === sortColumn && (
                        <i className={`fa fa-caret-${sortOrder === 'asc' ? 'up' : 'down'} sort-icon`} />
                      )}
                    </th>
                  )
                }
              }

              )}
            </tr>

          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => {

              if (row['Region'] === "Pool A" || row['Region'] === "Pool B" || row['GBBRegion'] === " Total") {
                return (
                  <tr key={rowIndex}
                    style={{ backgroundColor: row['GBBRegion'] === ' Total' ? '#f6f6f6' : rowIndex % 2 === 1 ? '#F0F0F0' : 'white' }}
                  >
                    <td style={{ alignItems: 'center', justifyContent: 'center', display: (props.header === "Live" || props.type==="ptu") && userRole !== "Basic" ? '' : 'none' }}>
                      <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        <i onClick={() => handleRowSelect(row)} className="fa fa-edit icon" style={{ color: '#4B81FF', marginRight: '10px', display: row['Region'].includes("Pool") ? 'none' : '' }}></i>
                      </div>
                    </td>
                    {keys.map((key) => {
                      if (key !== 'ID' && key !== 'RegionID' && key !== 'ColorID' && ((props.header === modalTypeData.slice(-1)[0]?.modalName && key !== "GBBRegion") || props.header !== "PTU Capacity Available to ALLOCATE to Customers")) {
                        return (
                          <td
                            key={key}
                            style={{
                              ...bodyStyle, textAlign: key !== 'Region' ? 'center' : '', fontWeight: 'bold', 
                              // backgroundColor : parseInt(convertUSFormate(row[key !== 'ID' && key !== 'RegionID' ? key : ''])) < 0 && props.header === "PTU Capacity Available to ALLOCATE to Customers" ? '#FFC7CE' : ''
                            }}


                          >
                            {(key !== "SellableETAmonth" && key !== "GBBRegion" && key !== "GBB Region") ?
                              row[key !== 'ID' && key !== 'RegionID' ? key : ''] !== '' ? convertUSFormate(row[key !== 'ID' && key !== 'RegionID' ? key : '']) : <span style={{}}>0</span>
                              :
                              key === "GBBRegion" || key === "GBB Region" ?

                                (<span>{row[key] === "" ? "-" : row[key]}</span>) :
                                row[key !== 'ID' && key !== 'RegionID' ? key : ''] !== '' ? convertDateToMonthString(row[key !== 'ID' && key !== 'RegionID' ? key : '']) : <span>Live</span>

                            }
                          </td>
                        )
                      }
                    })}
                  </tr>
                )
              }
              return (
                <tr
                  style={{ backgroundColor: props.header ===modalTypeData.slice(-1)[0]?.modalName
                  ? row['ColorID'] === "-1" ? '#D9D9D9' : 'white'
                  : rowIndex % 2 === 1 ? '#F0F0F0' : 'white'}}
                  // TODO: color code - red, green, white
                >
                    <td style={{ alignItems: 'center', justifyContent: 'center', display: (props.header === "Live" || props.type==="ptu") && userRole !== "Basic" ? '' : 'none' }}>
                    <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                      <i onClick={() => handleRowSelect(row)} className="fa fa-edit icon" style={{ color: '#4B81FF', marginRight: '10px' }}></i>
                    </div>
                  </td>
                  {keys.map((key) => {
                    if (key !== 'ID' && key !== 'RegionID' && key !== 'ColorID' && ((props.header === "PTU Capacity Available to ALLOCATE to Customers" && key !== "GBBRegion") || props.header !== "PTU Capacity Available to ALLOCATE to Customers")) {
                      return (
                        <td
                          key={key}
                          style={{
                            ...bodyStyle, textAlign: key !== 'Region' ? 'center' : '', 
                            // backgroundColor : parseInt(convertUSFormate(row[key !== 'ID' && key !== 'RegionID' ? key : ''])) < 0 && props.header === "PTU Capacity Available to ALLOCATE to Customers" ? '#FFC7CE' : '', 
                            fontWeight: props.header === "PTU Capacity Available to ALLOCATE to Customers"
                            ? row['ColorID'] === "-1" ? 'bold' : ''
                            : rowIndex % 2 === 1 ? '' : ''
                          }}


                        >

                          {(key !== "SellableETAmonth" && key !== "GBBRegion" && key !== "GBB Region") ?
                            row[key !== 'ID' && key !== 'RegionID' ? key : ''] !== '' ? convertUSFormate(row[key !== 'ID' && key !== 'RegionID' ? key : '']) : <span style={{}}>0</span>
                            :
                            key === "GBBRegion" || key === "GBB Region" ?

                              (<span>{row[key] === "" ? "-" : row[key]}</span>) :
                              row[key !== 'ID' && key !== 'RegionID' ? key : ''] !== '' ? convertDateToMonthString(row[key !== 'ID' && key !== 'RegionID' ? key : '']) : <span>Live</span>

                          }
                        </td>
                      )
                    }
                  })}



                </tr>
              )
            })}
            {
            props.header === modalTypeData.slice(-1)[0]?.modalName ? null : // Show nothing if header is "PTU Capacity Available to ALLOCATE to Customers"
            props.type === "ptu" || props.type === "ptuGPT" ? (
              <tr style={{ backgroundColor: '#f6f6f6', borderTop: '1px solid black' }}>
                <td style={{ display:props.type==="ptu" ? '' : 'none' }}></td>
                <td style={{ ...bodyStyle, fontWeight: 'bold' }}>Total</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: PTUBudgetedToSell || PTUBudgetedToSell === 0 ? '' : 'none' }}>{PTUBudgetedToSell.toLocaleString("en-US") === "" ? "0" : PTUBudgetedToSell.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: QuotaPTU || QuotaPTU === 0 ? '' : 'none' }}>{QuotaPTU.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: CommitmentPTU || CommitmentPTU === 0 ? '' : 'none' }}>{CommitmentPTU.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: PTUSold || PTUSold === 0 ? '' : 'none' }}>{PTUSold.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: PTUApprovedForAllocation || PTUApprovedForAllocation === 0 ? '' : 'none' }}>{PTUApprovedForAllocation.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: SellablePTU || SellablePTU === 0 ? '' : 'none' }}>{SellablePTU.toLocaleString("en-US")}</td>
                <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center', display: AvailablePTU || AvailablePTU === 0 ? '' : 'none' }}>{AvailablePTU.toLocaleString("en-US")} </td>
                {!props.header?.includes("Aggregate") && <td></td> }
              </tr>
            ) : (
              <tr style={{ backgroundColor: '#f6f6f6', borderTop: '1px solid black' }}>
                {liveTotals?.map(item => <td style={{ ...bodyStyle, fontWeight: 'bold', textAlign: 'center' }}>{item.toLocaleString("en-US")}</td>)}
              </tr>
            )
          }
          </tbody>
        </table>
        {loader &&
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
      </div>
    </>


  );
};

const headerStyle = {
  fontFamily: '"Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'left',
  backgroundColor: '#e9e9e9',
  height: '30px',
  padding: '10px',
  position: 'sticky',
  top: 0,
  color: '#000000',
  maxWidth: '200px',
};

const bodyStyle = {
  fontFamily: 'Segoe UI',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0px',
  textAlign: 'left',
  height: '32px',
  padding: '10px'
};


export default AddComponent;
