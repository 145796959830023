import React from 'react';

function Alert(props) {
    const isDeleting = props.message.includes("delete") ? true : false

    return (
        <div className="modal-container" style={{ width: '50vh' }}>
            <div>
                <div className="title" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <b>Alert</b>
                </div>

                <hr className="divider" />

                <div className="text" style={{ fontSize: '13.5px' }}>
                    {props.message}
                </div>


                <hr className="divider" />

                <div className="buttons-container" style={{ marginBottom: '0px' }}>
                    <div className="button-container" style={{ marginRight: '10px' }} onClick={props.onclickSubmit}>
                        <button style={{backgroundColor: '#D13438'}} disabled={isDeleting ? props.deleting : false}>{props.SubmitbuttonText}</button>
                    </div>
                    <button
                        className="button button-go-back"
                        onClick={props.onClickCancel}
                    >
                        Cancel
                    </button>


                </div>
            </div>
        </div>
    );
}

export default Alert;