import React, { useCallback, useEffect } from 'react';
import './bulkUpdate.css'; // Import your CSS file
import { tableFormat } from '../../../config/tableFormat';
import axios from 'axios';
import baseConfig from '../../../config/config';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { components } from 'react-select';
import Draggable from '../../components/tableComponent/tableComponent/Draggable';


const BulkUpdate = (props) => {
    const [selectedArray, setSelectedArray] = React.useState(props.data?.filter((item, index) => props.selectedIndex?.includes(item.RequestID))?.map((item) => ({
        RequestID: item.RequestID,
        RequestGrantStatusID: item.RequestGrantStatusID,
        DateApproved: item.DateApproved,
        DRI_Alias: item.DRI_Alias,
        AssignedToAlias: item.AssignedToAlias,
        Notes: item.Notes,
        ProvisionDate: item.ProvisionDate,
        ChurnDate: item.ChurnDate,
        PurchaseDate: item.PurchaseDate,
        ChurnReason: item.ChurnReason,
        DeprovisionDate: item.DeprovisionDate,
        Tag: item.Tag
    })));
    const [propertySelected, setPropertySelected] = React.useState("")
    const [updateList, setUpdateList] = React.useState([]);
    const [readyList, setReadyList] = React.useState([]);
    const [updating, setUpdating] = React.useState(false);
    const [updationFailed, setUpdationFailed] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false)
    const requestGrantedOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestGrantStatus !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestGrantStatus : null);
    const requestStateOptions = useSelector(store => store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestState !== undefined ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.RequestState : null);

    const PTUManagedTriageTagsReducer = useSelector((store) =>
        store.PTUManagedTriageTags.PTUManagedTriageTagsState !== undefined
            ? store.PTUManagedTriageTags.PTUManagedTriageTagsState
            : null
    );
    const [selectedTagsOptions, setSelectedTagsOptions] = React.useState([]);
    const customStyles = {
        container: (provided) => ({
            ...provided,

            width: "100%",

        }),
        control: (provided) => ({
            ...provided,
            height: '100%',
            width: "100%",
            cursor: 'pointer',
            border: 'none',
            boxShadow: 'none'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
            color: state.isSelected ? 'black' : provided.color,
            cursor: 'pointer',

        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
                display: "none"
            },
            // whiteSpace: 'nowrap',
            flexWrap: "unset",
            fontFamily:
                'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
            fontSize: "5px",
            fontWeight: "600",
            height: "32px",
            padding: "0px 8px 0px 8px",
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            color: 'black'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'black'
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'black',
            ':hover': {
                backgroundColor: 'lightgray',
                color: 'black'
            }
        })
    };
    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                        style={{ marginRight: '8px' }}
                    />
                    <label style={{ margin: 0 }}>{props.label}</label>
                </div>
            </components.Option>
        );
    };

    const handleTagChange = (selectedTagsOptions) => {
        const selectedLabels = selectedTagsOptions.map(option => option.label);
        setSelectedTagsOptions(selectedTagsOptions);
        handleInputChange('Tag', selectedLabels.join(','), selectedLabels);
    }
    const navigate = useNavigate();
    const handleInputChange = (key, value, property) => {
        
        setUpdateList({ key, value, property })
    }

    const handleAddToUpdateList = () => {
        if (updateList && updateList.property) {
            let tempList = readyList
            setUpdateList(null);

            const keyExists = tempList.findIndex(item => item.key === updateList.key);
            if (keyExists === -1) {
                tempList.push(updateList)
            }
            else {
                tempList[keyExists] = updateList;
            }

            setReadyList(tempList);
        }
        setPropertySelected("")
    }

    const update = async () => {
        setUpdating(true)
        let updatedJsonDataArray = selectedArray;
        for (let i = 0; i < readyList.length; i++) {
            const list = readyList[i];
            updatedJsonDataArray = updatedJsonDataArray.map((item) => {
                if (list.key === 'RequestGrantStatus') {
                    return {
                        ...item,
                        RequestGrantStatusID: list.value,
                    };
                }else if(list.key === 'RequestState'){
                    return {
                        ...item,
                        RequestState: parseInt(list.value),
                    };

                } else if(list.key === 'DeprovisionDate'){
                    return {
                        ...item,
                        [list.key]: formatDate(list.value),
                    };

                } 
                else {
                    return {
                        ...item,
                        
                        [list.key]: list.key === 'DateApproved' || list.key === 'ProvisionDate' || list.key === 'PurchaseDate' || list.key === 'ChurnDate'? formatDate(list.value) :  list.value,
                    };
                }
            });
        }
      
        const postData = updatedJsonDataArray;
        try {
            const response = await axios.post(
                baseConfig.apiBaseUrl + '/HelpRequest/BulkUpdate',
                postData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                    },
                }
            );
            if (response.status === 200) {
                setUpdating(false)
                setUpdateSuccess(true)
                setTimeout(() => {
                    setUpdateSuccess(false)
                    window.location.reload();
                }, 3000);
            }
        } catch (error) {
            console.error('API Error:', error);
            setUpdating(false)
            setUpdationFailed(true)
            setTimeout(() => {
                setUpdationFailed(false)
            }, 3000);
            if (error.response.status === 401) {
                navigate("/errorPage");
            }
        }
    }
    const MultiSelectCheckbox = ({ selectedOptions, options, onChange }) => {
        const formattedOptions = options.map(option => ({
            value: option.ID,
            label: option.Name
        }));
        return (
            <Select
                closeMenuOnSelect={false}
                components={{ Option: CustomOption }}
                // components={{ Option: CustomOption, ValueContainer: CustomValueContainer, Input: CustomInput }}
                isMulti
                options={formattedOptions}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                styles={customStyles}
                onChange={onChange}
                value={selectedOptions}
                inputValueRenderer={"Hi"}
            />
        );
    };

    const setInputField = (propertySelected) => {
        let label = propertySelected;
        if (propertySelected !== "") {
            if (label === 'DateApproved' || label=== "DeprovisionDate"  || label === 'ProvisionDate' || label === 'ChurnDate' || label === 'PurchaseDate') {
                return (
                    <>
                        <div className="input-container">
                            <input
                                type="date"
                                onKeyDown={(event) => event.preventDefault()}
                                className="custom-input-bulk"
                                style={{ width: '34%' }}
                                placeholder={`Enter ${(tableFormat.bulkUpdate.find(item => item.key === label))?.label}`}
                                value={updateList?.value}
                                onChange={(event) => { handleInputChange(label, event.target.value, event.target.value) }}
                            />
                        </div>
                    </>
                )
            }
            else if (label === 'RequestGrantStatus') {
                return (
                    <>
                        <div className="input-container">
                            <select
                                className="select-container-bulk"
                                style={{ width: '34%' }}
                                onChange={(event) => { handleInputChange(label, event.target.value, requestGrantedOptions.find(item => item["Key"] === event.target.value)?.Value) }}
                            >
                                <option value="">Choose {(tableFormat.bulkUpdate.find(item => item.key === label))?.label}</option>
                                {requestGrantedOptions?.map((item) => (
                                    <option value={item["Key"]}>{item["Value"]}</option>
                                ))}
                            </select>
                        </div>

                    </>
                )
            }
            else if (label === 'RequestState') {
                return (
                    <>
                        <div className="input-container">
                            <select
                                className="select-container-bulk"
                                style={{ width: '34%' }}
                                onChange={(event) => { handleInputChange(label, event.target.value, requestStateOptions.find(item => item["Key"] === event.target.value)?.Value) }}
                            >
                                <option value="">Choose {(tableFormat.bulkUpdate.find(item => item.key === label))?.label}</option>
                                {requestStateOptions?.map((item) => (
                                    <option value={item["Key"]}>{item["Value"]}</option>
                                ))}
                            </select>
                        </div>

                    </>
                )
            }
            else if (label === 'Tag') {
                return (
                    <div style={{ width: '35%', border: '1px solid #888888', borderRadius: '5px' }} className="input-container">
                        <MultiSelectCheckbox options={PTUManagedTriageTagsReducer} selectedOptions={selectedTagsOptions} onChange={handleTagChange} />
                    </div>
                )
            }
            else {
                return (
                    <>
                        <div className="input-container">
                            <input
                                type="text"
                                className="custom-input-bulk"
                                style={{ width: '34%' }}
                                placeholder={`Enter ${(tableFormat.bulkUpdate.find(item => item.key === label))?.label}`}
                                defaultValue=''
                                onChange={(event) => { handleInputChange(label, event.target.value, event.target.value) }}
                            />
                        </div>
                    </>
                )
            }
        }
        else {
            return (<></>)
        }
    }

    function isDate(dateString) {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString);
    }

    function formatDate(dateString) {
        const parts = dateString.split('-');
        if (parts.length === 3) {
            const [year, month, day] = parts;
            return `${month}/${day}/${year}`;
        }
        return ''; 
    }

    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
          props.closeBulkUpdate()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, [handleEscapeKeyPress]);
    
    return (
        <>
            <div className="modal-container">
                <div style={{ maxHeight: '100vh' }}>
                    <div className="title">
                        <b>Bulk Update</b>
                        <i className="fa fa-times close-icon"
                            onClick={props.closeBulkUpdate}
                        ></i>
                    </div>

                    <hr className="divider" />

                    <div className="text" style={{ fontSize: '13.5px' }}>
                        Choose the properties you wish to update from the list below and click on the 'Add to Update List' button. You can select multiple properties and add them to the update list. Once you've finished selecting the properties to update, click on the 'Update' button to apply your changes.
                    </div>

                    <div className="custom-modal">
                        <div className="left-panel">
                            <label className="text-container-bulk">Select Property</label>
                            <div className="select-container" style={{ marginTop: '15px' }}>
                                <select value={propertySelected} onChange={(event) => { setPropertySelected(event.target.value) }}>
                                    <option value={""}>Select Property</option>
                                    {tableFormat.bulkUpdate.map((property) => (
                                        <option value={property.key}>{property.label}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="input-container-bulk">
                                <label className="text-container-bulk">{(tableFormat.bulkUpdate.find(item => item.key === propertySelected))?.label}</label>
                                {setInputField(propertySelected)}
                            </div>

                            {propertySelected !== "" &&
                                <div className="container" style={{ paddingTop: '13vh' }}>
                                    <div className="button-container" onClick={(event) => { handleAddToUpdateList(event) }}>
                                        <button >Add to Update list</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="divider-vertical"></div>

                        <div className="right-panel">
                            <label className="text-container-bulk">List to Update</label>
                            <ul className="json-list">
                                {readyList.map((item, index) => (
                                    <li key={index} className="json-item">
                                        <span className="json-key">{(tableFormat.bulkUpdate.find(properties => properties.key === item.key))?.label}</span>
                                        <span className= {item.key === 'Tag' ? "json-value tag-json-value " : "json-value "}>{isDate(item.property) ? formatDate(item.property) :
                                            item.key === 'Tag' ?
                                                    <>
                                                    <Draggable>
                                                    {item.property.length > 0 && item.property?.map((tag, index) =>
                                                            <span key={index} className="Tag" >{tag.trim()}</span>)
                                                        }
                                                    {item.property.length > 0 && item.property?.map((tag, index) =>
                                                            <span key={index} className="Tag" >{tag.trim()}</span>)
                                                        }
                                                    
                                                    </Draggable>
                                                        
                                                        
                                                        {item.property.length == 0 && (
                                                            <span key={index} >NA</span>
                                                        )
                                                        }
                                                    </>
                                                    :
                                                    item.property}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="container">
                        <button
                            className="button button-go-back"
                            onClick={props.closeBulkUpdate}
                        >
                            Cancel
                        </button>
                        <div className="button-container">
                            <button disabled = {updating ? true : false} onClick={() => { update() }}>Bulk Update</button>
                        </div>
                    </div>

                </div>
            </div >
            {updating && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                Updating Records...
            </div>}
            {updateSuccess && (
                <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                   Records Updated successfully.
                </div>
            )}
           
            {updationFailed && <div className="alert">
                Failed To Update Records
            </div>}
        </>
    );
};

export default BulkUpdate;
