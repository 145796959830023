import { configureStore } from '@reduxjs/toolkit';
import { tableDataReducer, ptuTableReducer, regionDataClassicReducer, allClassicCustomersReducer, liveDataReducer, ptusDataReducer, gpt4DataReducer, modelNamesReducer, modalTypeReducer, classicTableDataReducer, allManagedCustomersReducer, distinctDataThroughSingleApiReducer, userRoleReducer, PTUManagedTriageTagsReducer, UnchangedTableDataReducer, UnchangedClassicTableDataReducer, PTUTriageTagsReducer } from './reducers/rootReducer'; 

const store = configureStore({
  reducer: {
    ptutable: ptuTableReducer.reducer,
    distinctDataThroughSingleApi: distinctDataThroughSingleApiReducer.reducer,
    PTUManagedTriageTags: PTUManagedTriageTagsReducer.reducer,
    PTUTriageTags: PTUTriageTagsReducer.reducer,
    regionDataClassic: regionDataClassicReducer.reducer,
    classicTableData: classicTableDataReducer.reducer,
    allClassicCustomers: allClassicCustomersReducer.reducer,
    allManagedCustomers : allManagedCustomersReducer.reducer,
    tableData: tableDataReducer.reducer,
    liveData: liveDataReducer.reducer,
    ptusData: ptusDataReducer.reducer,
    gpt4Data: gpt4DataReducer.reducer,
    modalNamesData: modelNamesReducer.reducer,
    modalTypeData: modalTypeReducer.reducer,
    userRoleData: userRoleReducer.reducer,
    managedTableData: UnchangedTableDataReducer.reducer,
    classicUnchangedTableData : UnchangedClassicTableDataReducer.reducer
  }
});

export default store;

