import React from 'react';
import { tableFormat } from '../../../config/tableFormat';

const DetailTable = (props) => {
    const [loader, setLoader] = React.useState(true)

    return (
        <>
            <table style={{ borderCollapse: "collapse", width: '100%', border: '3px solid #ccc' }}>
                <tbody>
                    {tableFormat.headerLabels.map((label, index) => {
                        if (label !== 'Notes' && label !== 'Tags') {
                            return (
                                <tr key={index}>
                                    <th style={{ ...headerStyle, border: '1px solid #ccc', width:"25%" }}>
                                        {label}
                                    </th>
                                    {props.data?.map((row, rowIndex) => (
                                        <td key={rowIndex} style={{ ...bodyStyle, backgroundColor: '#f6f6f6', border: '1px solid #ccc' }}>
                                            {row[tableFormat.keys[index]] && row[tableFormat.keys[index]] !== -1 && row[tableFormat.keys[index]] !== '-1' ? row[tableFormat.keys[index]] : 'NA'}
                                        </td>
                                    ))}
                                </tr>
                            )
                        }else if (label === 'Tags') {
                            return (
                                <tr key={index} >
                                    <th style={{ ...headerStyle, border: '1px solid #ccc' }}>
                                        {label}
                                    </th>
                                    {props.data?.map((row, rowIndex) => {
                                    const value = row[tableFormat.keys[index]];
                                    const displayValue = value && value !== -1 && value !== '-1' 
                                        ? value.split(',').map((val, i) => <span key={i} className="Tag-details">{val.trim()} </span>)
                                        : 'NA';

                                    return (
                                        <td key={rowIndex} style={{ ...bodyStyle, backgroundColor: '#f6f6f6', border: '1px solid #ccc', lineHeight:"23px" }}>
                                        {displayValue}
                                        </td>
                                    );
                                    })}
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
            {props.notes.length > 0 &&
                <>
                    <div style={{ fontFamily: 'Segoe UI', fontSize: '14px', fontWeight: 600, lineHeight: '20px', letterSpacing: '0px', marginTop: '10px' }}>NOTES</div>
                    {props.notes.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    flexDirection: 'column',
                                    marginTop: '0.6rem',
                                }}>
                                <div style={{ fontFamily: 'Segoe UI', fontSize: '14px', fontWeight: 700, lineHeight: '20px', letterSpacing: '0px', color: '#1f1f1f' }}>{item.User} <span style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 400 }}>{item.Date}</span></div>
                                <div style={{ fontSize: '13px', color: '#181818', marginBottom: '10px' }}>{item.Notes}</div>
                                {index < props.notes.length - 1 && <hr color='#eaeaea' />}
                            </div>
                        )
                    })}
                </>
            }



            {
               props.notesLoader &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }
        </>
    );
};

const headerStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    backgroundColor: '#F6F6F6',
    height: '30px',
    padding: '3px'
};

const bodyStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '30px',
    padding: '10px'
};


export default DetailTable;