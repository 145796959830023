import React, { useEffect, useState } from 'react';
import { headerLabels, tableFormat } from '../../../config/tableFormat';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CorrectIcon from "./../../../assets/correct.png";
import CrossIcon from "./../../../assets/crossAutoAllocation.png";

const AutoAllocateTable = (props) => {
    const [loader, setLoader] = React.useState(false);
    const [errorRecordsRequestIds, setErrorRecordsRequestIds] = React.useState([]);
    const ptuData = useSelector((state) => state.ptutable.ptuData);
    const classicRegionData = useSelector((store) =>
        store.ptusData.ptusData !== undefined ? store.ptusData.ptusData : []
      );
    const excludeHeaders = ['Deprovision Date', 'Request Grant Status', 'Notes', 'Purchase Date', 'Churn Reason', 'Churn Date'];
    const HeadersForAutoConfigurable = ['Request Date', "Customer", "Sub ID", "Region", "Model", "PTUs Requested", 'Request State', "Region Granted", "PTUs Granted" ]
    const KeysForAutoConfigurable = ['GetHelp_RequestDate', 'CustomerName', 'SubscriptionID', 'RegionRequested', 'ModelName', 'PTU_Requested', 'RequestState', 'RegionGranted', 'PTU_Granted'];
    const modelNameOptions = useSelector((store) =>
        store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
          .ModelName !== undefined
          ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
            .ModelName
          : null
      );
      const regionGrantedOptions = useSelector((store) =>
        store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
          .PTUManagedTriageRegionGranted !== undefined
          ? store.distinctDataThroughSingleApi.distinctDataThroughSingleApi
            .PTUManagedTriageRegionGranted
          : null
      );
    const [tableData,setTableData] = useState([]);
    const [errorRecordMapper,setErrorRecordMapper]= useState({});
    // Filtered headers and keys
    const filteredHeaderLabels = tableFormat.headerLabels.filter(label => !excludeHeaders.includes(label));

    const tableFormatLocal = props.isMail ? {headerLabels: filteredHeaderLabels, keys: filteredHeaderLabels} : props.isAutoConfigurable ? {headerLabels : HeadersForAutoConfigurable, keys : KeysForAutoConfigurable} : tableFormat;

    function getKeyForCurrentModel(curentModel) {
        for (const option of modelNameOptions) {
          if (option.Value == curentModel) return parseInt(option.Key);
        }
        return -1;
    }

    const CalculateErrornousRows = () => {
        const AvailblePTUsModelAndRegionWiseManaged = JSON.parse(JSON.stringify(ptuData));
        const AvailblePTUsModelAndRegionWiseClassic = JSON.parse(JSON.stringify(classicRegionData));
        const errorRecordsRequestIdsTemp = [];
        const errorRecordMapperTemp = {}
        props.data.forEach(record => {
            const currentRecordModelId = getKeyForCurrentModel(record.ModelName);
            const currentRecordRegionRequested = record.RegionRequested.split(/[;,]/)[0].trim();
            const isValidRecord = regionGrantedOptions.filter((regionOption)=> regionOption.Value.toLowerCase() === currentRecordRegionRequested.toLowerCase());
            const RegionDataForBothTabs = sessionStorage.getItem("tab") == "2"  ? AvailblePTUsModelAndRegionWiseManaged[currentRecordModelId] : AvailblePTUsModelAndRegionWiseClassic; 
            if(isValidRecord.length == 0){
                errorRecordsRequestIdsTemp.push(record.RequestID);
                errorRecordMapperTemp[record.RequestID] = "The Region you have entered doesn't match with the live table in the Capacity tab.";
            }else if(RegionDataForBothTabs){
                const currentPTURequested = record.PTU_Requested;
                let currentRequestedRegionData = RegionDataForBothTabs.filter((regionData)=> regionData.Region.toLowerCase() === currentRecordRegionRequested.toLowerCase());
                if(currentRequestedRegionData[0].Pool){
                    let tempPoolValue = currentRequestedRegionData[0].Pool;
                    currentRequestedRegionData = RegionDataForBothTabs.filter((regionData)=> regionData.Pool === tempPoolValue);
                }
                if(parseInt(currentRequestedRegionData[0].AvailablePTU) === 0){
                    errorRecordsRequestIdsTemp.push(record.RequestID);
                    errorRecordMapperTemp[record.RequestID] = 'This Region has no Available PTUs.';
                }else if(parseInt(currentRequestedRegionData[0].AvailablePTU) < parseInt(currentPTURequested)){
                    errorRecordsRequestIdsTemp.push(record.RequestID);
                    errorRecordMapperTemp[record.RequestID] = 'The PTU Requested value exceeds the Available PTU.';
                }else{
                    currentRequestedRegionData[0].AvailablePTU = String(parseInt(currentRequestedRegionData[0].AvailablePTU) - parseInt(currentPTURequested));;
                }
            }
        });
        setErrorRecordsRequestIds(errorRecordsRequestIdsTemp);
        const nonErroneousRecords = props.data.filter(record => !errorRecordsRequestIdsTemp.includes(record.RequestID));
        props.setNonErroneousSelectedIndex(nonErroneousRecords);
        const erroneousRecords = props.data.filter(record => errorRecordsRequestIdsTemp.includes(record.RequestID));
        const sortedRecords = [...nonErroneousRecords, ...erroneousRecords];
        setTableData(sortedRecords);
        setErrorRecordMapper(errorRecordMapperTemp);
    }
    
    useEffect(()=>{
        CalculateErrornousRows();
    },[])

    return (
        <>
            <table style={{ borderCollapse: 'collapse', width: "100%" }} >
                <thead >
                    <tr>
                            <th key={-1} style={{...headerStyle, paddingRight: '25px' , maxWidth: "8vw", width: "auto"}}>
                                {/* {"Error"} */}
                            </th>
                        {tableFormatLocal.headerLabels.map((label, index) => (
                            <th key={index} style={headerStyle}>
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            style={{
                                backgroundColor: '#f6f6f6',
                            }}
                            title={errorRecordMapper[row.RequestID] ? errorRecordMapper[row.RequestID] : undefined}
                        >
                            <td key={-1} style={{ ...bodyStyle, textAlign:'center', backgroundColor: errorRecordsRequestIds.includes(row.RequestID) ? '#f6d6d6' : 'initial', color:errorRecordsRequestIds.includes(row.RequestID) ? '#E71E26': '#21B24B', maxWidth: "8vw", width: "auto"}}>
                                {errorRecordsRequestIds.includes(row.RequestID) ?  <img src={CrossIcon} alt="Company Logo" style={{height:' 100%', width: 'auto'}}  /> : <img src={CorrectIcon} alt="Company Logo" style={{height:' 100%', width: 'auto'}} />}
                            </td>
                            {tableFormatLocal.keys.map((label,innerIndex) => (
                                <td key={innerIndex} style={{ ...bodyStyle, backgroundColor: errorRecordsRequestIds.includes(row.RequestID) ? '#f6d6d6' : 'initial' }}>
                                    {((row[label] && row[label] !== -1 && row[label] !== '-1') ? (label === 'DateApproved' ? moment(row[label]).format('MM/DD/YYYY') : row[label]) : 'NA')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                loader &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }
        </>
    );
};

const headerStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    backgroundColor: '#e9e9e9',
    color: 'black',
    height: '30px',
    padding: '3px'
};

const bodyStyle = {
    fontFamily: 'Segoe UI',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left',
    height: '21px',
    // padding: '10px'
};


export default AutoAllocateTable;
