import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./editRequest.css";
import axios from "axios";
import baseConfig from "../../../config/config";
import { tableFormat } from "../../../config/tableFormat";
import moment from "moment";
import Select from 'react-select';
import { components } from 'react-select';
import Draggable from "../../components/tableComponent/tableComponent/Draggable";

const CustomValueContainer = ({ children, ...props }) => {
  const selectedValues = props.getValue();
  const { selectProps, hasValue } = props;

  return (
    <components.ValueContainer {...props}>
      <div  className="drag-tag">
      <Draggable showScroll={false}>
      {!hasValue && !props.selectProps.inputValue && (
        <div className="custom-placeholder">{selectProps.placeholder}</div>
      )}
        {selectedValues.map((val, index) => (
          <span className="tag-value" key={val.value} style={{ marginRight: '8px' }}>
            {val.label}
            {/* {selectedValues.length - index === 1 ? "" : ","} */}
          </span>
        ))}
      </Draggable>
      </div>
     
      {/* {React.Children.map(children, child =>
        child && child.type !== components.Placeholder ? child : null
      )} */}
    </components.ValueContainer>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    border: 'none',
    boxShadow: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'white' : provided.backgroundColor,
    color: state.isSelected ? 'black' : provided.color,
    cursor: 'pointer'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflowX: "scroll",
    scrollbarWidth: 'none', /* Hides scrollbar */
    msOverflowStyle: 'none', /* IE and Edge */
    "&::-webkit-scrollbar": {
      display: "none"
    },
    // whiteSpace: 'nowrap',
    flexWrap: "unset",
    fontFamily:
      'apple-system,BlinkMacSystemFont,"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI',
    fontSize: "5px",
    fontWeight: "600",
    height: "32px",
    padding: "0px 8px 0px 8px",
    width: "18.5vw",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'black'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'black',
    ':hover': {
      backgroundColor: 'lightgray',
      color: 'black'
    }
  })
};

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: '8px' }}
        />{" "}
        <label style={{ margin: 0 }}>{props.label}</label>
      </div>
    </components.Option>
  );
};

function EditRequest(props) {
  const [selectedArray, setSelectedArray] = React.useState({
    RequestID: props.data?.RequestID || null,
        GetHelpID: props.data?.GetHelpID || "",
        CustomerName: props.data?.CustomerName || "",
        SubscriptionType: props.data?.SubscriptionType || "",
        SubscriptionID: props.data?.SubscriptionID || "",
        RegionRequested: props.data?.RegionRequested || "",
        ModelID: props.data?.ModelID || -1,
        PTU_Requested: props.data?.PTU_Requested || "",
        RequestGrantStatusID: props.data?.RequestGrantStatusID || -1,
        DateApproved: props.data?.DateApproved || '',
        PTU_Granted: props.data?.PTU_Granted || "",
        RegionGrantedID: props.data?.RegionGrantedID || -1,
        DRI_Alias: props.data?.DRI_Alias || '',
        DRI_Name: props.data?.DRI_Name || '',
        AssignedToAlias: props.data?.AssignedToAlias || '',
        AssignedToName: props.data?.AssignedToName || '',
        GBB_Region: props.data?.GBB_Region || '',
        PurchaseDate: props.data?.PurchaseDate || '',
        ChurnDate: props.data?.ChurnDate || '',
        DeprovisionDate: props.data?.DeprovisionDate || '',
        ProvisionDate: props.data?.ProvisionDate || '',
        GetHelp_RequestDate: props.data?.GetHelp_RequestDate || '',
        RequestStateID: props.data?.RequestStateID || -1,
        ChurnReason: props.data?.ChurnReason || '',
        Notes: '',
        PtuSplitted: props.data?.PtuSplitted || props.isNew ? null : [],
        Tag: props.data?.Tag || []
  });
  const [selectedRequestGranted, setSelectedRequestGranted] =
    React.useState(null);
  const [selectedModalName, setSelectedModalName] = React.useState(null);
  const [selectedRegionGranted, setSelectedRegionGranted] =
    React.useState(null);
  const [errorLabel, setErrorLabel] = React.useState("");
  const [newDate, setNewDate] = React.useState("");
  const [isRequestStateChanged, setIsRequestStateChanged] = React.useState(false);
  const [selectedGbbRegion, setSelectedGbbRegion] = React.useState();
  const [selectedRequestOption, setSelectedRequestOption] = React.useState();
  const [selectedRegionRequested, setSelectedRegionRequested] =
    React.useState();
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const [ptuErrorLabel, setPtuErrorLabel] = React.useState("");
  const [currentModelRegionData, setCurrentModelRegionData] = React.useState(
    []
  );
  const [errorField, setErrorField] = useState("")
  const ptuData = useSelector((state) => state.ptutable.ptuData);
  const classicRegionData = useSelector(store => store.ptusData.ptusData !== undefined ? store.ptusData.ptusData : []);
  const [selectedTagsOptions, setSelectedTagsOptions] = React.useState(props.selectedTagOptions.map(option => ({
    value: option.ID,
    label: option.Name
  })));
  
  
  const MultiSelectCheckbox = ({ selectedOptions, options, onChange }) => {
    const formattedOptions = options.map(option => ({
      value: option.ID,
      label: option.Name
    }));
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ Option: CustomOption, ValueContainer: CustomValueContainer }}
        // components={{ Option: CustomOption, ValueContainer: CustomValueContainer, Input: CustomInput }}
        isMulti
        options={formattedOptions}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        // closeMenuOnSelect={false}
        styles={customStyles}
        onChange={onChange}
        value={selectedOptions}
        inputValueRenderer={selectedOptions}
        placeholder="Choose Tags"
  
      />
    );
  };
  function formatDate(dateString) {
    const parts = dateString.split("/");
    if (parts.length === 3) {
      const [month, day, year] = parts;
      return `${month}/${day}/${year}`;
    }
    return "";
  }
  
  function formatDateAxios(dateString) {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${month}/${day}/${year}`;
    }
    return "";
  }
  const validate_PTU_Splitted = (value, granted) => {
    if (selectedArray['PTU_Granted'] !== granted) {
      return handleInputChange([], "PtuSplitted")
    }
    const values = value
      .split(",")
      .map((value) => value.trim());
    const integers = values.filter((value) => /^\d+$/.test(value));
    const parsedIntegers = integers.map((value) =>
      parseInt(value, 10)
    );
    const total = parsedIntegers.reduce(
      (acc, curr) => acc + curr,
      0
    );

    if (integers.length !== values.length) {
      setPtuErrorLabel(
        "Please enter integer values separated by commas"
      );
      props.setIsTrue(true);
    } else if (total > parseInt(granted)) {
      setPtuErrorLabel(
        `Sum of values cannot be greater than PTU Granted ${granted}`
      );
      props.setIsTrue(true);
    } else if (total < parseInt(selectedArray["PTU_Granted"])) {
      setPtuErrorLabel(
        `Sum of values cannot be lesser than PTU Granted ${granted}`
      );
      props.setIsTrue(true);
    } else {
      setPtuErrorLabel("");
      props.setIsTrue(false);
      handleInputChange(parsedIntegers, "PtuSplitted");
    }

  }
  const validate_PTU_Granted = () => {
    if (Number(selectedArray["PTU_Granted"]) < 0) {
      setErrorField("PTU_Granted")
      setErrorLabel(`PTU Granted should be positive number`);
      props.setIsTrue(true);
    } else {
      setErrorLabel(``);
      props.setIsTrue(false);
    }
  };

  const handleTagChange = (selectedTagsOptions) => {
    const selectedLabels = selectedTagsOptions.map(option => option.label);
    setSelectedTagsOptions(selectedTagsOptions);
    handleInputChange(selectedLabels,'Tag');
  }

  const handleInputChange = (e, keyvalue) => {
    const updatedJsonDataArray = selectedArray;
    if (keyvalue === "PtuSplitted") {
      selectedArray["PtuSplitted"] = e;
      updatedJsonDataArray["PtuSplitted"] = e;
      setSelectedArray(updatedJsonDataArray);
      return props.handleInputChange(updatedJsonDataArray);
    }
    const newValue = e.target ? e.target.value : undefined;
    updatedJsonDataArray[keyvalue] =
      keyvalue === "DateApproved" ||
        keyvalue === "ProvisionDate" ||
        keyvalue === "PurchaseDate" ||
        keyvalue === "ChurnDate" ||
        keyvalue === "DeprovisionDate"
        ? formatDate(newValue)
        : newValue;
    if (keyvalue === "DateApproved") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);
      updatedJsonDataArray["DateApproved"] = formatedDate;
    }
    if (keyvalue === "ProvisionDate") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);
      updatedJsonDataArray["ProvisionDate"] = formatedDate;
    }
    if (keyvalue === "ChurnDate") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);
      updatedJsonDataArray["ChurnDate"] = formatedDate;
    }
    if (keyvalue === "DeprovisionDate") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);

      updatedJsonDataArray["DeprovisionDate"] = formatedDate;
    }
    if (keyvalue === "PurchaseDate") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);
      updatedJsonDataArray["PurchaseDate"] = formatedDate;
    }
    if (keyvalue === "ChurnReason") {
      updatedJsonDataArray["ChurnReason"] = newValue;
    }
    if(keyvalue === "Tag"){
      updatedJsonDataArray["Tag"] = e;
    }
    if (keyvalue === "GetHelp_RequestDate") {
      setNewDate(newValue);
      const formatedDate = formatDateAxios(newValue);
      updatedJsonDataArray["GetHelp_RequestDate"] = formatedDate;
    }
    if (keyvalue === "RequestGrantStatusID") {
      setSelectedRequestGranted(newValue);
    } else if (keyvalue === "ModelID") {

      setSelectedModalName(newValue);
      const newIndex = e.target.value;
      if (sessionStorage.getItem('tab') === '2') {
        if (selectedArray["ModelID"] !== -1) {
          let currentRegionData = ptuData[newIndex]
          if (!currentRegionData.find(item => item.Region === selectedArray['RegionGranted'])) {
            updatedJsonDataArray['RegionGranted'] = props.regionGrantedOptions[0]?.Value;
            updatedJsonDataArray['RegionGrantedID'] = props.regionGrantedOptions[0]?.Key;
          }
          setCurrentModelRegionData(ptuData[newIndex]);
        }
      } else {
        setCurrentModelRegionData(classicRegionData);
      }
    } else if (keyvalue === "RegionGrantedID") {
      setSelectedRegionGranted(newValue);
      updatedJsonDataArray["RegionGranted"] = (props.regionGrantedOptions.find(item => item.Key === newValue)).Value;
    } else if (keyvalue === "RegionRequested") {
      setSelectedRegionRequested(newValue);
      updatedJsonDataArray["RegionRequested"] = newValue;
    } else if (keyvalue === "GBB_Region") {
      setSelectedGbbRegion(newValue);
    } else if (keyvalue === "RequestState") {
      setSelectedRequestOption(newValue);
    } else if (keyvalue === "RequestStateID") {
      setSelectedRequestOption(newValue);
    }

    if ( !isRequestStateChanged && (
      keyvalue === "DateApproved" ||
      keyvalue === "ProvisionDate" ||
      keyvalue === "PurchaseDate" ||
      keyvalue === "DeprovisionDate" ||
      keyvalue === "ChurnDate" 
    )
    ) {
      if (
        updatedJsonDataArray["DateApproved"] === "" &&
        updatedJsonDataArray["ProvisionDate"] === "" &&
        updatedJsonDataArray["PurchaseDate"] === "" &&
        updatedJsonDataArray["ChurnDate"] === "" &&
        updatedJsonDataArray["DeprovisionDate"] === ""
      ) {
        updatedJsonDataArray["RequestStateID"] = "1";
        setSelectedRequestOption("1");
      }
      if (updatedJsonDataArray["DateApproved"] !== "") {
        selectedArray["RequestStateID"] = "4";
        setSelectedRequestOption(4);
      }
      if (updatedJsonDataArray["ProvisionDate"] !== "") {
        selectedArray["RequestStateID"] = "5";
        setSelectedRequestOption(5);
      }
      if (updatedJsonDataArray["PurchaseDate"] !== "") {
        selectedArray["RequestStateID"] = "6";
        setSelectedRequestOption(6);
      }
      if (updatedJsonDataArray["ChurnDate"] !== "") {
        selectedArray["RequestStateID"] = "8";
        setSelectedRequestOption(8);
      }
    }

    setSelectedArray(updatedJsonDataArray);
    props.handleInputChange(updatedJsonDataArray);
    validate_PTU_Granted();
  };
  useEffect(() => {
    if (sessionStorage.getItem('tab') === '2') {
      if (selectedArray["ModelID"] !== -1) {
        setCurrentModelRegionData(ptuData[selectedArray["ModelID"]]);
      }
    } else {
      setCurrentModelRegionData(classicRegionData);
    }
  }, []);

  const setInputField = (propertySelected) => {
    let label = propertySelected.key;
    if (label === "DateApproved") {
      return (
        <>
          <div className="input-container">
            <input
              type="date"
              className="custom-input"
              onKeyDown={(event) => event.preventDefault()}
              defaultValue={
                selectedArray[label]
                  ? moment(selectedArray[label], "MM/DD/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              placeholder={`Enter ${propertySelected.label}`}
              onChange={(event) => {
                handleInputChange(event, label);
              }}
            />
          </div>
        </>
      );
    } else if (label === "DeprovisionDate") {
      return (
        <>
          <div className="input-container">
            <input
              type="date"
              className="custom-input"
              onKeyDown={(event) => event.preventDefault()}
              defaultValue={
                selectedArray['DeprovisionDate']
                  ? moment(selectedArray['DeprovisionDate'], "MM/DD/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              onChange={(event) => {
                handleInputChange(event, label);
              }}
            />
          </div>
        </>
      );
    } else if (
      label === "ProvisionDate" ||
      label === "ChurnDate" ||
      label === "PurchaseDate" ||
      label === "GetHelp_RequestDate" 
    ) {
      return (
        <>
          <div className="input-container">
            <input
              type="date"
              onKeyDown={(event) => event.preventDefault()}
              className="custom-input"
              defaultValue={
                selectedArray[label]
                  ? moment(selectedArray[label], "MM/DD/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              onChange={(event) => {
                handleInputChange(event, label);
              }}
            />
          </div>
        </>
      );
    } else if (label === "RequestGrantStatus") {
      return (
        <>
          <div>
            <select
              className="input-container-select"
              value={
                selectedRequestGranted
                  ? selectedRequestGranted
                  : selectedArray["RequestGrantStatusID"]
              }
              onChange={(event) => {
                handleInputChange(event, "RequestGrantStatusID");
              }}
            >
              {props.regionGrantedStatusOptions?.map((item) => (
                <option value={item["Key"]}>{item["Value"]}</option>
              ))}
            </select>
          </div>
        </>
      );
    } else if (label === "ModelName") {
      return (
        <>
          <div>
            <select
              className="input-container-select"
              value={
                selectedModalName ? selectedModalName : selectedArray["ModelID"]
              }
              onChange={(event) => {
                handleInputChange(event, "ModelID");
              }}
            >
              {selectedArray["ModelID"] === -1 && (
                <option value={-1} disabled hidden>
                  Choose Model Name
                </option>
              )}
              {props.modelOptions?.map((item) => (
                <option value={item["Key"]}>{item["Value"]}</option>
              ))}
            </select>
          </div>
        </>
      );
    } else if (label === "Tag") {
      return (
        <div style={{width:'280px',border:'1px solid #888888', borderRadius:'5px'}}>
          <MultiSelectCheckbox options={props.PTUManagedTriageTagsReducer} selectedOptions={selectedTagsOptions} onChange={handleTagChange}/>
        </div>
      );
    } else if (label === "RegionGranted") {
      return (
        <>
          <div>
            <select
              className="input-container-select "
              value={
                selectedRegionGranted
                  ? selectedRegionGranted
                  : selectedArray["RegionGrantedID"]
              }
              onFocus={() => {
                if (selectedArray["ModelID"] === -1) {
                  setErrorField("RegionGranted")
                  setErrorLabel("Please select a model");
                  props.setIsTrue(true);
                }
              }}
              onChange={(event) => {
                if (selectedArray["ModelID"] === -1) {
                  setErrorField("RegionGranted")
                  setErrorLabel("Please select a model");
                  props.setIsTrue(true);
                } else {
                  setErrorLabel("");
                  props.setIsTrue(false);
                  handleInputChange(event, "RegionGrantedID");
                }
              }}
            >
              <option
                style={{ color: '#00000029' }} key='-1' value={'-1'} selected={selectedArray["RegionGrantedID"] == '-1'}>{props.regionGrantedOptions[0].Value}</option>
              {props.regionGrantedOptions?.map((item) => {
                if(item.Key == '-1') return null;
                const regionDataItem = currentModelRegionData.find(
                  (region) => region.Region === item.Value
                );
                if (sessionStorage.getItem('tab') === '2' ? regionDataItem : true) {
                  return (
                    <option style={{ color: 'black' }} key={item.Key} value={item.Key}>
                      {item.Value}
                    </option>
                  );
                }
              })}
            </select>
            {propertySelected.key === "RegionGranted" && errorField === "RegionGranted" ? (
                            <span className="side-error">
                {errorLabel}
              </span>
            ) : (
              ""
            )}
          </div>
        </>
      );
    } else if (label === "SubscriptionType") {
      return (
        <>
          <div>
            <select
              className="input-container-select"
              value={"PTU"}
              onChange={(event) => {
                handleInputChange(event, label);
              }}
            >
              <option value="">Select {label}</option>
              {props.subscriptionOptions?.map((item) => (
                <option value={item["Key"]}>{item["Value"]}</option>
              ))}
            </select>
          </div>
        </>
      );
    } else if (label === "GBB_Region") {
      return (
        <>
          <div>
            <select
              className="input-container-select"
              value={
                selectedGbbRegion
                  ? selectedGbbRegion
                  : selectedArray["GBB_Region"]
              }
              onChange={(event) => {
                handleInputChange(event, "GBB_Region");
              }}
            >
              {selectedArray["GBB_Region"] === "" && (
                <option value="" disabled hidden>
                  Choose GBB Region
                </option>
              )}
              {props.gbbRegionOptions?.map((item) => (
                <option key={item["Key"]} value={item["Key"]}>
                  {item["Value"]}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    }
    else if (label === "GetHelpID") {
      return (
        <>
          <div className="input-container">
            <input
              type="text"
              className="custom-input"
              maxLength={20}
              defaultValue={
                selectedArray[label] && selectedArray[label] !== "-1"
                  ? selectedArray[label]
                  : "NA"
              }
              onChange={(event) => {
                handleInputChange(event, label);
              }}
            />
          </div>
        </>
      );
    } else if (label === "CustomerName") {
      return (
        <div style={{ flexDirection: "row", display: "flex" }}>
          <input
            placeholder="Choose Customer Name"
            type="text"
            value={
              selectedArray[label] && selectedArray[label] !== -1
                ? selectedArray[label]
                : selectedCustomer?.value
            }
            style={{
              width: "248px",
              height: "25px",
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            className="custom-input-filters"
            onChange={(event) => {
              handleInputChange(event, label);
              setSelectedCustomer(event.target.value);
            }}
          />
          <div style={{}} id="filterDropdownDiv">
            <select
              name="filterDropDown"
              id="filterDropDown"
              onChange={(event) => {
                handleInputChange(event, label);
                setSelectedCustomer(event.target.value);
              }}
              style={{
                width: "20px",
                outline: "none",
                height: "36.5px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <option value="-1" selected="selected" disabled="disabled">
                -- Choose customer name --
              </option>
              {props.allCustomer?.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            {props.customerError &&  (
              <span className="side-error">
                 Customer Name cannot be empty
              </span>
            ) }
          </div>
        </div>
      );
    } else if (label === "RequestState") {
      return (
        <div className="input-container">
          <select
            style={{ marginRight: "10px" }}
            className="input-container-select"
            value={
              selectedRequestOption
                ? selectedRequestOption
                : selectedArray["RequestStateID"]
            }
            disabled={isChecked ? true : false}
            onChange={(event) => {
                setIsRequestStateChanged(true)
                handleInputChange(event, "RequestStateID");
            }}
          >
            <option value="-1" selected="selected" disabled="disabled">
              Choose Request State
            </option>
            {props.requestStateOption?.map((item) => (
              <option value={item["Key"]}>{item["Value"]}</option>
            ))}
          </select>
          {!props.isNewRecord && (<><div
            style={{
              borderLeft: "1px solid #ccc",
              height: "38px",
              paddingLeft: "10px",
              marginLeft: "10px",
            }}
          >
            <label
              style={{
                paddingRight: "10px",
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <input
                type="checkbox"
                checked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    setPtuErrorLabel("");
                    props.setIsTrue(false);
                    handleInputChange([], "PtuSplitted");
                  }
                  setIsChecked(!isChecked);
                }}
                style={{ height: "20px", width: "20px", marginRight: "5px" }} // Increase height and width of checkbox
              />
              Split
            </label>
          </div>
          <div
            style={{
              borderLeft: "1px solid #ccc",
              paddingLeft: "10px",
              marginLeft: "10px",
            }}
          >
            <input
              type="text"
              className="custom-input"
              disabled={isChecked ? false : true}
              placeholder="Enter comma separated values"
              onChange={(event) => {
                validate_PTU_Splitted(event.target.value, selectedArray['PTU_Granted'])
              }
              }
            />
          </div>
          {ptuErrorLabel ? (
            <div style={{ color: "red", fontSize: "10px", marginLeft: "1%" }}>
              {ptuErrorLabel}
            </div>
          ) : (
            ""
          )}
          </>)}
          
        </div>
      );
    } else {
      return (
        <>
          <div
            className="input-container"
            style={{
              flexDirection: "column",
              left: 0,
              alignItems: "flex-start",
            }}
          >
            <input
              type={label === "PTU_Granted" || label === "PTU_Requested" ? "number" : "text"}
              className="custom-input"
              defaultValue={
                selectedArray[label] === "-1" ? "" : selectedArray[label]
              }
              placeholder={`Enter ${propertySelected.label}`}
              onChange={(event) => {
                if (label === "PTU_Granted") {
                  if (selectedArray["ModelID"] === -1) {
                    event.target.value = 0
                    handleInputChange(event, label);
                    setErrorField("PTU_Granted")
                    setErrorLabel("Please select a model");
                    props.setIsTrue(true);
                  } else if (selectedArray["RegionGrantedID"] == -1) {
                    event.target.value = 0
                    handleInputChange(event, label);
                    setErrorField("PTU_Granted")
                    setErrorLabel("Please select region");
                    props.setIsTrue(true);
                  } else {
                    setErrorLabel("");
                    props.setIsTrue(false);
                    if (selectedArray['PtuSplitted'].length > 0) {
                      validate_PTU_Splitted(selectedArray['PtuSplitted'].join(', '), event.target.value)
                    }
                    handleInputChange(event, label);
                  }
                } else {
                  handleInputChange(event, label);
                }
              }}
            />
            {propertySelected.key === "PTU_Granted" && errorField === "PTU_Granted" ? (
              <div
                style={{ color: "red", fontSize: "10px", marginBottom: "1rem" }}
              >
                {errorLabel}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      );
    }
  };


  return (
    <>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          border: "3px solid #ccc",
        }}
      >
        <tbody>
          {tableFormat.LabelKeyPair?.map((property, index) => {
            return (
              <tr key={index}>
                <th style={{ ...headerStyle, border: "1px solid #ccc", width: "25%" }}>
                  {property.label} <span style={{color: "red"}}>{property.key === "CustomerName" ? "*" : ""}</span>
                </th>
                <td
                  style={{
                    ...bodyStyle,
                    backgroundColor: "#f6f6f6",
                    border: "1px solid #ccc",
                  }}
                >
                  {setInputField(property)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {props.notes.length > 0 && (
        <>
          <div
            style={{
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "20px",
              letterSpacing: "0px",
              marginTop: "10px",
            }}
          >
            NOTES
          </div>
          {props.notes.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  flexDirection: "column",
                  marginTop: "0.6rem",
                }}
              >
                <div
                  style={{
                    fontFamily: "Segoe UI",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    letterSpacing: "0px",
                    color: "#1f1f1f",
                  }}
                >
                  {item.User}{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "12px",
                      fontWeight: 400,
                    }}
                  >
                    {item.Date}
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    color: "#181818",
                    marginBottom: "10px",
                  }}
                >
                  {item.Notes}
                </div>
                {index < props.notes.length - 1 && <hr color="#eaeaea" />}
              </div>
            );
          })}
        </>
      )}
      {props.notesLoader && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}

const headerStyle = {
  fontFamily: "Segoe UI",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0px",
  textAlign: "left",
  backgroundColor: "#F6F6F6",
  height: "30px",
  padding: "3px"
};

const bodyStyle = {
  fontFamily: "Segoe UI",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "16px",
  letterSpacing: "0px",
  textAlign: "left",
  height: "30px",
  padding: "10px",
};

export default EditRequest;
