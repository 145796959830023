import React, { useCallback, useEffect, useState } from 'react';
import '../../pages/requestApproval/requestApproval.css';
import "../../components/tableComponent/tableComponent/mastertable.css"
import DetailTable from '../../components/tableComponent/DetailTable';
import axios from 'axios';
import baseConfig from '../../../config/config';
import EditRequest from '../editRequest/editRequest';
import { useNavigate } from "react-router-dom";
import Logs from '../logs/logs';
import Alert from '../../components/alert/alert';
import pencilImage from './../../../assets/pencil.png';
import deleteImage from './../../../assets/delete.png'
import { useSelector } from 'react-redux';

function Detail(props) {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState(0);
    const [editBlockEnable, setEditBlockEnable] = React.useState(false);
    const [isNewRecord, setIsNewRecord] = React.useState(false);
    const [isTrue, setIsTrue] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [adding, setAdding] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const allClassicCustomers = useSelector((store) =>
        store.allClassicCustomers.allClassicCustomers !== undefined
          ? store.allClassicCustomers.allClassicCustomers
          : []
      )
      const allManagedCustomers = useSelector((store) =>
        store.allManagedCustomers.allManagedCustomers !== undefined
          ? store.allManagedCustomers.allManagedCustomers
          : [])
    const [openAlert, setOpenAlert] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false)
    const [deleteFailure, setDeleteFailure] = React.useState(false);
    const [customerError, setCustomerError] = React.useState(false);
    const [modelData, setModelData] = useState([])
    const [notes, setNotes] = useState([])
    const [notesLoader, setNotesLoader] = useState(true)
    const [selectedTagOptions, setSelectedTagOptions] = useState([]);
    const getNotes = async () => {
        try {
            const response = await axios.get(
                baseConfig.apiBaseUrl + '/HelpRequest/Notes?RequestID=' + props.data?.RequestID,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                    },
                }
            );
            if (response.status === 200) {
                setNotes(response.data)
                setNotesLoader(false)

            }

        } catch (error) {
            setNotes([])
            setNotesLoader(false)

        }
    }
    useEffect(() => {
        if (props.data.length === 0) {
            setSelectedTagOptions([]);
            setIsNewRecord(true)
            setNotesLoader(false)
        } else {
            setNotesLoader(true)
            const tagArray = props.data.Tag?.split(",").map(tag => tag.trim());

            const tempArray = props.PTUManagedTriageTagsReducer.filter(obj =>
                tagArray.includes(obj.Name)
            )

            setSelectedTagOptions(tempArray);

            if (props.data?.RequestID) {
                getNotes()
            }

        }
    }, [])


    const [selectedArray, setSelectedArray] = React.useState({
        RequestID: props.data?.RequestID || null,
        GetHelpID: props.data?.GetHelpID || "",
        CustomerName: props.data?.CustomerName || "",
        SubscriptionType: props.data?.SubscriptionType || "",
        SubscriptionID: props.data?.SubscriptionID || "",
        RegionRequested: props.data?.RegionRequested || "",
        ModelID: props.data?.ModelID || -1,
        PTU_Requested: props.data?.PTU_Requested || "",
        RequestGrantStatusID: props.data?.RequestGrantStatusID || "",
        DateApproved: props.data?.DateApproved || '',
        PTU_Granted: props.data?.PTU_Granted || "",
        RegionGrantedID: props.data?.RegionGrantedID || -1,
        DRI_Alias: props.data?.DRI_Alias || '',
        DRI_Name: props.data?.DRI_Name || '',
        AssignedToAlias: props.data?.AssignedToAlias || '',
        AssignedToName: props.data?.AssignedToName || '',
        GBB_Region: props.data?.GBB_Region || '',
        PurchaseDate: props.data?.PurchaseDate || '',
        ChurnDate: props.data?.ChurnDate || '',
        DeprovisionDate: props.data?.DeprovisionDate || '',
        ProvisionDate: props.data?.ProvisionDate || '',
        GetHelp_RequestDate: props.data?.GetHelp_RequestDate || '',
        RequestStateID: props.data?.RequestStateID || -1,
        ChurnReason: props.data?.ChurnReason || '',
        Notes: '',
        PtuSplitted: props.data?.PtuSplitted || props.isNew ? null : [],
        Tag: props.data?.Tag || []
    });

    const tabsData = [
        { label: 'Details', content: <DetailTable isNewRecord={isNewRecord} data={[props?.data]}></DetailTable> },
        { label: 'Logs', content: <Logs /> },
    ];

    // Properties to remove special characters and commas from
    const propertiesToClean = ['PTU_Requested', 'PTU_Granted'];

    // Function to remove special characters and commas from a string
    const cleanString = (str) => {
        // Replace special characters and commas with an empty string
        if (str) {
            return str.replace(/[^\w\s]/gi, '').replace(/,/g, '');
        }
        return str
    };

    const clean_PTU_Granted = (str) => {
        if (str) {
            return str.replace(/[^\w\s-]/gi, '').replace(/,/g, '');
        }
        return str
    }

    const update = async () => {
        setUpdating("Updating Record...")
        const postData = Object.fromEntries(
            Object.entries(selectedArray).map(([key, value]) => [
                key,
                propertiesToClean.includes(key) ? key === 'PTU_Granted' && selectedArray.RequestStateID === "8" ? clean_PTU_Granted(value) : cleanString(value) : value,
            ])
        );
        postData.Tag = Array.isArray(postData.Tag) ? postData.Tag.join(',') : postData.Tag;
        if (postData['RegionGranted'] === 'Choose Region') postData['RegionGranted'] = "";
        try {
            const response = await axios.post(
                baseConfig.apiBaseUrl + '/HelpRequest/SelectedUpdate',
                postData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                    },
                }
            );
            if (response.status === 200) {
                setUpdating("")
                setSuccess("Record Updated Successfully")
                setTimeout(() => {
                    setSuccess("")
                    window.location.reload();
                }, 2000);
            }

        } catch (error) {
            console.error('API Error:', error);
            setUpdating("")
            setFailed("Failed To Update Record. Try again.")
            setTimeout(() => {
                setFailed("")
            }, 3000);
            if (error.response.status === 401) {
                navigate("/errorPage");
            }
        }
    }
    const addRecord = async () => {
        if (selectedArray['CustomerName']) {
            const postData = Object.fromEntries(
                Object.entries(selectedArray).map(([key, value]) => [
                    key,
                    propertiesToClean.includes(key) ? key === 'PTU_Granted' && selectedArray.RequestStateID === "8" ? clean_PTU_Granted(value) : cleanString(value) : value,
                ])
            );
            postData.Tag = Array.isArray(postData.Tag) ? postData.Tag.join(',') : postData.Tag;
            postData.Source = sessionStorage.getItem("tab") === '2' ? "PTUManagedTriage" : "PTUTriage"


            try {
                setUpdating("Adding Record...")

                const response = await axios.post(
                    baseConfig.apiBaseUrl + '/Utility/AddEntry',
                    postData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                        },
                    }
                );
                if (response.status === 200) {
                    setUpdating("")
                    setSuccess("Record Added Successfully")
                    setTimeout(() => {
                        setSuccess("")
                        window.location.reload();
                    }, 2000);
                }

            } catch (error) {
                console.error('API Error:', error);
                setUpdating("")
                setFailed("Failed To Add Record. Try again.")
                setTimeout(() => {
                    setFailed("")
                }, 3000);
                if (error.response.status === 401) {
                    navigate("/errorPage");
                }
            }
        } else {
            setCustomerError(true)
        }

    }

    const deleteRecord = async () => {
        setDeleting(true)
        let postData = {
            "HelpRequestID": selectedArray.RequestID
        }
        try {
            const response = await axios.post(
                baseConfig.apiBaseUrl + '/HelpRequest/Delete',
                postData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                    },
                }
            );
            if (response.status === 200) {
                setDeleting(false)
                setDeleteSuccess(true)
                setTimeout(() => {
                    setDeleteSuccess(false)
                    window.location.reload();
                }, 2000);
            }

        } catch (error) {
            console.error('API Error:', error);
            setDeleting(false)
            setDeleteFailure(true)
            setTimeout(() => {
                setDeleteFailure(false)
            }, 3000);
            if (error.response.status === 401) {
                navigate("/errorPage");
            }
        }
    }

    const handleInputChange = (value) => {
        if (value['CustomerName'] === "" || !value['CustomerName']) {
            setIsTrue(true)
        }
        setSelectedArray(value)
    };

    const handleEditClick = () => {
        setEditBlockEnable(!editBlockEnable);
    }

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    const handleEscapeKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            props.closeConfirmationModal()
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKeyPress);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPress);
        };
    }, [handleEscapeKeyPress]);

    return (
        <>
            <div className="modal-container">

                <div style={{ maxHeight: '80vh' }}>
                    <div className="title" style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        {editBlockEnable ? <b>Edit</b> : isNewRecord ? <b>New Record</b> : <b>Detailed View</b>}
                        {!editBlockEnable && !isNewRecord &&
                            <button style={{ padding: '15px', height: '35px', backgroundColor: '#4b81ff', color: 'white', border: 'none', borderRadius: '5px', marginLeft: 'auto', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={handleEditClick}>
                                <img src={pencilImage} style={{ width: '13px', marginRight: '8px' }}></img>
                                Edit
                            </button>
                        }
                        {!editBlockEnable && !isNewRecord &&
                            <button style={{ padding: '15px', height: '35px', backgroundColor: '#D13438', color: 'white', border: 'none', borderRadius: '5px', marginLeft: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={() => { setOpenAlert(true) }} disabled={deleting}>
                                <img src={deleteImage} style={{ width: '13px', marginRight: '8px' }}></img>
                                Delete
                            </button>
                        }
                        <i className="fa fa-times close-icon"
                            style={{ marginLeft: '20px', marginRight: '10px' }}
                            onClick={editBlockEnable ? () => setEditBlockEnable(false) : props.closeConfirmationModal}
                        ></i>
                    </div>

                    <hr className="divider" />

                    {!editBlockEnable && !isNewRecord &&
                        <ul className="tab-list" style={{ padding: '0px', marginBottom: '20px' }}>
                            {tabsData.map((tab, index) => (
                                <li
                                    key={index}
                                    className={activeTab === index ? 'active-tab' : ''}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {tab.label}
                                </li>
                            ))}
                        </ul>
                    }

                    {/* <div className="text" style={{ fontSize: '13.5px' }}>
                        This is a snippet of what you have selected from the table. If you want to cancel the request
                        then Click on <b>Cancel</b> Button.
                    </div> */}
                    {(editBlockEnable || isNewRecord) &&
                        <div style={{ overflow: 'auto', maxHeight: '60vh' }}>
                            <EditRequest
                                allCustomer={sessionStorage.getItem('tab') === '2' ? allManagedCustomers : allClassicCustomers}
                                customerError={customerError}
                                setIsTrue={setIsTrue}
                                editBlockEnable={editBlockEnable}
                                setEditBlockEnable={setEditBlockEnable}
                                data={props?.data}
                                handleInputChange={handleInputChange}
                                regionGrantedOptions={props.regionGrantedOptions}
                                regionGrantedStatusOptions={props.regionGrantedStatusOptions}
                                modelOptions={props.modelOptions}
                                subscriptionOptions={props.subscriptionOptions}
                                gbbRegionOptions={props.gbbRegionOptions}
                                requestStateOption={props.requestStateOption}
                                handletableInputChange={props.handletableInputChange}
                                PTUManagedTriageTagsReducer={props.PTUManagedTriageTagsReducer}
                                selectedTagOptions={selectedTagOptions}
                                isNewRecord={isNewRecord}
                                notes={notes}
                                notesLoader={notesLoader}
                            ></EditRequest>
                        </div>
                    }

                    {!editBlockEnable && !isNewRecord &&
                        <div className="tab-content">
                            {activeTab === 0 ?
                                <DetailTable data={[props?.data]} notes={notes} notesLoader={notesLoader}
                                ></DetailTable>
                                :
                                <Logs data={props?.data}></Logs>
                            }
                        </div>
                    }


                    <hr className="divider" />

                    <div className="buttons-container">
                        {editBlockEnable &&
                            <div className="button-container" style={{ marginBottom: '20px', marginRight: '10px', opacity: isTrue ? .5 : 1 }}>
                                <button disabled={isTrue || updating} onClick={update}>Update</button>
                            </div>
                        }
                        {isNewRecord &&
                            <div className="button-container" style={{ marginBottom: '20px', marginRight: '10px', opacity: isTrue ? .5 : 1 }}>
                                <button disabled={isTrue || updating} onClick={addRecord}>Add </button>
                            </div>
                        }
                        <button
                            className="button button-go-back"
                            onClick={editBlockEnable ? () => setEditBlockEnable(false) : props.closeConfirmationModal}
                            style={{ marginBottom: '20px' }}
                        >
                            Cancel
                        </button>


                    </div>
                </div>
            </div>


            {openAlert &&
                <>
                    <div className="modal-backdrop" style={{ zIndex: '1000' }}></div>
                    <Alert message={'Are you sure you want to delete this record?'} SubmitbuttonText={'Delete'} onclickSubmit={deleteRecord} deleteSuccess={deleteSuccess} deleteFailure={deleteFailure} deleting={deleting} onClickCancel={() => { setOpenAlert(false) }}></Alert>
                </>
            }
            {updating && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                {updating}
            </div>}
            {success && <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                {success}
            </div>}
            {failed && <div className="alert">
                {failed}
            </div>}
            {deleting && <div className="alert" style={{ backgroundColor: '#aaaa09' }}>
                Deleting Record
            </div>}
            {deleteSuccess && <div className="alert" style={{ backgroundColor: '#4CAF50' }}>
                Record Deleted Successfully
            </div>}
            {deleteFailure && <div className="alert">
                Failed To Delete Record. Try again.
            </div>}
        </>
    );
}

export default Detail;