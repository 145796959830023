import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

const insertUniqueValues = (data, uniqueValues) => {
  const result = [];
  const inserted = new Set();

  for (const region of data) {
    const trimmedRegion = region.GBBRegion;

    if (
      trimmedRegion !== "" &&
      uniqueValues.includes(trimmedRegion) &&
      !inserted.has(trimmedRegion)
    ) {
      result.push({
        GBBRegion: region.GBBRegion,
        unique: true,
        Region: region.GBBRegion,
      });
      inserted.add(trimmedRegion);
    }
    result.push(region);
  }
  return result;
};

const segregateByGBBRegion = (arr) => {
  const regionMap = {};

  arr.forEach((obj) => {
    const region = obj.GBBRegion;

    if (!regionMap[region]) {
      regionMap[region] = {
        GBBRegion: region,
        data: [],
      };
    }

    regionMap[region].data.push(obj);
  });

  return Object.values(regionMap);
};

const PTUTable = (props) => {
  const modelNameOptions = useSelector((store) =>
    store.distinctDataThroughSingleApi.distinctDataThroughSingleApi.ModelName ||
    []
  );
  const isPtuDataLoaded = useSelector((state) => state.ptutable.isPtuDataLoaded);
  const ptuData = useSelector((state) => state.ptutable.ptuData);
  const classicRegionData = useSelector((store) =>
    store.ptusData.ptusData || []
  );

  const regionDataTemp = useMemo(
    () => (sessionStorage.getItem("tab") === "2" ? ptuData[props.activeTab] || [] : classicRegionData || []),
    [ptuData, classicRegionData, props.activeTab]
  );

  const [regionData, setRegionData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [segregatedData, setSegregatedData] = useState([]);

  useEffect(() => {
    const uniqueGbbRegions = [...new Set(regionDataTemp.map((region) => region.GBBRegion))];

    const newRegionData = insertUniqueValues(regionDataTemp, uniqueGbbRegions);
    const segregatedDataTemp = segregateByGBBRegion(regionDataTemp);

    setRegionData(newRegionData);
    setSegregatedData(segregatedDataTemp);
    setLoader(false);
  }, [regionDataTemp]);

  return (
    <>
      {sessionStorage.getItem("tab") === "2" && (
        <div>
          <div className="tabs-container">
            <div className="tabs" style={{ display: "flex" }}>
              {modelNameOptions.map((tab, index) => (
                <button
                  key={tab.Key}
                  id={index}
                  onClick={() => props.handleTabClick(tab.Key, index)}
                  className={
                    tab.Key == props.activeTab ? "all-tabs  tabbutton active" : "all-tabs tabbutton"
                  }
                >
                  {tab.Value}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="ptu-available-container">
        {regionData.length > 0 && !loader ? (
          <div style={{ display: "grid", width: "100%" }}>
            <div className="grid-container-table" style={{ gridTemplateColumns: sessionStorage.getItem('tab') === "1" ? 'repeat(auto-fit, minmax(424px, 1fr))' : "repeat(auto-fit, minmax(20%, 1fr))" }}>
              {segregatedData.map((regionDataMapper) => (
                <table key={regionDataMapper.GBBRegion} className="ptu-table" >
                  <thead>
                    <tr>
                      <th className="region-td">{regionDataMapper.GBBRegion}</th>
                      <th style={{ display: sessionStorage.getItem('tab') === "1" ? '' : 'none' }}>Starting PTUs</th>
                      <th style={{ display: sessionStorage.getItem('tab') === "1" ? '' : 'none' }}>Allotted PTUs</th>
                      <th>Available PTUs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regionDataMapper.data.map((item, index) => (
                      <tr key={index}>
                        {item.Pool && !item.Region.includes("Pool") ? null : item.unique ? (
                          <td
                            style={{
                              backgroundColor: "rgb(233, 233, 233)",
                              fontWeight: "bold",
                            }}
                            colSpan={4}
                          >
                            {item.GBBRegion === "zzz" ? "Not Defined" : item.GBBRegion}
                          </td>
                        ) : (
                          <>
                            <td className="region-td">{item.Region}</td>
                            <td style={{ display: sessionStorage.getItem('tab') === "1" ? '' : 'none' }}>{item.AvailablePTU}</td>
                            <td style={{ display: sessionStorage.getItem('tab') === "1" ? '' : 'none' }}>{item.AllotedPTU || 0}</td>
                            <td>
                              {item.AvailablePTU - (item.AllotedPTU ? item.AllotedPTU : 0)}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
            {sessionStorage.getItem("tab") === "2" && (
              <div className="pool-info">
                <div>
                  <b>US Pool A: </b> East US/ East US 2/ North Central US
                </div>
                <div>
                  <b>US Pool B: </b> South Central US/ West US/ West US 3
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PTUTable;
