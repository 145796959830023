import React from 'react';
import './logs.css';
import axios from 'axios';
import baseConfig from '../../../config/config';
import moment from 'moment';

const historyData = [
    {
        user: 'John Doe',
        date: '2023-10-25',
        description: 'Created the project'
    },
    {
        user: 'Alice Smith',
        date: '2023-10-24',
        description: 'Updated task #123'
    },
    {
        user: 'Bob Johnson',
        date: '2023-10-23',
        description: 'Resolved issue #456'
    },
    {
        user: 'Bob Johnson',
        date: '2023-10-23',
        description: 'Resolved issue #456'
    },
    {
        user: 'Bob Johnson',
        date: '2023-10-23',
        description: 'Resolved issue #456'
    },
    {
        user: 'Bob Johnson',
        date: '2023-10-23',
        description: 'Resolved issue #456'
    },
    {
        user: 'Eva Williams',
        date: '2023-10-22',
        description: 'Added new feature'
    }
];

const Logs = (props) => {
    const [logData, setLogData] = React.useState([]);
    const [loader, setLoader] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [selectedLogData, setSelectedLogData] = React.useState(null);
    const [modelArray, setModelArray] = React.useState([])

    const logs = async () => {
        setLoader(true)
        try {
            const response = await axios.get(
                baseConfig.apiBaseUrl + '/Utility/History?RequestID=' + props.data['RequestID'],
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
                    },
                }
            );
            if (response.status === 200) {
                setLogData(response.data)
                setLoader(false)
            }

        } catch (error) {
            setLogData([])
            setLoader(false)
        }
    }
    const getDistinctData = async (column) => {
        const config = {
            headers: {
                'content-type': 'application/json',
                "serviceid": window.btoa(sessionStorage.getItem('Emailid') + ';' + sessionStorage.getItem('userName'))
            },
        };
        try {
            const response = await axios.get(baseConfig.apiBaseUrl + '/Utility/Filter?columnName=' + column, config);
            if (response.status === 200) {
                return response.data;
            }

        } catch (error) {
            console.error(error);
            // if (error.response.status === 401) {
            //     navigate("/errorPage");
            // }
        }
    };

    const getInitials = (userName) => {
        return (userName?.split(" ")
            .map((word) => word.charAt(0))
            .slice(0, 2)
            .join(""))
    }

    const getValue = (val, field) => {
        if (field === 'ModelID') {
            return modelArray.find(item => item.Key === val)?.Value
        }
        else {
            if (val !== '') {
                return val
            } else {
                return 'NA'
            }
        }
    }

    const getFields = async () => {
        setModelArray(await getDistinctData('ModelName'))
    }

    const handleLogSelect = (index) => {
        setSelectedIndex(index);
        setSelectedLogData(logData[index])
    }

    React.useEffect(() => {
        logs()
        getFields()
    }, []);


    return (
        <>
            <div className="text" style={{ fontSize: '13.5px' }}>
                History
            </div>

            <hr className="divider" />

            {(!loader && logData.length > 0) ?
                <div className="history-container">
                    <div className="left-section">
                        <hr color='#eaeaea' style={{ margin: '0px' }} />
                        {logData.map((item, index) => (
                            <>
                                <div key={index} className={`history-item ${selectedIndex === index ? 'selected' : ''}`} onClick={() => { handleLogSelect(index) }} >
                                    <div className="avatar" style={{ fontWeight: 'lighter', marginRight: '10px', height: '36px', width: '36px' }}>
                                        {getInitials(selectedLogData ? selectedLogData?.TransactionBy : logData[0]?.TransactionBy)}
                                    </div>
                                    <div className="description">{item.TransactionBy} {item.Operation} {item.FieldName !== 'ModelID' ? item.FieldName : 'ModelName'}</div>
                                    <div className="date">{moment(item.Date).format("MM/DD/YYYY")}</div>
                                </div>
                                <hr color='#eaeaea' style={{ margin: '0px' }} />
                            </>
                        ))}
                    </div>
                    <div className="right-section">
                        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Segoe UI', fontSize: '14px', lineHeight: '20px', letterSpacing: '0px' }}>
                            <div className="avatar" style={{ fontWeight: 'lighter', marginRight: '10px', height: '36px', width: '36px' }}>
                                {getInitials(selectedLogData ? selectedLogData?.TransactionBy : logData[0]?.TransactionBy)}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '90%' }}>
                                <div style={{ flex: 1 }}>
                                    <div style={{ fontWeight: '700' }}>{selectedLogData ? selectedLogData?.TransactionBy : logData[0]?.TransactionBy}</div>
                                    <div style={{ fontSize: '13px' }}>
                                        {selectedLogData ? (
                                            <>
                                                {selectedLogData?.TransactionBy} {selectedLogData?.Operation} {selectedLogData?.FieldName !== 'ModelID' ? selectedLogData?.FieldName : 'ModelName'}
                                            </>
                                        ) : (
                                            <>
                                                {logData[0]?.TransactionBy} {logData[0]?.Operation} {logData[0]?.FieldName !== 'ModelID' ? logData[0]?.FieldName : 'ModelName'}
                                            </>
                                        )}
                                    </div>

                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <div style={{ fontSize: '11px' }}>{selectedLogData ? moment(selectedLogData?.Date).format("MM/DD/YYYY") : moment(logData[0]?.Date).format("MM/DD/YYYY")}</div>
                                </div>
                            </div>
                        </div>
                        <hr color='#eaeaea' />

                        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Segoe UI', fontSize: '14px', lineHeight: '20px', letterSpacing: '0px' }}>
                            <div style={{ marginRight: '10px' }}>{selectedLogData ? selectedLogData?.FieldName : logData[0]?.FieldName}</div>
                            <div style={{ backgroundColor: '#dff6dd', padding: '2px 6px', marginRight: '10px' }}>{selectedLogData ? getValue(selectedLogData?.NewValue, selectedLogData?.FieldName) : getValue(logData[0]?.NewValue, logData[0]?.FieldName)}</div>
                            {selectedLogData ?
                                <>
                                    {(selectedLogData.Operation !== "ADDED") &&
                                        <div style={{ backgroundColor: '#eaeaea', padding: '2px 6px', position: 'relative', textDecoration: 'line-through' }}>
                                            {getValue(selectedLogData?.OldValue, selectedLogData?.FieldName)}
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {(logData[0]?.Operation !== "ADDED") &&
                                        <div style={{ backgroundColor: '#eaeaea', padding: '2px 6px', position: 'relative', textDecoration: 'line-through' }}>
                                            {getValue(logData[0]?.OldValue, logData[0]?.FieldName)}
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                :
                <>
                    {!loader &&
                        <div className="text" style={{ fontSize: '13.5px', textAlign: 'center', padding: '20px' }}>
                            There have been no updates made to the selected record. Therefore,  no logs available for this entry.
                        </div>
                    }
                </>
            }

            {
                loader &&
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            }

        </>
    );
};

export default Logs;
