import './App.css';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainPage from './utilities/pages/mainPage/mainPage';
import MicrosoftLogin2 from './utilities/pages/login/MLogin';
import baseConfig from './config/config';
import ExceptionHandled from './utilities/pages/login/errorPage';
import { Provider } from 'react-redux';
import store from './redux/store';


function App() {
  const msalConfig = {
    auth: {
      clientId: baseConfig.clientId,
      authority: baseConfig.authority
    },
    cache: {
      cacheLocation: 'sessionStorage', // Cache location can be 'localStorage' or 'sessionStorage'
      storeAuthStateInCookie: false, // Set this to true if you want to store the auth state in a cookie
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Provider store={store}>
            <Routes>
              <Route exact path="/" element={<MicrosoftLogin2/>} />
              <Route exact path="/Home" element={<MainPage />} />
              <Route exact path="/Home/:filters" element={<MainPage />} />
              <Route exact path="/errorPage" element={<ExceptionHandled />} />
            </Routes>
          </Provider>
        </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
