import React, { useRef, useState, useEffect } from "react";

const Draggable = ({ children , showScroll}) => {
  const draggableRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX);
    setStartScrollLeft(draggableRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const offsetX = e.pageX - startX;
    draggableRef.current.scrollLeft = startScrollLeft - offsetX;
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startX, startScrollLeft]);

  return (
    <div
      ref={draggableRef}
      style={{  overflowX: "auto", overflowY: "auto",
      overflowX:"scroll",
      maxHeight: "70vh", }}
      className={showScroll ? "draggable showScroll" : "draggable"}
      onMouseDown={handleMouseDown}
    >
      {children}
    </div>
  );
};

export default Draggable;
